import React, { useRef } from "react";
import { Text } from "@react-three/drei";
import state from "../../state/state"; //todo: delete
import ColorRevealMaterial from "../../shaders/ColorReveal";
import {
  deactivateMouseTracker,
  activateMouseTracker,
} from "../../util/changeMouseTracker";

interface TextProps {
  data: {
    text: string;
    color: string;
    font: string;
    fontSize: number;
    position: { x: number; y: number; z: number };
    rotation: { x: number; y: number; z: number };
    moveTo?: [number, number, (() => void)?];
  };
  pointerEvents: boolean;
  hovered?: boolean;
  revealSpeed?: number;
  setHovered?: (AnimatedTextHovered: boolean) => void;
  show: boolean;
  id: number;
}

const AnimatedText: React.FC<TextProps> = ({
  data,
  hovered,
  setHovered,
  id,
  show,
  revealSpeed,
  pointerEvents,
}) => {
  const setArea = state((s) => s.setArea);
  const setHotspot = state((s) => s.setHotspot);

  const mesh = useRef(null);

  return (
    <mesh
      renderOrder={5}
      onClick={(e) => {
        e.stopPropagation();
        if (!pointerEvents) return;
        if (!data.moveTo) return;
        deactivateMouseTracker();
        setArea(data.moveTo[0]);
        setHotspot(data.moveTo[1]);
        if (data.moveTo[2]) data.moveTo[2]();
      }}
      ref={mesh}
      rotation={[data.rotation.x, data.rotation.y, data.rotation.z]}
      position={[data.position.x, data.position.y, data.position.z]}
      onPointerEnter={(e) => {
        e.stopPropagation();
        if (!pointerEvents) return;
        if (data.text.length > 3) {
          if (setHovered) setHovered(true);
          activateMouseTracker("ENTER");
        }
      }}
      onPointerLeave={(e) => {
        e.stopPropagation();
        if (!pointerEvents) return;
        if (data.text.length > 3) {
          if (setHovered) setHovered(false);
          deactivateMouseTracker();
        }
      }}
    >
      <Text
        fontSize={data.fontSize}
        textAlign={"center"}
        color={data.color}
        font={data.font}
        anchorX={"center"}
      >
        {data.text}
        <ColorRevealMaterial
          hovered={hovered}
          show={show}
          revealSpeed={revealSpeed}
        />
      </Text>
    </mesh>
  );
};

export default React.memo(AnimatedText);
