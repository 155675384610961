import styled from "styled-components";

export const Title = styled.h1<{ render: boolean }>`
  position: absolute;
  top: 5rem;
  left: 50%;
  opacity: ${({ render }) => (render ? 1 : 0)};
  transition: all 0.4s ease-in-out;
  transform: translateX(-50%);
  font-family: "KinfolkReg", sans-serif;
  font-size: 4.5rem;
  margin: 0;
  padding: 0;
  text-align: center;
  z-index: 10;
  color: white;
  text-shadow: 0 3px 10px rgb(0 0 0 / 0.1);
  @media (max-width: 768px) {
    font-size: 3rem;
  }
`;
