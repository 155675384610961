import { isMobile } from "react-device-detect";

export interface Camera {
  label: string;
  position:
    | { x: number; y: number; z: number }[]
    | { x: number; y: number; z: number; label: string }[];
  target:
    | { x: number; y: number; z: number }[]
    | { x: number; y: number; z: number; label: string }[];
  duration?: number;
}

export const cameraData: Camera[] = [
  {
    label: "Landing",
    position: [{ x: 0, y: 2.6, z: 20 }],
    target: [{ x: 0, y: 2.6, z: 0 }],
  },
  {
    label: "Lobby",
    position: [
      isMobile
        ? { x: 0, y: 3, z: 5, label: "story" }
        : { x: 0, y: 1.75, z: -0.5, label: "story" },
      isMobile
        ? { x: 0, y: 4, z: 0, label: "map" }
        : { x: 0, y: 3, z: 1, label: "map" },

      isMobile
        ? { x: 0, y: 4, z: 0, label: "timeline" }
        : { x: 0, y: 3, z: 1, label: "timeline" },
    ],
    target: [
      isMobile
        ? { x: 0, y: 3, z: -3, label: "story" }
        : { x: 0, y: 2, z: -3, label: "story" },
      isMobile
        ? { x: -8.5, y: 4, z: -3, label: "map" }
        : { x: -4, y: 3, z: -3, label: "map" },

      isMobile
        ? { x: 8.5, y: 4, z: -3, label: "timeline" }
        : { x: 4, y: 3, z: -3, label: "timeline" },
    ],
  },
  {
    label: "Map",
    position: [
      isMobile
        ? { x: -21, y: 1.4, z: -6, label: "map lobby" }
        : { x: -25, y: 4, z: -9, label: "map lobby" },
      isMobile
        ? { x: -25.77, y: 3.89, z: -11.14, label: "at map" }
        : { x: -25.77, y: 3.89, z: -11.14, label: "at map" },
    ],
    target: [
      isMobile
        ? { x: -42.75, y: 4, z: -22.97, label: "map lobby" }
        : { x: -42.88, y: 4, z: -22.97, label: "map lobby" },
      isMobile
        ? { x: -28.39, y: 3.89, z: -13.21, label: "at map" }
        : { x: -28.39, y: 3.89, z: -13.21, label: "at map" },
    ],
  },
  {
    label: "Story",
    position: [
      isMobile
        ? { x: -9, y: 6, z: -40, label: "Robles Pond" }
        : { x: -4.5, y: 2.5, z: -45, label: "Robles Pond" },
      isMobile
        ? { x: 2, y: 3, z: -44, label: "The Ceremony" }
        : { x: 0, y: 2.25, z: -46, label: "The Ceremony" },
      isMobile
        ? { x: -9, y: 6, z: -38, label: "Segregation" }
        : { x: -4, y: 2, z: -42, label: "Segregation" },
      isMobile
        ? { x: -0.5, y: 6, z: -35, label: "Present Day" }
        : { x: 0, y: 2.5, z: -42, label: "Present Day" },
      { x: 0.014, y: 1.19, z: -43, label: "Take Action" },
    ],
    target: [
      isMobile
        ? { x: 0, y: 2, z: -50, label: "Robles Pond" }
        : { x: 1, y: 1.5, z: -52, label: "Robles Pond" },
      { x: 0, y: 1, z: -50, label: "The Ceremony" },
      { x: -0.5, y: 2, z: -50, label: "Segregation" },
      isMobile
        ? { x: 0, y: 3.5, z: -50, label: "Present Day" }
        : { x: 0, y: 3, z: -50, label: "Present Day" },
      isMobile
        ? { x: 0, y: 1.19, z: -50, label: "Take Action" }
        : { x: 0, y: 1.19, z: -50, label: "Take Action" },
    ],
  },
  {
    label: "Timeline",
    position: [{ x: 12.715, y: 2, z: -2.75 }],
    target: [{ x: 39.0, y: 2, z: -21.2 }],
  },
];
