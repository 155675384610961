/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import React, { useState, useEffect, useCallback } from "react";
import SideModal from "./SideModal";
import state from "../../state/state";
import { sideModalData } from "../../data/sideModal.data";
import { isMobile } from "react-device-detect";

interface SideModalWrapperProps {
  Area: number;
  Hotspot: number;
}

const SideModalWrapper: React.FC<SideModalWrapperProps> = ({
  Area,
  Hotspot,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const ModalIndex = state((s) => s.ModalIndex);
  const RenderAbout = state((s) => s.RenderAbout);
  const RenderCallToAction = state((s) => s.RenderCallToAction);
  const setModalIndex = state((s) => s.setModalIndex);
  const setShowSideModal = state((s) => s.setShowSideModal);
  const [toggleAnim, setToggleAnim] = useState(true);

  const [data, setData] = useState(null); // ? ref
  const [futureData, setFutureData] = useState(null); // ? ref

  const openModal = useCallback(
    (justCloseModal: boolean) => {
      if (futureData) {
        setData(futureData);
        setToggleAnim(true);
        if (!justCloseModal) {
          setIsOpen(true);
          setShowSideModal(true);
        }
      } else {
        setData(null);
      }
    },
    [futureData]
  );

  const onAnimFinished = useCallback(
    (justCloseModal: boolean) => {
      setIsOpen(false);
      let delay = 0;

      if (
        sideModalData[Area] &&
        sideModalData[Area].data &&
        sideModalData[Area].data[ModalIndex]
      ) {
        const data = sideModalData[Area].data[ModalIndex];
        if (data.delay !== undefined) delay = data.delay;
        else if (data.delayFunc !== undefined) delay = data.delayFunc();
      }

      if (RenderAbout) delay = 1000;
      setTimeout(() => openModal(justCloseModal), delay);
    },
    [Area, ModalIndex, RenderAbout, openModal]
  );

  const handleClose = useCallback(
    (justCloseModal = false) => {
      setTimeout(() => onAnimFinished(justCloseModal), 600);
      setShowSideModal(false);
    },
    [onAnimFinished]
  );

  useEffect(() => {
    setToggleAnim(false);
    handleClose();
    if (ModalIndex !== null) {
      if (RenderCallToAction && !isMobile) return;
      setFutureData(
        sideModalData[
          RenderAbout ? 5 : RenderCallToAction && isMobile ? 6 : Area
        ]?.data?.[ModalIndex]
      );
    } else {
      setFutureData(null);
    }
  }, [Area, ModalIndex, RenderAbout, handleClose, RenderCallToAction]);

  if (ModalIndex === null) handleClose();

  return (
    <>
      {isOpen && (
        <SideModal
          data={data}
          onClose={handleClose}
          toggleAnim={toggleAnim}
          setToggleAnim={setToggleAnim}
          setModalIndex={setModalIndex}
          Area={Area}
        />
      )}
    </>
  );
};

export default SideModalWrapper;
