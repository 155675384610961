import React from "react";
import parser from "ua-parser-js";
import CanvasApple from "./WebGL/CanvasApple";
import CanvasAndroid from "./WebGL/CanvasAndroid";
import UI from "./UI";
import state from "../../../state/arState";
import { OsTypes } from "./Util/OsTypes";
import TapToPlacePrompt from "./android/TapToPlacePrompt";
import LottiePrompt from "./ios/LottiePrompt";

interface Props {}

const StoryPage: React.FC<Props> = () => {
  const UserEventRecorded = state((s) => s.UserEventRecorded);
  const ScenePlaced = state((s) => s.ScenePlaced);
  const setScenePlaced = state((s) => s.setScenePlaced);
  const Chapter = state((s) => s.Chapter);
  const TextIndex = state((s) => s.TextIndex);

  const UA = new parser();
  const os = UA.getOS();

  return (
    <>
      <UI ScenePlaced={ScenePlaced} Chapter={Chapter} TextIndex={TextIndex}>
        {os.name === OsTypes.IOS ? (
          <>{<LottiePrompt ScenePlaced={ScenePlaced} />}</>
        ) : (
          <>{!ScenePlaced && <TapToPlacePrompt />}</>
        )}
      </UI>

      {os.name === OsTypes.MAC_OS && (
        <CanvasApple
          UserEventRecorded={UserEventRecorded}
          setScenePlaced={setScenePlaced}
          ScenePlaced={ScenePlaced}
        />
      )}
      {os.name === OsTypes.Android && (
        <CanvasAndroid
          setScenePlaced={setScenePlaced}
          ScenePlaced={ScenePlaced}
        />
      )}
      {os.name === OsTypes.IOS && (
        <CanvasApple
          UserEventRecorded={UserEventRecorded}
          setScenePlaced={setScenePlaced}
          ScenePlaced={ScenePlaced}
        />
      )}
    </>
  );
};

export default StoryPage;
