import React, { FC, useRef, useEffect, useState } from "react";
import {
  VideoContentWrapper,
  ModalDiv,
  Video,
  VideoCloseButton,
  MuteBtn,
  MuteImg,
} from "./styles/popupModalStyles";
import { VideoCross } from "../sideModal/styles/sideModalStyles";
import mute from "../../assets/images/muteW.png";

interface ModalProps {
  visible: boolean;
  handleClose: () => void;
  children?: React.ReactNode;
  url?: string | undefined;
}

const Modal: FC<ModalProps> = ({ visible, children, url, handleClose }) => {
  const [muted, setMuted] = useState(true);
  const [hovered, setHovered] = useState(false);

  const videoRef = useRef<HTMLVideoElement>(null);

  const handleVideoPlay = () => {
    console.log("Video started playing!");
  };

  const stopVideo = () => {
    if (videoRef.current) {
      videoRef.current.pause();
      videoRef.current.currentTime = 0;
    }
  };

  useEffect(() => {
    if (!visible && videoRef.current) {
      stopVideo();
    } else if (visible && videoRef.current) {
      videoRef.current.play();
    }
  }, [visible]);

  useEffect(() => {
    const handleVolumeChange = (e: any) => {
      if (e.target.muted) setMuted(true);
      else setMuted(false);
    };

    if (videoRef.current) {
      videoRef.current.addEventListener("volumechange", handleVolumeChange);

      return () => {
        videoRef.current?.removeEventListener(
          "volumechange",
          handleVolumeChange
        );
      };
    }
  }, []);

  return (
    <ModalDiv visible={visible}>
      <VideoContentWrapper visible={visible}>
        <MuteBtn
          onPointerEnter={() => setHovered(true)}
          onPointerLeave={() => setHovered(false)}
          onClick={() => setMuted(!muted)}
          visible={muted}
          hovered={hovered}
        >
          <MuteImg src={mute} alt="mute" />
          TAP TO UNMUTE
        </MuteBtn>
        <Video
          muted={muted}
          visible={visible}
          src={url}
          controls
          ref={videoRef}
          onPlay={handleVideoPlay}
          preload="auto"
        />
        <VideoCloseButton onClick={handleClose}>
          <VideoCross />
        </VideoCloseButton>
      </VideoContentWrapper>
    </ModalDiv>
  );
};

export default Modal;
