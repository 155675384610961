import React from "react";
import {
  Container,
  TextBox,
  Title,
  Subtitle,
  Text,
  Button,
  Background,
  Header,
  Image,
  // Link,
  Content,
  TitleContainer,
  ButtonContainer,
} from "../styles/intro.styles";

interface IntroProps {
  display: string;
  data: {
    title?: string;
    link?: string;
    linkText?: string;
    image?: string;
    paragraph?: string;
  };
  HideZionIntro: boolean;
  ShowZionIntro: boolean;
  handleContinueClick: (e: any) => void;
  handleARClick: (e: any) => void;
  handleVRClick: (e: any) => void;
}

const Intro: React.FC<IntroProps> = ({
  display,
  data,
  HideZionIntro,
  ShowZionIntro,
  handleVRClick,
  handleARClick,
  handleContinueClick,
}) => {
  return (
    <Background hide={HideZionIntro} show={ShowZionIntro}>
      <Container hide={HideZionIntro} show={ShowZionIntro}>
        <TextBox>
          <TitleContainer>
            <Title>ZION</Title>
            <Subtitle>CEMETERY</Subtitle>
          </TitleContainer>
          <Content>
            {data.title && <Header>{data.title}</Header>}
            {/* {data.link && (
              <Link target="_blank" href={data.link}> //todo: change link to occulus store
                <Text>{data.linkText}</Text>
              </Link>
            )} */}
            <Text style={{ textIndent: "15px" }}>{data.paragraph}</Text>
            {data.image && <Image src={data.image}></Image>}
          </Content>
          <ButtonContainer>
            <Button
              selected={false}
              hide={HideZionIntro}
              onClick={handleContinueClick}
            >
              Continue
            </Button>
            <Button
              selected={display === "ar"}
              hide={HideZionIntro}
              onClick={handleARClick}
            >
              View in AR
            </Button>
            <Button
              selected={display === "vr"}
              hide={HideZionIntro}
              onClick={handleVRClick}
            >
              View in VR
            </Button>
          </ButtonContainer>
        </TextBox>
      </Container>
    </Background>
  );
};

export default Intro;
