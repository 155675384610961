import Webcam, { WebcamProps } from "react-webcam";
import state from "../../../../state/arState";
import { useCallback } from "react";

const CameraOverlay = (): JSX.Element => {
  const setCameraPermission = state((s) => s.setCameraPermission);

  const videoConstraints: WebcamProps["videoConstraints"] = {
    facingMode: { exact: "environment" },
  };

  const handleUserMedia = useCallback(() => {
    setCameraPermission(true);
  }, [setCameraPermission]);

  const handleUserMediaError = useCallback(() => {
    console.log("denied camera");
  }, []);

  console.log(`cam overlay`);

  return (
    <Webcam
      // height={window.innerHeight}

      videoConstraints={videoConstraints}
      onUserMedia={handleUserMedia}
      onUserMediaError={handleUserMediaError}
    />
  );
};

export default CameraOverlay;
