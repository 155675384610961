import React, { useEffect, useState, useRef } from "react";
import state from "../../state/state";

interface AudioProps {
  currentTime: number;
  duration: number;
  playbackStatus: "play" | "pause";
  isSeeking: boolean;
  isLoading: boolean;
  progress: number;
  setTime: (seconds: number) => void;
  togglePlaybackStatus: () => void;
}

function useAudio(url: string): [JSX.Element, AudioProps] {
  const lowerSoundVolume = state((s) => s.lowerSoundVolume);

  const audioRef = useRef<HTMLAudioElement | null>(null);

  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [playbackStatus, setPlaybackStatus] = useState<"play" | "pause">(
    "pause"
  );
  const [isLoading, setLoading] = useState(false);
  const [isSeeking, setSeeking] = useState(false);

  useEffect(() => {
    setLoading(false);
  }, [url]);

  return [
    <audio
      onLoadedData={() => {
        if (audioRef.current !== null) {
          setPlaybackStatus("pause");
          setLoading(false);
          setDuration(audioRef.current.duration);
        }
      }}
      onSeeking={() => setSeeking(true)}
      onSeeked={() => setSeeking(false)}
      src={url}
      ref={audioRef}
      onTimeUpdate={() => {
        if (audioRef.current !== null) {
          setCurrentTime(audioRef.current.currentTime);
        }
      }}
      hidden
    />,
    {
      currentTime,
      duration,
      playbackStatus,
      isSeeking,
      isLoading,
      progress: (currentTime / duration) * 100,
      setTime: (seconds: number) => {
        if (audioRef.current !== null) {
          audioRef.current.currentTime = seconds;
        }
      },
      togglePlaybackStatus: () => {
        if (audioRef.current !== null) {
          if (playbackStatus === "play") {
            audioRef.current.pause();
            setPlaybackStatus("pause");
            lowerSoundVolume("high");
          }
          if (playbackStatus === "pause") {
            audioRef.current.play();
            setPlaybackStatus("play");
            lowerSoundVolume("low");
          }
        }
      },
    },
  ];
}

export default useAudio;
