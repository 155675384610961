export function lerp(start: number, end: number, amt: number): number {
  return (1 - amt) * start + amt * end;
}

export function smoothstep(min: number, max: number, value: number): number {
  const x = Math.max(0, Math.min(1, (value - min) / (max - min)));
  return x * x * (3 - 2 * x);
}

// * SINE

export const easeInSine = (t: number) => {
  return -1.0 * Math.cos(t * (Math.PI / 2.0)) + 1.0;
};

export const easeOutSine = (t: number) => {
  return Math.sin(t * (Math.PI / 2.0));
};

export const easeInOutSine = (t: number) => {
  return -0.5 * (Math.cos(Math.PI * t) - 1.0);
};

export function easeInOutCubic(x: number): number {
  return x < 0.5 ? 4 * x * x * x : 1 - Math.pow(-2 * x + 2, 3) / 2;
}

export function easeInOutCirc(x: number): number {
  return x < 0.5
    ? (1 - Math.sqrt(1 - Math.pow(2 * x, 2))) / 2
    : (Math.sqrt(1 - Math.pow(-2 * x + 2, 2)) + 1) / 2;
}

export function easeOutQuint(x: number): number {
  return 1 - Math.pow(1 - x, 5);
}
