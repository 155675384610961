import * as React from "react";

import { StyledLi } from "./styles/menuStyles";

const variants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

interface MenuItemProps {
  i: number;
  text: string;
  isOpen: boolean;
  clickHandler: () => void;
}

export const MenuItem: React.FC<MenuItemProps> = ({
  i,
  text,
  isOpen,
  clickHandler,
}) => {
  return (
    <StyledLi
      isOpen={isOpen}
      variants={variants}
      whileHover={{ scale: 1.1, color: "#C69E38" }}
      whileTap={{ scale: 0.95 }}
      onClick={clickHandler}
    >
      {text}
    </StyledLi>
  );
};
