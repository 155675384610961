import arState from "../state/arState";
import { isMobile } from "react-device-detect";

const setChapter = arState.getState().setChapter;

interface Xyz {
  x: number;
  y: number;
  z: number;
}

interface TextItem {
  label: string;
  position: Xyz;
  rotation: Xyz;
  text: string;
  color: string;
  font: string;
  fontSize: number;
  moveTo?: [number, number, (() => void)?];
}

interface TextProps {
  label: string;
  data: TextItem[][];
}

const black = "#000000";
const the = "THE";
const KinfolkReg = "./fonts/KinfolkReg.otf";
const mapWordPosition = { x: -30, y: 6.15, z: -13 };
const mapWordRotation = { x: 0, y: -5.38, z: 0 };

export const animatedTextData: TextProps[] = [
  {
    label: "Landing",
    data: [
      [
        {
          label: "title zion",
          position: { x: 0, y: 2.8, z: 15 },
          rotation: { x: 0, y: 0, z: 0 },
          text: "ZION",
          color: black,
          font: KinfolkReg,
          fontSize: 0.65,
          moveTo: [1, 0],
        },
        {
          label: "subtitle zion",
          position: { x: 0, y: 2.3, z: 15 },
          rotation: { x: 0, y: 0, z: 0 },
          text: "CEMETERY",
          color: black,
          font: KinfolkReg,
          fontSize: 0.18,
          moveTo: [1, 0],
        },
      ],
    ],
  },
  {
    label: "Lobby",
    data: [
      [
        {
          label: "story title",
          position: isMobile
            ? { x: 0, y: 4.55, z: -2 }
            : { x: 0, y: 2.8, z: -4 },
          rotation: { x: 0, y: 0, z: 0 },
          text: isMobile ? "" : "",
          color: black,
          font: KinfolkReg,
          fontSize: isMobile ? 0.23 : 0.11,
          moveTo: [3, 0, () => setChapter(0)],
        },
      ],
      [
        {
          label: "maps title",
          position: isMobile
            ? { x: -7.5, y: 5.2, z: -2.5 }
            : { x: -8.2, y: 4.7, z: -2.7 },
          rotation: { x: 0, y: Math.PI / 2, z: 0 },
          text: "MAPS",
          color: black,
          font: KinfolkReg,
          fontSize: 0.8,
          moveTo: [2, 0],
        },
        {
          label: "maps subtitle",
          position: isMobile
            ? { x: -7.5, y: 5.8, z: -2.5 }
            : { x: -8.2, y: 5.3, z: -2.7 },
          rotation: { x: 0, y: Math.PI / 2, z: 0 },
          text: the,
          color: black,
          font: KinfolkReg,
          fontSize: 0.25,
        },
      ],
      [
        {
          label: "timeline title",
          position: isMobile
            ? { x: 7.3, y: 4.6, z: -2.41 }
            : { x: 7.5, y: 4.2, z: -2.3 },
          rotation: { x: 0, y: -Math.PI / 2, z: 0 },
          text: "TIMELINE",
          color: black,
          font: KinfolkReg,
          fontSize: isMobile ? 0.68 : 0.7,
          moveTo: [4, 0],
        },
        {
          label: "timeline subtitle",
          position: isMobile
            ? { x: 7.3, y: 5.105, z: -2.41 }
            : { x: 7.5, y: 4.9, z: -2.3 },
          rotation: { x: 0, y: -Math.PI / 2, z: 0 },
          text: the,
          color: black,
          font: KinfolkReg,
          fontSize: isMobile ? 0.23 : 0.25,
        },
      ],
    ],
  },

  {
    label: "Story",
    data: [
      [
        {
          label: "title story header",
          position: { x: 0, y: 4, z: -10 },
          rotation: { x: 0, y: 0, z: 0 },
          text: "",
          color: black,
          font: KinfolkReg,
          fontSize: 1,
        },
        {
          label: "subtitle story header",
          position: { x: 0, y: 3.3, z: -10 },
          rotation: { x: 0, y: 0, z: 0 },
          text: "",
          color: black,
          font: KinfolkReg,
          fontSize: 0.25,
        },
      ],
    ],
  },
];

export const mapAnimatedText = {
  label: "Map",
  data: [
    [
      {
        label: "title map intro",
        position: mapWordPosition,
        rotation: mapWordRotation,
        text: "MAPS",
        color: black,
        font: KinfolkReg,
        fontSize: 0.35,
      },
      {
        label: "subtitle map intro",
        position: { x: -30, y: 6.45, z: -13 },
        rotation: mapWordRotation,
        text: the,
        color: black,
        font: KinfolkReg,
        fontSize: 0.15,
      },
    ],
    [
      {
        label: "title 1820",
        position: mapWordPosition,
        rotation: mapWordRotation,
        text: "1820",
        color: black,
        font: KinfolkReg,
        fontSize: 0.35,
      },
    ],
    [
      {
        label: "title 1950",
        position: mapWordPosition,
        rotation: mapWordRotation,
        text: "1950",
        color: black,
        font: KinfolkReg,
        fontSize: 0.35,
      },
    ],
    [
      {
        label: "title present day",
        position: mapWordPosition,
        rotation: mapWordRotation,
        text: "PRESENT DAY",
        color: black,
        font: KinfolkReg,
        fontSize: 0.35,
      },
    ],
  ],
};

export const timelineAnimatedText = {
  label: "Timeline",
  data: [
    [
      {
        label: "title timeline",
        position: { x: 0, y: 1.8, z: -0.1 },
        rotation: { x: 0, y: 0, z: 0 },
        text: "TIMELINE",
        color: black,
        font: KinfolkReg,
        fontSize: 0.37,
      },
      {
        label: "subtitle timeline",
        position: { x: 0, y: 2.15, z: -0.1 },
        rotation: { x: 0, y: 0, z: 0 },
        text: the,
        color: black,
        font: KinfolkReg,
        fontSize: 0.17,
      },
    ],
  ],
};
