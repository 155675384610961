import React, { useEffect } from "react";
import styled, { keyframes, css } from "styled-components";
import state from "../../state/state";

// Define keyframes for fadeIn and fadeOut
const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`;

const FullScreenOverlay = styled.div<{
  transitionTime: number;
  visibleTime: number;
  show: boolean;
}>`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: white;
  pointer-events: none;
  z-index: 40;
  display: ${({ show }) => (show ? "block" : "none")};

  ${({ transitionTime, visibleTime, show }) =>
    show
      ? css`
          animation: ${fadeIn} ${transitionTime}s ease-in-out forwards,
            ${fadeOut} ${transitionTime}s ease-in-out
              ${visibleTime + transitionTime}s forwards;
        `
      : ""};
`;

const WhiteOverlayComponent = () => {
  const RenderWhiteOverlay = state((s) => s.RenderWhiteOverlay);
  const setRenderWhiteOverlay = state((s) => s.setRenderWhiteOverlay);

  const transitionTime = 1.8; //1.5
  const visibleTime = 1.3;

  useEffect(() => {
    if (!RenderWhiteOverlay) return;
    const duration = (transitionTime + visibleTime + 2) * 1000;
    setTimeout(() => {
      setRenderWhiteOverlay(false);
    }, duration);
  }, [RenderWhiteOverlay, setRenderWhiteOverlay]);

  return (
    <FullScreenOverlay
      show={RenderWhiteOverlay}
      transitionTime={transitionTime}
      visibleTime={visibleTime}
    />
  );
};

export default React.memo(WhiteOverlayComponent);
