import React from "react";
import Gui from "./Gui";
import Version from "./Version";
import ShortcutLaunchGui from "./ShortcutLaunchGui";
import state from "../../../state/state";

interface Props {
  DevEnvironment: boolean;
}

const DevToolsUI: React.FC<Props> = ({ DevEnvironment }) => {
  const setDevEnvironment = state((s) => s.setDevEnvironment);

  const toggleDevEnvironment = () => {
    setDevEnvironment(!DevEnvironment);
  };

  return (
    <>
      <Gui DevEnvironment={DevEnvironment} />
      <ShortcutLaunchGui toggleDevEnvironment={toggleDevEnvironment} />
      {/* {DevEnvironment && <Version />} */}
      {/* {DevEnvironment && <TransitionAreaBtn />}
      {DevEnvironment && <TransitionHotspotBtn />} */}
    </>
  );
};

export default DevToolsUI;
