import React from "react";
import { SvgContainer, ScrollText, Img } from "./styles/scroll.styles";
import scroll from "../../assets/images/scroll.svg";
import { isMobile } from "react-device-detect";
import state from "../../state/state";

type ScrollUIProps = {
  visible: boolean;
};

const ScrollUI: React.FC<ScrollUIProps> = ({ visible }) => {
  const setArea = state((s) => s.setArea);

  const handleClick = () => {
    if (isMobile) {
      setArea(1);
    }
  };

  return (
    <>
      <SvgContainer visible={visible} onClick={handleClick}>
        {isMobile && <Img src={scroll} alt="Tap to Begin" />}
      </SvgContainer>
      {isMobile ? (
        <ScrollText visible={visible} onClick={handleClick}>
          TAP TO BEGIN EXPERIENCE
        </ScrollText>
      ) : (
        <ScrollText visible={visible}>REMEMBRANCE EXPERIENCE</ScrollText>
      )}
    </>
  );
};

export default ScrollUI;
