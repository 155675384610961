import React, { useEffect, useState, useLayoutEffect } from "react";
import Hotspot from "../../shaders/Hotspot";
import { storyData } from "../../data/story.data";
import * as THREE from "three";
import state from "../../state/state";
import { useThree } from "@react-three/fiber";

interface HotspotProps {
  Chapter: number;
}

interface HotspotData {
  position: THREE.Vector3;
  onClick: () => void;
}

const Hotspots: React.FC<HotspotProps> = ({ Chapter }) => {
  const [data, setData] = useState<HotspotData[] | null>(null);
  const [offset, setOffset] = useState<number>(0);
  const Animating = state((s) => s.Animating);
  const CandleLit = state((s) => s.CandleLit);
  const { clock } = useThree();

  useLayoutEffect(() => {
    // console.log(`hit`);
    setOffset(clock.getElapsedTime());
  }, [Animating, clock]);

  useEffect(() => {
    // console.log(`Chapter`, Chapter);
    // console.log(`data`, Chapter);
    setData(
      storyData[Chapter].data.map((data) => {
        return {
          position: new THREE.Vector3(data.x, data.y, data.z),
          onClick: data.onClick,
        };
      })
    );
  }, [Chapter]);

  if (!data) return null;

  return (
    <>
      <Hotspot
        CandleLit={CandleLit}
        Chapter={Chapter}
        data={data[0]}
        Animating={Animating}
        offset={offset}
        stager={0}
        transparent={false}
      />

      {Chapter < 5 && (
        // todo: don't dismount here. just make invisible
        <>
          <Hotspot
            CandleLit={CandleLit}
            Chapter={Chapter}
            data={data[1]}
            Animating={Animating}
            offset={offset}
            stager={0.15}
            transparent={false}
          />

          <Hotspot
            CandleLit={CandleLit}
            Chapter={Chapter}
            data={data[2]}
            Animating={Animating}
            offset={offset}
            stager={0.3}
            transparent={false}
          />
        </>
      )}
    </>
  );
};

export default Hotspots;
