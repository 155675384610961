export const vertexShader = `

    attribute vec3 aVelocity;
    attribute float aRandomness;
    uniform float uSize;
    uniform float uTime;
    uniform float uGravity;
    varying float randomness;
    // varying float aDirection;


void main() {
  // Create a unique angle for each vertex based on its initial x and z position
  float angle = atan(position.z, position.x);

  // Determine a radius for the circular motion based on the initial position
  float radius = length(position.xz);

  // Add a time-dependent phase shift to the angle to create swirling motion
  angle += uTime ;

  // Compute the new position of the vertex
  vec3 newPosition = vec3(
    radius * cos(angle) , // new X
    position.y + uTime,  // new Y with upward motion
    radius * sin(angle)   // new Z
  );

  // Keep the y-coordinate within a specific range using modulus
  newPosition.y = mod(newPosition.y, 13. );

  newPosition.z += 5.;

  // Set the new position of the vertex
  gl_Position = projectionMatrix * modelViewMatrix * vec4(newPosition, 1.0);
  gl_PointSize = 20.0;
}
`;
