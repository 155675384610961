import { Howl } from "howler";

export const backgroundMusic = new Howl({
  src: ["music/backgroundMusic.mp3"],
  html5: true,
  loop: true,
});

const Music1 = new Howl({
  src: ["music/story/music/Zion_Music_Scene_0.mp3"],
  html5: true,
  loop: true,
});
// const Music2 = new Howl({
//   src: ["music/story/music/Zion_Music_Scene_1.mp3"],
//   html5: true,
//   loop: true,
// });
const Music3 = new Howl({
  src: ["music/story/music/Zion_Music_Scene_2.mp3"],
  html5: true,
  loop: true,
});
const Music4 = new Howl({
  src: ["music/story/music/Zion_Music_Scene_3.mp3"],
  html5: true,
  loop: true,
});

export const storyMusic = [Music1, Music3, Music4];

const sfx1 = new Howl({
  src: ["music/story/sfx/sfx_Zion_Story_1.mp3"],
  html5: true,
  loop: false,
});
const sfx2 = new Howl({
  src: ["music/story/sfx/sfx_Zion_Story_2.mp3"],
  html5: true,
  loop: false,
});
const sfx3 = new Howl({
  src: ["music/story/sfx/sfx_Zion_Story_3.mp3"],
  html5: true,
  loop: false,
});
const sfx4 = new Howl({
  src: ["music/story/sfx/sfx_Zion_Story_4.mp3"],
  html5: true,
  loop: false,
});

export const sfx = [sfx1, sfx2, sfx3, sfx4];

export const sfxLightning = new Howl({
  src: ["music/story/sfx/sfx_thunder_lightning.mp3"],
  html5: true,
  loop: false,
});

// import { Howl } from "howler";

// export const backgroundMusic = new Howl({
//   src: ["music/backgroundMusic.mp3"],
//   html5: true,
//   loop: true,
// });

// const Music1 = new Howl({
//   src: ["music/story/music/Zion_Music_Scene_0.mp3"],
//   html5: true,
//   loop: true,
// });
// const Music2 = new Howl({
//   src: ["music/story/sfx/sfx_Zion_Story_5.mp3"], // * nothing is needed here. just a place holder because combined 1st and 2nd chapter music
//   loop: false,
// });
// const Music3 = new Howl({
//   src: ["music/story/music/Zion_Music_Scene_2.mp3"],
//   html5: true,
//   loop: true,
// });
// const Music4 = new Howl({
//   src: ["music/story/music/Zion_Music_Scene_3.mp3"],
//   html5: true,
//   loop: true,
// });

// export const storyMusic = [Music1, Music2, Music3, Music4];

// const sfx1 = new Howl({
//   src: ["music/story/sfx/sfx_Zion_Story_1.mp3"],
//   loop: false,
// });
// const sfx2 = new Howl({
//   src: ["music/story/sfx/sfx_Zion_Story_2.mp3"],
//   loop: false,
// });
// const sfx3 = new Howl({
//   src: ["music/story/sfx/sfx_Zion_Story_3.mp3"],
//   loop: false,
// });
// const sfx4 = new Howl({
//   src: ["music/story/sfx/sfx_Zion_Story_4.mp3"],
//   loop: false,
// });
// export const lightTheCandle = new Howl({
//   src: ["music/story/sfx/sfx_Zion_Story_5.mp3"],
//   loop: false,
// });

// export const sfx = [sfx1, sfx2, sfx3, sfx4];

// export const sfxLightning = new Howl({
//   src: ["music/story/sfx/sfx_thunder_lightning.mp3"],
//   loop: false,
// });
