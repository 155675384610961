import React, { useEffect, useState } from "react";
// * Style
import { Btn } from "../../styles/RestartMusic";
// * Music
import { storyMusic } from "../../../../../util/getSound";

type SoundProps = {
  ScenePlaced: boolean;
  Chapter: number;
};

const Sound = ({ ScenePlaced, Chapter }: SoundProps) => {
  const [soundOn, toggleSound] = useState(true);
  const [i, setI] = useState(0);

  useEffect(() => {
    // * will also reset music if restart btn is pressed
    if (ScenePlaced) {
      // storyMusic[0].play();
      if (!soundOn) storyMusic[i].pause();
      else {
        storyMusic[i].play();
      }
    } else {
      console.log(`music off`);
      storyMusic.forEach((sound) => sound.stop());
    }
    return () => {
      storyMusic.forEach((sound) => sound.stop());
    };
  }, [ScenePlaced, soundOn, i]);

  useEffect(() => {
    if (!ScenePlaced) return;
    const onFocus = () => {
      if (!soundOn) return;
      storyMusic[i].play();
    };

    const onBlur = () => {
      if (!soundOn) return;
      storyMusic[i].pause();
    };

    window.addEventListener("focus", onFocus);
    window.addEventListener("blur", onBlur);

    return () => {
      window.removeEventListener("focus", onFocus);
      window.removeEventListener("blur", onBlur);
    };
  });

  const handleClick = () => {
    toggleSound(!soundOn);
  };

  useEffect(() => {
    if (Chapter <= 1) setI(0);
    else if (Chapter === 2) setI(1);
    else if (Chapter >= 3) setI(2);
  }, [Chapter]);

  if (Chapter === 4) return null;

  return (
    <Btn
      backgroundImageUrl={
        soundOn
          ? "/images/storyPage/sound.png"
          : "/images/storyPage/soundOff.png"
      }
      onClick={handleClick}
    />
  );
};

export default React.memo(Sound);
