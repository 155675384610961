import React from "react";
import styled from "styled-components";

const StyledHint = styled.h1`
  font-family: "Iowan Old Style";
  font-size: 16pt;
  font-weight: normal;
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 8%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
  pointer-events: none;
  text-align: center;
`;

const TapToPlacePrompt = () => {
  return (
    <StyledHint>Hint: point at a surface, move your device around</StyledHint>
  );
};

export default TapToPlacePrompt;
