import { useEffect } from "react";
import storyState from "../../../state/arState";

const InitStory = () => {
  const setScenePlaced = storyState((s) => s.setScenePlaced);

  useEffect(() => {
    const timer = setTimeout(() => {
      setScenePlaced(true);
    }, 500);
    return () => clearTimeout(timer);
  }, [setScenePlaced]);

  return null;
};

export default InitStory;
