import React, { useEffect, useState } from "react";
import Intro from "./Intro";
import { introData } from "./data/intro.data";
import state from "../../../../state/state";
import { isMobile } from "react-device-detect";

type DisplayOption = "default" | "ar" | "vr";

const IntroParent = () => {
  const [display, setDisplay] = useState<DisplayOption>("default");

  const {
    HideZionIntro,
    setHideZionIntro,
    ShowZionIntro,
    setShowZionIntro,
    setShowIntro,
  } = state();

  useEffect(() => {
    const timer = setTimeout(() => setShowZionIntro(true), 750); // 500ms delay
    return () => clearTimeout(timer);
  }, [setShowZionIntro]);

  const handleContinueClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setHideZionIntro(true);
    setDisplay("default");
    setShowIntro(false);
  };

  const handleARClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (isMobile) {
      window.open("/ar");
    } else {
      setDisplay("ar");
    }
  };

  const handleVRClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setDisplay("vr");
  };

  return (
    <Intro
      display={display}
      data={introData[display]}
      HideZionIntro={HideZionIntro}
      ShowZionIntro={ShowZionIntro}
      handleContinueClick={handleContinueClick}
      handleARClick={handleARClick}
      handleVRClick={handleVRClick}
    />
  );
};

export default IntroParent;
