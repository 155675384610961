import {
  Header,
  Paragraph,
  ContainerCentered,
  Image,
} from "../styles/sideModal.styles";
import AudioPlayer from "../../../components/audioPlayer";

export const PresentDay = (
  <ContainerCentered>
    <Header>Present Day</Header>
    <Paragraph>
      In 2018, nearly a 100 hundred years after the last burial at Zion Cemetery
      Ray Reed, a local community activist, questioned the disappearance of Zion
      Cemetery. Tampa Bay Times launched an investigation led by journalist Paul
      Guzzo who wrote the first published contemporary article on Zion Cemetery.
    </Paragraph>
    <Header>Fred</Header>
    <AudioPlayer
      url={"./music/story/soundBytes/Zion_Story_PresentDay_Fred.mp3"}
    />
    <Header>Reva</Header>
    <AudioPlayer
      url={"./music/story/soundBytes/Zion_Story_PresentDay_Reva.mp3"}
    />
  </ContainerCentered>
);

export const PresentDayTwo = (
  <ContainerCentered>
    <Header>Present Day</Header>
    <Paragraph>
      In 2022, the state of Florida approved Florida House Bill 4815 African
      American Cemetery Education Tampa Bay.  The project, designed by veteran
      educator Dr. Shannon Peck-Bartle, aims to preserve, protect, and
      memorialize erased African American cemeteries through education to
      empower the next generation of citizens to become active stewards for
      Florida’s African American cemeteries. These efforts are supported by
      local cemetery activists and learning institutions, artist Dr. Queenchiku
      Ngozi, and Groove Jones, who was commissioned to virtually recreate a
      memorial space for students, descendants, and future generations. Research
      efforts are ongoing.
    </Paragraph>
  </ContainerCentered>
);

export const GPR = (
  <ContainerCentered>
    <Image
      src="./images/storyDesktop/gpr1.jpg"
      alt="ground penetrating radar"
    />
    <Image
      src="./images/storyDesktop/gpr2.jpg"
      alt="ground penetrating radar"
    />
    <Header>GPR</Header>

    <Paragraph>
      Ground Penetrating Radar (GPR) confirmed that hundreds of caskets remain
      interred.  Families residing in homes above and near the cemetery were
      devastated. Local churches tied to the cemetery and cemetery history
      rallied the local community to demand action leading to the passage of HB
      37 and HB 4815, both sponsored by House Representative Fentrice Driskell
      and Senator Janet Cruz.
    </Paragraph>
    <AudioPlayer
      url={"./music/story/soundBytes/Zion_Story_PresentDay_Reva_GPR.mp3"}
    />
  </ContainerCentered>
);
