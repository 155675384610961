import React from "react";
import LandingPage from "./LandingPage";
import state from "../../../state/arState";

const LandingPageComponent: React.FunctionComponent = () => {
  const setUserEventRecorded = state((s) => s.setUserEventRecorded);

  return (
    <>
      <LandingPage
        backgroundImageUrl={"/images/landingPage/Landing.png"}
        buttonImageUrl={"/images/landingPage/landingBtn.png"}
        setUserEventRecorded={setUserEventRecorded}
      />
    </>
  );
};

export default LandingPageComponent;
