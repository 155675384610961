import React from "react";
import ScrollUI from "./ScrollUI";
// import ScrollEvent from "./ScrollEvent";
// import { isMobile } from "react-device-detect";
// @ts-ignore
const Scroll = ({ visible }) => {
  return (
    <>
      <ScrollUI visible={visible} />
      {/* {!isMobile && <ScrollEvent />} */}
    </>
  );
};

export default Scroll;
