import React, { useRef } from "react";
import { useFrame } from "@react-three/fiber";
import { fragmentShader, vertexShader } from "./water/water.shader";
import { useTexture } from "@react-three/drei";

interface Props {
  floor: string;
}

const Water: React.FC<Props> = ({ floor }) => {
  const mat = useRef(null);

  useFrame(({ clock }) => {
    if (!mat.current) return;
    const elapsedTime = clock.getElapsedTime();
    // @ts-ignore
    mat.current.uniforms.iTime.value = elapsedTime;
  });

  const tile = useTexture(floor);

  return (
    <shaderMaterial
      transparent
      ref={mat}
      vertexShader={vertexShader}
      fragmentShader={fragmentShader}
      uniforms={{
        iTime: { value: 0 },
        tTile: { value: tile },
      }}
    />
  );
};

export default React.memo(Water);
