interface SceneText {
  chapter: string;
  title: string;
  year: string;
  text: string[];
}

export const data: SceneText[] = [
  {
    chapter: "CHAPTER 1",
    title: "ROBLES POND",
    year: "1901",
    text: [
      "Robles Pond, a 45-acre all-Black community linked by sandy roads, is established in an area then outside city limits. The rough boundaries are Florida Avenue to Central Avenue, and Virginia Avenue to Lake Avenue. The “pond” in Robles Pond is less than a mile south of the community.",
      "Richard Doby, a prominent African American land developer, purchases about 3 acres along the 3800 block of N Florida Avenue in the Robles Pond neighborhood for $140 from Isaac W. Warner in 1897. This land was donated by Mr. Doby to the African American community for the establishment of Zion Cemetery and the Roble Ponds schoolhouse.",
      "Mount Carmel AME Church begins holding services on Sundays in the one-room wooden Robles Pond Elementary School at 3819 N Florida Ave.",
    ],
  },
  {
    chapter: "CHAPTER 2",
    title: "REMEMBRANCE CEREMONY",
    year: "1901",
    text: [
      // "It is not known how many people in total were buried at the cemetery.",
      "It is not known how many people in total were buried at the cemetery. \n1911 Death records report that 117 people are buried in Zion Cemetery.  \n1912 Death records report that 47 people are buried in Zion. \n1913 Death records report that 101 people are buried in Zion Cemetery. \n1914 Death records report that 97 people are buried in Zion.  \n1915 Death records report that 111 people are buried in Zion.  \n1916 Death records report that 92 people are buried in Zion.  \n1917 Death records report that 88 people are buried in Zion Cemetery.",
      "1918 Death records report that 68 people are buried in Zion Cemetery.\n1919 Death records report that 10 people are buried in Zion Cemetery.\n1920 Death records report that 10 people are buried in Zion Cemetery.\n1921 Death records report that one person is buried in Zion Cemetery.\n1922 Death records report that no one is buried in Zion Cemetery.",
      "1923 Zion Cemetery does not appear in the annual Polk City Directory. It never reappears. The city boundaries expand to include Zion and the Robles Pond neighborhood. Death records report that one person is buried in Zion. That marks the final burial reported. In all, death records report 769 burials, but archaeologists believe there are more.",
      "Families gathered at Robles Pond to remember their loved ones buried at Zion Cemetery. Many of the families at Zion would commemorate their deceased loved ones by bringing candles and decorating graves with shells, coins, plates, and glasses of water or shots of liquor.",
    ],
  },
  {
    chapter: "CHAPTER 3",
    title: "JIM CROW ERA",
    year: "1952",
    text: [
      "According to community resident Eunive Massey, in 1933 some graves were exhumed from Zion Cemetery. She doesn't remember how many, who, or why. All the headstones are then removed and the cemetery’s existence is erased, even though hundreds of bodies are still there.",
      "Newspapers report a mass reburial of bodies from Zion Cemetery’s neighboring all-white Catholic Cemetery to Myrtle Hill Cemetery.",
      "While building the Robles Park Apartments, on land that included part of the Zion Cemetery site, crews unearth three caskets. The city tells reporters  all the other bodies had been moved in 1925. Minutes from Housing Authority meetings include discussion of the three caskets and the need to re-enter them, but there is no mention of halting construction or searching for more graves.",
      "Land ownership was transferred several times between 1907 and 1926. In 1926, Alice W. Fuller of Los Angeles sold Zion Cemetery for $1 to Tampa developer H.P. Kennedy. Fuller is the daughter of Warner, who sold the land to Doby in 1894. It is not clear who sold it to Fuller or when. Fuller and Kennedy are both white.",
    ],
  },
  {
    chapter: "CHAPTER 4",
    year: "2023",
    title: "PRESENT DAY",
    text: [
      "Ray Reed, a concerned local citizen, informs the City of Tampa Zion Cemetery is located under Robles Park apartments. In June 2019, the Housing Authority of the City of Tampa (THA) commissioned a Phase 1 Environmental Site Assessment to confirm the location of Zion Cemetery.",
      "The Phase 1 report confirmed the 2.5 acre Zion Cemetery was originally located in the vicinity of what is today east of Florida Avenue and south of E. Virginia Avenue. The report further confirmed, approximately 1.5 acres of what today is Robles Park Village is on top of the original Zion Cemetery.",
      "With these discoveries, the THA in early July 2019 formed the Zion Archeological Advisory Committee and retained the services of archeologists with Cardno and FPAN (Florida Public Archeological Network) to confirm the presence of human remains at Zion Cemetery, and conduct further investigative work.",
      "Recently, excavations and ground penetrating radar reports have revealed artifacts and bodies under the project housing currently occupying the area.",
      "GPR (Ground Penetrating Radar) reported 126+ “anomalies” (believed to be intact coffins) visible at approximately 4-6 feet beneath the ground in the areas examined. GPR was used on approximately 30% of the 1.5-acre site occupied by 5 of Robles Park Village’s apartment buildings, and coincides with the known property boundaries of the originally platted Zion Cemetery. The smaller squares indicate where children might have been buried.",
      // "The smaller squares indicate where children might have been buried.",
    ],
  },
  {
    chapter: "",
    year: "",
    title: "",
    text: [""],
  },
];
