import {
  Header,
  Paragraph,
  ContainerCentered,
  ImageSmall,
} from "../styles/sideModal.styles";

export const Ceremony = (
  <ContainerCentered>
    <Header>The Ceremony</Header>
    <Paragraph>
      In 2019 Tampa Bay Times journalist Paul Guzzo covered a story on Zion
      Cemetery that reopened a national dialogue about erased and rediscovered
      African American Cemeteries. Organizations that focused on protection,
      preserving, and remembering those buried, began to form. After the
      cemetery made national news, Tampa Housing Authority relocated families
      residing in sacred space, fenced off the area, draped the fence with a
      banner that includes names of those interred, a photo of Robles Pond
      School, and images of old documents. It is common for families and
      visitors to place objects at gravesites to pay reverence to and/or
      remember lost loved ones such as conch shells, plates, coins, or bottles
      filled with various liquids. Evidence of grave goods were discovered by
      archaeologists during site ground truthing.
    </Paragraph>
  </ContainerCentered>
);

export const ConchShell = (
  <ContainerCentered>
    <ImageSmall
      src="./images/storyDesktop/conchShell.png"
      alt="Robles Park Village"
    />
    <Header>The Conch Shell</Header>
    <Paragraph>
      The placement of shells on African American graves is a practice found in
      many African American cemeteries, particularly in the South. These shells
      often symbolize water and immortality. In many African religions, the
      kingdom of the dead was underwater and the color of white is associated
      with the dead. White sea shells and mirrors, which can resemble smooth
      shiny water, are often placed on graves to symbolize an underwater
      environment. Some anthropologists believe the shape of a conch shell is
      significant to African American culture because the inside of the shell
      can enclose the immortal soul for transport to the afterlife. In Florida
      cemeteries, conch shells can also reflect the ancestry of African
      Americans who also identify as Afro-Caribbean.
    </Paragraph>
  </ContainerCentered>
);

export const BrokenPlate = (
  <ContainerCentered>
    <ImageSmall
      src="./images/storyDesktop/plate.png"
      alt="Robles Park Village"
    />
    <Header>Broken Plate</Header>
    <Paragraph>
      African American cemeteries in the South often share the practice of
      decorating the surface of graves with ceramics, glassware, cooking pots,
      or shiny objects. This practice may be traced back to Africa, where a wide
      variety of items used by the dead individual were placed on the grave.
      Some anthropologists believe intentionally broken objects at a gravesite
      represent the breaking of life and encourages the recently departed to
      stay in the afterlife with the objects. Others trace the practice to a
      belief that breaking everyday objects at a gravesite guards the grave,
      preventing the dead from returning to direct the lives of those still
      living. It is believed broken items will release the spirit of the dead,
      allowing it to travel to the next world.
    </Paragraph>
  </ContainerCentered>
);

export const Bottle = (
  <ContainerCentered>
    <ImageSmall
      src="./images/storyDesktop/bottle.png"
      alt="Robles Park Village"
    />
    <Header>The Bottle</Header>
    <Paragraph>
      A few old glass bottles were among the material resources recovered at
      Zion Cemetery during an Archaeological excavation led by Cardno, the
      archaeology company hired to excavate the site. Bottles were used in
      several different ways in Southern African American burial practices.
      Sometimes graves were bordered by bottles that were partially buried in
      the ground. According to Congo tradition, the bottles help to keep evils
      from the spirit and the spirit from wandering into the living world.
      Funeral processions and burial rituals often incorporated libations, the
      celebration of life through the sharing of drinks with the recently
      departed. Often libation bottles were left at grave sites after moments of
      celebration.
    </Paragraph>
  </ContainerCentered>
);
