import React from "react";
import { Btn } from "../../styles/RestartMusic";
import { useNavigate } from "react-router-dom";

interface HomeProps {
  RestartFn: () => void;
  Chapter: number;
}

const Home: React.FC<HomeProps> = ({ RestartFn, Chapter }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    RestartFn();
    navigate("/");
  };

  if (Chapter === 4) return null;
  return (
    <Btn
      backgroundImageUrl={"/images/storyPage/home.png"}
      onClick={handleClick}
    />
  );
};

export default Home;
