import {
  Header,
  Paragraph,
  ContainerCentered,
  Image,
} from "../styles/sideModal.styles";
import AudioPlayer from "../../../components/audioPlayer";

export const Segregation = (
  <ContainerCentered>
    <Header>Segregation</Header>
    <Paragraph>
      Racial Segregation was constitutionalized by the landmark U.S Supreme
      Court case Perry v. Ferguson in 1896. These laws were known as Jim Crow
      and “separate but equal” laws. These laws often determined the specific
      spaces where Black people were permitted to reside, eat, socialize, learn,
      travel, shop, and work. Jim Crow Laws were powerful beyond lived
      experiences; the laws were also enforced in death and determined where
      Black people were permitted to be laid to rest.  Even cemeteries were
      racially segregated. This Supreme Court ruling was overturned in 1954 by
      the landmark case Brown v. Board of Education. It would take decades after
      this ruling for desegregation to be enforced.
    </Paragraph>
  </ContainerCentered>
);

export const TheDeed = (
  <ContainerCentered>
    <Header>The Deed</Header>
    <Paragraph>
      In 1907, a Black owned Industrial Company with a direct connection to St.
      Paul AME Church purchased Zion Cemetery from Richard Doby. The cemetery
      remained active through this transfer of deed. And, hundreds of African
      American remains were interred at the location including members of the
      nearby Mt. Carmel Church.  A few years later, Zion Cemetery would become
      the center of a legal battle. Cemeteries are typically protected by laws.
      Still in 1911, Judge Robles granted ownership of the African American
      cemetery to Dr. James J. Head over a tax deed. Head, at the time, was the
      Hillsborough County treasurer. He was also a veteran and Commanding
      Officer of the Confederate Army. Florida Industrial and Commercial Co. did
      not relinquish ownership.  Judge Robles was unaware that the land was a
      cemetery.  Then in 1915, the cemetery was sold in an auction. The last
      recorded burial was in 1921. The 1925 deed of the property indicates Alice
      Fuller sold Zion Cemetery for $1 to H.P. Kennedy. Development of the land
      soon follows.
    </Paragraph>
  </ContainerCentered>
);

export const ExhumedGraves = (
  <ContainerCentered>
    <Header>Exhumed Graves</Header>
    <Paragraph>
      New commercial buildings were built on what was the entrance of Zion
      cemetery in 1929. Tampa Housing Authority sought to purchase and develop
      Zion Cemetery land in 1950. Mt. Caramel Church filed a lawsuit against
      Tampa Housing Authority to halt development, but the case was lost. When
      groundbreaking for the housing complex began, caskets were unearthed.
      Despite this, development continued. The newly constructed Roble Park
      Village consisted of 67 buildings and was accepting White tenants only by
      1953.  On the surface, Zion Cemetery was completely erased but a 1962
      Tampa Times article highlighted that the cemetery was not forgotten by
      community members.
    </Paragraph>
    <AudioPlayer
      url={"./music/story/soundBytes/Zion_Story_Segregation_Fred.mp3"}
    />
  </ContainerCentered>
);

export const Gentrification = (
  <ContainerCentered>
    <Image
      src="./images/storyDesktop/roblesParkVillage.jpg"
      alt="Robles Park Village"
    />
    <Header>Gentrification</Header>
    <Paragraph>
      Gentrification is a term that often comes to mind in discussion of Black
      communities being erased or replaces with White tenants.  Robles Park
      Village was a White only community that was constructed over a sacred part
      of the African American Community, Zion Cemetery. It would be nearly a
      decade later before African American Families are permitted to reside in
      the Robles Park Village.
    </Paragraph>
    <AudioPlayer
      url={"./music/story/soundBytes/Zion_Story_Segregation_Reva.mp3"}
    />
  </ContainerCentered>
);
