import styled from "styled-components";

export const MusicRestartUI = styled.div`
  position: absolute;
  z-index: 1;
  top: 120px;
  left: 15px;
`;
export const Btn = styled.div`
  background-image: url(${(props: { backgroundImageUrl: string }) =>
    props.backgroundImageUrl});
  transition: background-image 0.2s ease-in-out;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  margin-bottom: 12px;
  background-size: cover;
  background-position: center;
`;
