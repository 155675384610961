import React from "react";
import { Title as StyledTitle } from "./styles/chapterTitlesStyles";

interface TitleProps {
  title: string;
  HideZionIntro: boolean;
}

const Title: React.FC<TitleProps> = ({ title, HideZionIntro }) => {
  return <StyledTitle render={HideZionIntro}>{title}</StyledTitle>;
};

export default Title;
