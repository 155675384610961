import React, { useRef } from "react";
import * as THREE from "three";
import { useFrame } from "@react-three/fiber";
import { fragmentShader, vertexShader } from "./radar/radarShader";
import { useTexture } from "@react-three/drei";

const Radar = () => {
  const mat = useRef(null);

  useFrame(({ clock }) => {
    
    if (!mat.current) return;
    const elapsedTime = clock.getElapsedTime();
    // @ts-ignore
    mat.current.uniforms.iTime.value = elapsedTime;
  });
  const grass = useTexture("/textures/radar/grass.jpg");

  return (
    <shaderMaterial
      ref={mat}
      vertexShader={vertexShader}
      fragmentShader={fragmentShader}
      uniforms={{
        iTime: { value: 0 },
        grass: { value: grass },
      }}
    />
  );
};

export default React.memo(Radar);
