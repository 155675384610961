import * as React from "react";
import { motion } from "framer-motion";
import { StyledButton } from "./styles/menuStyles";
import { isMobile } from "react-device-detect";

const Path = (props: any) => (
  <motion.path
    fill="transparent"
    strokeWidth="3"
    stroke="hsl(0, 0%, 18%)"
    strokeLinecap="round"
    {...props}
  />
);
interface MenuToggleProps {
  toggle: () => void;
  isOpen: boolean;
}

export const MenuToggle: React.FC<MenuToggleProps> = ({ toggle, isOpen }) => (
  <StyledButton onClick={toggle} isOpen={isOpen}>
    <svg
      width={isMobile ? "16" : "23"}
      height={isMobile ? "16" : "23"}
      viewBox={isMobile ? "0 0 23 23" : "0 0 23 23"}
    >
      <Path
        variants={{
          closed: { d: "M 2 2.5 L 20 2.5" },
          open: { d: "M 3 16.5 L 17 2.5" },
        }}
      />
      <Path
        d="M 2 9.423 L 20 9.423"
        variants={{
          closed: { opacity: 0.8 },
          open: { opacity: 0 },
        }}
        transition={{ duration: 0.1 }}
      />
      <Path
        variants={{
          closed: { d: "M 2 16.346 L 20 16.346" },
          open: { d: "M 3 2.5 L 17 16.346" },
        }}
      />
    </svg>
  </StyledButton>
);
