import {
  Header,
  Paragraph,
  ContainerCentered,
  Image,
  ParagraphSmall,
} from "../styles/sideModal.styles";
import AudioPlayer from "../../../components/audioPlayer";

export const SchoolHouse = (
  <ContainerCentered>
    <Image
      src="./images/storyDesktop/schoolHouse.jpg"
      alt="Robles Park Village"
    />
    <Header>The School House</Header>
    <Paragraph>
      Prior to the development of the housing complex the area was mostly rural.
      Robles Pond was a wood framed school near the Zion Cemetery that was
      attended by many East Tampa African American children.  It was an
      all-Black school due to Jim Crow Laws that perpetuated racial segregation.
    </Paragraph>
    <AudioPlayer
      url={"./music/story/soundBytes/Zion_Story_RublesPond_Fred.mp3"}
    />
    <div style={{ marginTop: "2rem" }}></div>
    <Paragraph>Fred Hearns</Paragraph>
    <ParagraphSmall>
      President of the Zion Cemetery Preservation & Maintenance Society
    </ParagraphSmall>
  </ContainerCentered>
);

export const Deed = (
  <ContainerCentered>
    <Header>THE DEED</Header>
    <Paragraph>
      Historians use primary sources, like this deed, to gather evidence and
      understandings of past people, places, and events. A deed is a tangible
      document signed by the seller, or grantor, of a property. It confirms
      transfer of ownership from one party to another. Property deeds are useful
      for creating a historical time-line of owners. One of the original owners
      of Zion Cemetery is Richard Dolby. Today the land is divided between three
      different owners.  The land formerly known as Zion Cemetery is owned by
      Tampa Housing Authority (Robles Park Village), Richard Gonzmart a Tampa
      restaurateur, and Sunstate Wrecker services.
    </Paragraph>
  </ContainerCentered>
);
