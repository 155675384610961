import React, { useCallback, useState, useEffect, useRef } from "react";
import state from "../../state/state";
import CameraControls from "./CameraControls";
import { cameraData } from "../../data/camera.data";
import { cameraObjectData } from "../../data/cameraObject.data";
import MoveCamera from "./MoveCamera";
import {
  smoothstep,
  easeInSine,
  easeOutSine,
  easeInOutSine,
  easeInOutCubic,
  easeInOutCirc,
  easeOutQuint,
} from "../utils/easingFunctions";
import ManualMoveCamera from "./ManualMoveCamera";
import OrbitControlsContainer from "./OrbitControlsContainer";
import { useControls } from "leva";

import { history, returnPreviousHistory } from "../../state/util/history";

interface CameraProps {
  Area: number;
  Hotspot: number;
  Chapter: number;
}

const CameraAssembly: React.FC<CameraProps> = ({ Area, Hotspot, Chapter }) => {
  const [camData, setCamData] = useState({
    position: cameraData[0].position[0],
    target: cameraData[0].target[0],
  });
  const [duration, setDuration] = useState(12000);
  const firstCameraMove = useRef(false);

  const Object = state((s) => s.Object);

  useEffect(() => {
    const previousState = returnPreviousHistory();
    if (previousState === null) return;
    if (!firstCameraMove.current) {
      firstCameraMove.current = true;
      setDuration(12000);
    } else if (Area === 3 && Chapter === 0) {
      setDuration(15000);
    } else if (Area === 3 && Chapter > 4) {
      setDuration(20000);
    } else if (Area === 3 && Chapter > 0) setDuration(30000);
    else if (previousState?.Area === 0) {
      setDuration(35000);
    } else if (Area === 1 && previousState.Area === 1) setDuration(8000);
    else if (previousState.Area === 3) setDuration(4000);
    else if (previousState.Area > 1) setDuration(12000);
    else if (Area > 1) setDuration(19000);
    else setDuration(12000);

    if (Object !== null && Area === 3) {
      setCamData({
        position: cameraObjectData[Object].position,
        target: cameraObjectData[Object].target,
      });
    } else {
      setCamData({
        position: cameraData[Area].position[Area !== 3 ? Hotspot : Chapter],
        target: cameraData[Area].target[Area !== 3 ? Hotspot : Chapter],
      });
    }
  }, [Area, Chapter, Hotspot, Object]);

  useEffect(() => {
    firstCameraMove.current = true;
  }, []);

  const handleOnStart = useCallback(() => {
    // console.log(`start`);
    // if (!controls) return;
    // controls.enabled = false;
  }, []);

  const handleOnEnd = useCallback(() => {
    // console.log(`end`);
    // if (!controls) return;
    // controls.enabled = true;//todo: update duration to null
  }, []);

  const onChange = useCallback(() => {
    // console.log(`change`);
    // console.log("Changed", position, target)
  }, []);

  const { enabled } = useControls("OrbitControls", {
    enabled: { value: false },
  });

  //todo: need to work on percision and when onEnd gets triggered

  return (
    <>
      {enabled && <OrbitControlsContainer />}
      {!enabled && (
        <MoveCamera
          cameraData={camData}
          duration={duration}
          onStart={handleOnStart}
          onEnd={handleOnEnd}
          onChange={onChange}
          easing={easeInOutCubic}
        />
      )}

      <CameraControls />
      {/* <ManualMoveCamera /> */}
    </>
  );
};

export default React.memo(CameraAssembly);
