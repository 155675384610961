import React, { useMemo, useRef } from "react";
import * as three from "three";
import { useThree, useFrame } from "@react-three/fiber";
//* Util
import createParticlesAttributes from "./risingSpirits/util/createParticlesAttributes";
//* Shaders
import { vertexShader } from "./risingSpirits/vertexShader";
import { fragmentShader } from "./risingSpirits/fragmentShader";

const RisingSpirits = () => {
  const { gl } = useThree();

  const particleCount = 100;
  const particleSize = 25;
  const power = 1.8;
  const width = 1.5;
  const height = 2.5;
  const depth = 1.5;

  const material = useRef<three.ShaderMaterial>(null);

  useFrame(({ clock }) => {
    if (material.current === null) return;

    material.current.uniforms.uTime.value = clock.getElapsedTime();
  });

  const { positions, velocities, randomness } = createParticlesAttributes(
    particleCount,
    power,
    width,
    height,
    depth
  );

  const uniforms = useMemo(
    () => ({
      uSize: {
        value: particleSize * gl.getPixelRatio(),
      },
      uTime: {
        value: 0.0,
      },
      uGravity: {
        value: -3,
      },
    }),
    [gl]
  );

  return (
    <mesh position={[0, 0, 0]}>
      <points>
        <bufferGeometry attach="geometry">
          <bufferAttribute
            attach="attributes-position"
            count={positions.length / 3}
            array={positions}
            itemSize={3}
            usage={three.DynamicDrawUsage}
          />
          <bufferAttribute
            attach="attributes-aVelocity"
            count={velocities.length / 3}
            array={velocities}
            itemSize={3}
            usage={three.DynamicDrawUsage}
          />
          <bufferAttribute
            attach="attributes-aRandomness"
            count={randomness.length}
            array={randomness}
            itemSize={1}
            usage={three.DynamicDrawUsage}
          />
        </bufferGeometry>
        <shaderMaterial
          ref={material}
          fragmentShader={fragmentShader}
          vertexShader={vertexShader}
          uniforms={uniforms}
          depthWrite={false}
          vertexColors={true}
          blending={three.AdditiveBlending}
        />
      </points>
    </mesh>
  );
};

export default RisingSpirits;
