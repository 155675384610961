import React, { useEffect, useState } from "react";
import styled, { keyframes, css } from "styled-components";

const fadeIn = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

const Background = styled.div<{
  image: string;
  animation: any;
  zIndex: number;
}>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  opacity: 0;
  background-image: url(${(props) => props.image});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  animation: ${(props) => props.animation} 2s ease-in-out forwards;
  z-index: ${(props) => props.zIndex};
`;

const Title = styled.div`
  position: absolute;
  top: 7rem;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 24;
  font-size: 5.5rem;
  font-family: KinfolkReg;
  color: white;
`;

const About: React.FC = () => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(true);
  }, []);

  return (
    <>
      <Title>About</Title>
      <Background
        image="./images/about/aboutBackground.jpg"
        animation={
          loaded
            ? css`
                ${fadeIn}
              `
            : ""
        }
        zIndex={23}
      />
    </>
  );
};

export default About;
