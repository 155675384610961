import React, { useState, MouseEventHandler } from "react";
import { PlaybackButton } from "./styles/audioPlayer.styles";

interface IconProps {
  className?: string;
  style?: React.CSSProperties;
}

const PauseIcon: React.FC<IconProps> = (props) => (
  <svg viewBox="0 0 47.607 47.607" {...props}>
    <path
      fill="currentColor"
      d="M17.991 40.976a6.631 6.631 0 01-13.262 0V6.631a6.631 6.631 0 0113.262 0v34.345zM42.877 40.976a6.631 6.631 0 01-13.262 0V6.631a6.631 6.631 0 0113.262 0v34.345z"
    />
  </svg>
);

const PlayIcon: React.FC<IconProps> = (props) => (
  <svg viewBox="0 0 1000 1000" {...props}>
    <path
      fill="currentColor"
      d="M96.51 11.97c-31.23 8.05-53.26 32.76-63.42 71.27-3.45 12.84-3.64 29.7-3.64 416.71s.19 403.87 3.64 416.71c16.09 60.74 61.69 86.03 120.9 67.25 9-2.87 53.65-25.1 116.49-58.24 56.14-29.51 221.29-116.3 367.28-192.93 145.99-76.64 271.29-143.31 278.38-148.1 39.28-25.68 59.59-63.04 53.26-97.52-4.79-26.63-24.33-53.07-52.88-71.65C892 399.37 172.58 22.32 154.95 16.38c-18.97-6.33-43.3-8.24-58.44-4.41z"
    />
  </svg>
);

interface PlaybackBtnProps {
  playbackStatus: "play" | "pause";
  onClick: MouseEventHandler<HTMLButtonElement>;
}

const PlaybackBtn: React.FC<PlaybackBtnProps> = ({
  playbackStatus,
  onClick,
}) => {
  const [hover, setHover] = useState(false);

  return (
    <PlaybackButton
      isHovered={hover}
      onClick={onClick}
      className="playback-button"
      onPointerEnter={() => setHover(true)}
      onPointerLeave={() => setHover(false)}
    >
      {playbackStatus === "play" ? <PauseIcon /> : <PlayIcon />}
    </PlaybackButton>
  );
};

export default PlaybackBtn;
