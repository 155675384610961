import { useThree } from "@react-three/fiber";
import { useControls } from "leva";
import { PerspectiveCamera } from "three";

function CameraControls() {
  const { camera } = useThree();

  useControls("CameraControls", {
    fov: {
      value: 60,
      min: 1,
      max: 180,
      step: 1,
      onChange: (val: number) => {
        if (camera instanceof PerspectiveCamera) {
          camera.fov = val;
          camera.updateProjectionMatrix();
        }
      },
    },
    near: {
      value: 0.1,
      min: 0.01,
      max: 10,
      step: 0.01,
      onChange: (val: number) => {
        if (camera instanceof PerspectiveCamera) {
          camera.near = val;
          camera.updateProjectionMatrix();
        }
      },
    },
    far: {
      value: 5000,
      min: 100,
      max: 10000,
      step: 1,
      onChange: (val: number) => {
        if (camera instanceof PerspectiveCamera) {
          camera.far = val;
          camera.updateProjectionMatrix();
        }
      },
    },
  });

  return null;
}

export default CameraControls;
