import styled from "styled-components";
import background from "../../../assets/images/loader/zionLoadingBackground.jpg";
import redStroke from "../../../assets/images/loader/redStroke.png";

export const FallbackLoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: url(${background}) no-repeat center center fixed;
  background-size: cover;
`;

export const LoadingWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    scale: 0.8;
  }
`;

export const BackgroundImage = styled.div`
  position: absolute;
  top: 90%;
  left: 50%;
  width: 100vw;
  height: 100vh;
  background: url(${redStroke}) no-repeat center center;
  transform: translate(-50%, -50%);
  background-size: 35rem auto;
`;

export const LoadingText = styled.div`
  font-family: "ApothecarySerifSpaced", serif;
  font-style: normal;
  font-weight: 400;
  font-size: 3.2rem;
  line-height: 3rem;
  text-align: center;
  color: #ffffff;
  display: flex;
  z-index: 1;
  margin-left: 3rem;
  letter-spacing: -2.3244px;
`;

export const Dots = styled.span`
  font-family: "ApothecarySerifSpaced", serif;
  min-width: 1.2em;
  text-align: left;
  letter-spacing: -2.3244px;
`;
