import React from "react";
import Music from "./Music";
import Restart from "./Restart";
import Home from "./Home";
import { MusicRestartUI } from "../../styles/RestartMusic";
import state from "../../../../../state/arState";
import parser from "ua-parser-js";
import { OsTypes } from "../../Util/OsTypes";
import { useNavigate } from "react-router-dom";

interface Props {
  ScenePlaced: boolean;
  Chapter: number;
}

const SoundAndRestart: React.FC<Props> = ({ ScenePlaced, Chapter }) => {
  const RestartFn = state((s) => s.Restart);
  const setUserEventRecorded = state((s) => s.setUserEventRecorded);
  const UA = new parser();
  const os = UA.getOS();
  const navigate = useNavigate();

  const handleRestart = () => {
    RestartFn();
    if (os.name === OsTypes.IOS) {
      setUserEventRecorded(false);
      navigate("/");
    }
  };

  return (
    <MusicRestartUI>
      {os.name === OsTypes.Android && (
        <Home RestartFn={RestartFn} Chapter={Chapter} />
      )}
      <Music ScenePlaced={ScenePlaced} Chapter={Chapter} />
      <Restart
        Chapter={Chapter}
        handleRestart={handleRestart}
        ScenePlaced={ScenePlaced}
      />
    </MusicRestartUI>
  );
};

export default SoundAndRestart;
