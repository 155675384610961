import styled from "styled-components";

export const Flexbox = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 0px 50px 0px 0px;
`;

export const LeftBox = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const RightBox = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  padding-right: 20px;
`;

export const Text = styled.h1`
  margin: 0;
  position: relative;
`;

export const Title2 = styled.div`
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-text: center;
  font-family: Iowan Old Style;
  font-size: 14px;
  font-weight: 300;
`;

export const Strikethrough = styled.div`
  position: absolute;
  right: 0;
  right: 0;
  top: 50%;
  height: 2px;
  opacity: 0.15;
  background-color: black;
  width: 100vw;
  transform: translateY(-50%);
`;
