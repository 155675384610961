import styled from "styled-components";
import Sheet from "react-modal-sheet";

export const SheetModalDiv = styled.div`
  position: absolute;
  z-index: 1000;
`;

export const CustomSheet = styled(Sheet)`
  .react-modal-sheet-backdrop {
  }
  .react-modal-sheet-container {
    background-color: transparent !important;
    box-shadow: none !important;
    background-image: url(${(props: { backgroundimageurl: string }) =>
      props.backgroundimageurl}) !important;
    background-size: cover !important;
    // background-position: center !important; // ! with this on the gold gravestone on the sheet modal will disapear when short
    z-index: 1 !important;
    position: absolute !important;
    pointer-events: none !important;
  }
  .react-modal-sheet-header {
    background-color: transparent !important;
    position: absolute !important;
    bottom: 260px !important;
    pointer-events: auto !important;
  }
  .react-modal-sheet-drag-indicator {
    z-index: 4 !important;
    width: 15px !important;
    background-color: red !important;
    pointer-events: auto !important;
  }
  .react-modal-sheet-content {
    // pointer-events: none !important;
    pointer-events: auto !important;
  }
`;
