import React from "react";
import { Circle, Container, OuterContainer } from "../../styles/Carousel";

interface Props {
  selectedIndex: number;
  onSelectedIndexChanged: (index: number) => void;
}

const images: string[] = [
  "/images/carousel/1.png",
  "/images/carousel/2.png",
  "/images/carousel/3.png",
  "/images/carousel/4.png",
  "/images/carousel/5.png",
];

const Carousel: React.FC<Props> = ({
  selectedIndex,
  onSelectedIndexChanged,
}) => {
  const circles: number[] = [...Array(5).keys()];

  return (
    <OuterContainer>
      <Container>
        {circles.map((index: number) => (
          <Circle
            key={index}
            selected={selectedIndex === index}
            onClick={() => onSelectedIndexChanged(index)}
            selectedBackground={images[index]}
            circle={"/images/carousel/whiteCircle.png"}
          />
        ))}
      </Container>
    </OuterContainer>
  );
};

export default Carousel;
