import React from "react";
import { LandingPageWrapper, Button, HomeLink } from "./styles/landingPage";
import { useNavigate } from "react-router-dom";
import TitleSubtitle from "./TitleSubtitle";

interface LandingPageProps {
  backgroundImageUrl: string;
  buttonImageUrl: string;
  setUserEventRecorded: (value: boolean) => void;
}

const LandingPage: React.FC<LandingPageProps> = ({
  backgroundImageUrl,
  buttonImageUrl,
  setUserEventRecorded,
}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    // setUserEventRecorded(true);
    navigate("/ar/story");
  };

  return (
    <LandingPageWrapper backgroundImageUrl={backgroundImageUrl}>
      <HomeLink>
        <a href="http://zioncemeteryrememberance.com/">
          <div className="button">
            <img src="/images/landingPage/ui_exit.svg" />
            Back to site
          </div>
        </a>
      </HomeLink>
      <TitleSubtitle color={"#fff"} margin={"75px 0 0 0"} />
      <Button src={buttonImageUrl} alt="Button" onClick={handleClick} />
    </LandingPageWrapper>
  );
};

export default LandingPage;
