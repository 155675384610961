import styled from "styled-components";

export const Title = styled.h1`
  font-family: "KinfolkReg", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12rem;
  line-height: 12.5rem;
  text-align: center;
  letter-spacing: -2.3244px;
  color: #000000;
  margin: 0;
  @media (max-width: 768px) {
    font-size: 9rem;
  }
`;

export const Subtitle = styled.h2`
  font-family: "KinfolkReg", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 2.9rem;
  line-height: 3.2rem;
  text-align: center;
  letter-spacing: -0.601804px;
  color: #000000;
  margin: 0 0 5rem 0;
  @media (max-width: 768px) {
    font-size: 2.6rem;
    margin: 0 0 4rem 0;
  }
`;
