import React, { useState, useEffect, useCallback } from "react";
import { DotButton } from "./ModalCarouselBtns";
import useEmblaCarousel from "embla-carousel-react";
import "../../styles/modalCarousel.css";
import { data } from "../../Util/SceneData";
import {
  Flexbox,
  LeftBox,
  RightBox,
  Strikethrough,
  Text,
  Title2,
} from "../../styles/ModalCarousel";
import state from "../../../../../state/arState";

interface ModalCarouselProps {
  slides: number[];
  Chapter: number;
  TextIndex: number;
}

const ModalCarousel = ({ slides, Chapter, TextIndex }: ModalCarouselProps) => {
  const [viewportRef, embla] = useEmblaCarousel({
    skipSnaps: false,
    active: true,
    loop: false,
  });
  // const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  // const [nextBtnEnabled, setNextBtnEnabled] = useState(false);
  // const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState<number[]>([]);
  const setTextIndex = state((s) => s.setTextIndex);

  const scrollTo = useCallback(
    (index: number) => embla && embla.scrollTo(index),
    [embla]
  );

  const onSelect = useCallback(() => {
    if (!embla) return;
    setTextIndex(embla.selectedScrollSnap());
    // setPrevBtnEnabled(embla.canScrollPrev());
    // setNextBtnEnabled(embla.canScrollNext());
  }, [embla, setTextIndex]);

  useEffect(() => {
    //* Reset amount of gravestones at bottom when switching chapters
    if (embla) embla.reInit();
  }, [embla, slides]);

  useEffect(() => {
    if (!embla) return;
    onSelect();
    setScrollSnaps(embla.scrollSnapList());
    embla.on("select", onSelect);
  }, [embla, setScrollSnaps, onSelect, slides, Chapter]);

  useEffect(() => {
    if (!embla) return;
    scrollTo(0);
  }, [embla, Chapter, scrollTo]);

  return (
    <>
      <div className="embla">
        <div className="embla__viewport" ref={viewportRef}>
          <div className="embla__container">
            {slides.map((index) => (
              <div className="embla__slide" key={index}>
                <div className="embla__slide__inner">
                  {TextIndex === 0 && (
                    <>
                      <h3>{data[Chapter].chapter}</h3>
                      <Flexbox>
                        <LeftBox>
                          <Text>{data[Chapter].title}</Text>
                        </LeftBox>
                        <RightBox>
                          {TextIndex === 0 && (
                            <Text>
                              {data[Chapter].year}
                              <Strikethrough />
                            </Text>
                          )}
                        </RightBox>
                      </Flexbox>
                    </>
                  )}
                  {TextIndex > 0 && <Title2>{data[Chapter].title}</Title2>}

                  <p>{data[Chapter].text[TextIndex]}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
        {/* <PrevButton onClick={scrollPrev} enabled={prevBtnEnabled} /> */}
        {/* <NextButton onClick={scrollNext} enabled={nextBtnEnabled} /> */}
      </div>
      <div className="embla__dots">
        {scrollSnaps.map((_, index) => (
          <DotButton
            key={index}
            selected={index === TextIndex}
            onClick={() => scrollTo(index)}
          />
        ))}
      </div>
    </>
  );
};

export default ModalCarousel;
