import React, { useEffect, useState } from "react";
import {
  FallbackLoaderContainer,
  LoadingText,
  Dots,
  BackgroundImage,
  LoadingWrapper,
} from "./styles/fallbackLoaderStyles";
import { Title, Subtitle } from "../../styles/titleStyles";

interface FallbackLoaderProps {}

export const FallbackLoader: React.FC<FallbackLoaderProps> = () => {
  const [dots, setDots] = useState("");

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prev) => (prev.length < 3 ? "." + prev : ""));
    }, 500);
    return () => clearInterval(interval);
  }, []);

  return (
    <FallbackLoaderContainer>
      <Title>ZION</Title>
      <Subtitle>CEMETERY</Subtitle>
      <LoadingWrapper>
        <BackgroundImage />
        <LoadingText>
          Loading
          <Dots>{dots}</Dots>
        </LoadingText>
      </LoadingWrapper>
    </FallbackLoaderContainer>
  );
};
