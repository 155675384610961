export const vertexShader = `
  uniform float time;
  varying vec2 vuv; 
  
  
  void main() {
    vec4 modelPosition = modelMatrix * vec4(position, 1.0);
   
    //Final
    vec4 viewPosition = viewMatrix * modelPosition;
    vec4 projectedPosition = projectionMatrix * viewPosition;
    gl_Position = projectedPosition;
    
  

    // Props
      vuv = uv;
  }
`;

export const fragmentShader = `
  uniform float time;  
  uniform float uOpacity;  
  varying vec2 vuv;

 float easeInOutQuad(float t) {
    return t < 0.5 ? 2.0 * t * t : -1.0 + (4.0 - 2.0 * t) * t;
}
  
  void main() {
    
    vec3 color = vec3(0.);
    vec3 white = vec3(0.9);
    color = mix(color, white, easeInOutQuad(time * .75));

    color *= ((1. + (-vuv.x * vuv.y) )+ easeInOutQuad(time * .75));

    gl_FragColor = vec4(color, uOpacity);
  }
`;
