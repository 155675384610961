import React, { Suspense } from "react";
import DevToolsUI from "../../../components/devTools/ui";
import ConstantUI from "./ConstantUI";
import Navigation from "./Navigation";
import state from "../../../state/state";
import ScrollEvent from "../../../components/scroll";
import SideModal from "../../../components/sideModal";
import StoryUI from "../../../components/story/ui";
import CallToAction from "../../../components/callToActionDesktop/CallToAction";
import About from "../../../components/about";
import MouseTracker from "../../../components/mouseTracker";
import AnimatedWhiteOverlay from "../../../components/animatedWhiteOverlay";

interface Props {
  Area: number;
  Hotspot: number;
}

const UI: React.FC<Props> = ({ Area, Hotspot }) => {
  const RenderCallToAction = state((s) => s.RenderCallToAction);
  const RenderAbout = state((s) => s.RenderAbout);

  return (
    <>
      <DevToolsUI DevEnvironment />
      <Suspense fallback={null}>
        <MouseTracker />
      </Suspense>
      <ConstantUI Area={Area} />
      <SideModal Hotspot={Hotspot} Area={Area} />
      <AnimatedWhiteOverlay />
      <ScrollEvent visible={Area === 0} />
      {(Area === 1 || Area === 4 || Area === 2) && (
        <Navigation Hotspot={Hotspot} Area={Area} />
      )}
      {Area === 3 && <StoryUI />}
      {RenderCallToAction && <CallToAction />}
      {RenderAbout && <About />}
    </>
  );
};

export default UI;
