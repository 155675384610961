import React, { useState, useEffect } from "react";
import Modal from "./Modal";
import { Heading, Subheading, CloseButton } from "../../styles/CallToAction";
import TitleSubtitle from "../../../landing/TitleSubtitle";

interface CallToActionProps {
  Chapter: number;
  setChapter: (Chapter: number) => void;
}

const CallToAction: React.FC<CallToActionProps> = ({ Chapter, setChapter }) => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (Chapter === 4) {
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  }, [Chapter]);

  const handleCloseModal = () => {
    setChapter(3);
  };

  if (Chapter < 4) return null;

  return (
    <div>
      {/* <button onClick={handleOpenModal}>Open Modal</button> */}
      <Modal onClose={handleCloseModal} isOpen={showModal}>
        <CloseButton
          backgroundImageUrl={"/images/storyPage/x.png"}
          onClick={handleCloseModal}
        ></CloseButton>
        <TitleSubtitle color={"#000"} margin={"0"} />
        {/* <Spacer /> */}
        <Heading>MAKE CHANGE</Heading>
        <Subheading>
          It's time to get involved!! Contact your local city council and state
          leadership to ask them to preserve, protect, and include Zion Cemetery
          and local Florida African American history in education.
        </Subheading>
        <Heading>EMAIL CITY COUNCIL</Heading>
        <Subheading>
          <a href="mailto:tampacitycouncil@tampagov.net">
            tampacitycouncil@tampagov.net
          </a>
        </Subheading>
        <Heading>EMAIL THE FLORIDA GOVERNOR</Heading>
        <Subheading>
          <a href="https://www.flgov.com/email-the-governor" target="_blank">
            flgov.com/email-the-governor
          </a>
        </Subheading>
        <Heading>EMAIL THE DEPARTMENT OF EDUCATION</Heading>
        <Subheading>
          <a href="mailto:commissioner@fldoe.org">commissioner@fldoe.org</a>
        </Subheading>
      </Modal>
    </div>
  );
};

export default React.memo(CallToAction);
