import React, { useEffect, useState } from "react";
import AnimatedText from "./AnimatedText";
import { animatedTextData } from "../../data/animatedText.data";
import state from "../../state/state";
import { returnPreviousHistory } from "../../state/util/history";

interface TextProps {
  Area: number;
  Hotspot: number;
}

const TextComponent: React.FC<TextProps> = ({ Area, Hotspot }) => {
  const AnimatedTextHovered = state((s) => s.AnimatedTextHovered);
  const setAnimatedTextHovered = state((s) => s.setAnimatedTextHovered);
  const [allTextData, setAllTextData] = useState<any>([]);
  // const [hovered, setHovered] = useState<boolean>(false);
  const [showAll, setShowAll] = useState<boolean>(false);

  useEffect(() => {
    let tempData: any = [];

    Object.keys(animatedTextData).forEach((area) => {
      animatedTextData[Number(area)].data.forEach((data, index) => {
        tempData.push({
          area: area,
          index: index,
          data: data,
        });
      });
    });
    setAllTextData(tempData);
  }, []);

  const timeout = 2700;

  useEffect(() => {
    if (Area === 0) {
      setTimeout(() => {
        setShowAll(true);
      }, 2700);
    } else {
      const previousState = returnPreviousHistory();
      setShowAll(false);
      if (previousState?.Area === 0) {
        setTimeout(() => {
          setShowAll(true);
        }, 4000);
      } else {
        setTimeout(() => {
          setShowAll(true);
        }, timeout);
      }
    }
  }, [Area]);

  return (
    <>
      {allTextData.map(({ area, index, data }: any) => (
        <group key={`${area}-${index}`} renderOrder={3}>
          {data.map((textData: any, idx: number) => (
            <AnimatedText
              key={textData.label}
              id={idx}
              data={textData}
              hovered={AnimatedTextHovered}
              setHovered={setAnimatedTextHovered}
              show={showAll}
              revealSpeed={Area === 0 ? 0.5 : undefined}
              pointerEvents={true}
            />
          ))}
        </group>
      ))}
    </>
  );
};

export default React.memo(TextComponent);
