import React from "react";
import UI from "./ui";
import Scene from "./webgl/Scene";
import HistoryTracker from "../../state/util/HistoryTracker";
// import SoundAndMusic from "../../components/sound";
import state from "../../state/state";
const SoundAndMusic = React.lazy(() => import("../../components/sound"));

const Desktop = () => {
  const Area = state((s) => s.Area);
  const Hotspot = state((s) => s.Hotspot);

  return (
    <>
      <UI Area={Area} Hotspot={Hotspot} />
      <Scene />
      <SoundAndMusic Area={Area} />
      <HistoryTracker Area={Area} Hotspot={Hotspot} />
    </>
  );
};

export default Desktop;
