import React, { useState, useEffect } from "react";
import Modal from "./PopupModal";
import state from "../../state/state";

const PopupModalWrapper = () => {
  const [isVisible, setIsVisible] = useState(false);
  const setIntroVideoComplete = state((s) => s.setIntroVideoComplete);
  const lowerSoundVolume = state((s) => s.lowerSoundVolume);

  const videoUrl = "./video/Zion_Intro_vid_Fred.mp4";

  const handleClose = () => {
    // console.log(`vis`, isVisible);
    setIsVisible(false);
    setIntroVideoComplete(true);
    lowerSoundVolume("high");
  };

  useEffect(() => {
    setTimeout(() => {
      lowerSoundVolume("low");
      setIsVisible(true);
    }, 1850);
  }, [lowerSoundVolume, setIntroVideoComplete]);

  return (
    <Modal visible={isVisible} url={videoUrl} handleClose={handleClose}></Modal>
  );
};

export default PopupModalWrapper;
