import * as React from "react";
import { AudioPlayer, Controls } from "./styles/audioPlayer.styles";
import useAudio from "./useAudio";
import TimeBar from "./TimeBar";
import PlaybackBtn from "./PlaybackBtn";

interface AudioPlayerComponentProps {
  url: string;
}

const AudioPlayerComponent: React.FC<AudioPlayerComponentProps> = ({ url }) => {
  const [audioElement, audioProps] = useAudio(url);

  return (
    <AudioPlayer>
      {audioElement}
      {audioProps.isLoading ? (
        <div style={{ color: "white" }}>Loading...</div>
      ) : (
        <Controls>
          <PlaybackBtn
            onClick={audioProps.togglePlaybackStatus}
            playbackStatus={audioProps.playbackStatus}
          />
          <TimeBar
            currentTime={audioProps.currentTime}
            isSeeking={audioProps.isSeeking}
            duration={audioProps.duration}
            progress={audioProps.progress}
            setTime={audioProps.setTime}
          />
        </Controls>
      )}
    </AudioPlayer>
  );
};

export default AudioPlayerComponent;
