import React from "react";
import ChurchModel from "../../../models/ChurchModel";
import { useControls } from "leva";

const Church = () => {
  const { ChurchVisible } = useControls("Assets", {
    ChurchVisible: { value: true },
  });

  return (
    <group visible={ChurchVisible} scale={1}>
      <ChurchModel />
    </group>
  );
};

export default Church;
