/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import React, { Suspense, useRef, useState, useEffect } from "react";
import * as three from "three";
import { Loader, Plane, useTexture } from "@react-three/drei";
import { Canvas, useThree } from "@react-three/fiber";
import SceneContainer from "./Scene/AndroidSceneContainer";
import { CanvasWrapper } from "../styles/CanvasWrapper";
import { XR, ARButton, useHitTest } from "@react-three/xr";
import RisingSpirits from "../../../../shaders/RisingSpirits";
// import state from "../../../../state/arState";
// import { useNavigate } from "react-router-dom";

interface Props {
  setScenePlaced: (ScenePlaced: boolean) => void;
  ScenePlaced: boolean;
}

const CanvasContainerAndroid: React.FC<Props> = ({
  ScenePlaced,
  setScenePlaced,
}) => {
  const [initialized, setInitialized] = useState(false);
  // const RestartFn = state((s) => s.Restart);

  return (
    <CanvasWrapper>
      <ARButton
        // mode={"AR"}
        // enterOnly={true}
        sessionInit={{
          domOverlay:
            typeof document !== "undefined"
              ? { root: document.body }
              : undefined,
          requiredFeatures: ["hit-test", "viewer"],
          optionalFeatures: ["dom-overlay", "dom-overlay-for-handheld-ar"],

          // hitTestOptionsInit: { //todo: set manually?
          //   space: "viewer",
          //   entityTypes: ["plane"], //'point'
          //   offsetRay: new XRRay({ y: 1.5 }),
          // },
        }}
        style={{
          opacity: initialized ? "0" : "100",
          width: "100%",
          height: "100%",
          border: "none",
          position: "absolute",
          zIndex: "999999999999999",
          // left: "0%",
          // bottom: "5%",
          padding: "0",
          backgroundImage: "url('/images/landingPage/Landing2Background.png')",
          backgroundSize: "cover",
          backgroundPosition: "center",
          pointerEvents: initialized ? "none" : "auto",
        }}
      >
        <div
          style={{
            position: "absolute",
            // left: "0%",
            // margin:
            width: "100%",
            // bottom: "5%",
            height: "300px",

            // backgroundImage: "url('/images/landingPage/landing2Btn.png')",
            pointerEvents: "none",
          }}
        >
          <div
            style={{
              // fontWeight: "bold",
              // border: "2px solid red",
              padding: "20px 0 ",
              height: "100%",
              width: "100%",
              // backgroundColor: "white",
              backgroundImage: "url('/images/landingPage/landing2Btn.png')",
              backgroundSize: "cover",
              backgroundPosition: "center",
              pointerEvents: "none",
            }}
          ></div>
        </div>
      </ARButton>
      <Canvas
        camera={{
          far: 250,
          near: 0.0005,
          position: [0, 0, 5],
        }}
        dpr={[1, 2]}
      >
        <XR
          mode={"AR"}
          referenceSpace="unbounded"
          onSessionStart={() => setInitialized(true)}
          onSessionEnd={() => {
            setInitialized(false);
            // RestartFn();
            // navigate("/");
          }}
        >
          <XRWrapper
            ScenePlaced={ScenePlaced}
            setScenePlaced={setScenePlaced}
            initialized={initialized}
          />
          {ScenePlaced && <RisingSpirits />}
        </XR>
      </Canvas>
      <Loader />
    </CanvasWrapper>
  );
};

interface XRWrapperProps {
  ScenePlaced: boolean;
  setScenePlaced: (ScenePlaced: boolean) => void;
  initialized: boolean;
}

const XRWrapper: React.FC<XRWrapperProps> = ({
  initialized,
  ScenePlaced,
  setScenePlaced,
}) => {
  const { gl } = useThree();
  // const [visible, toggleVisibility] = useState(false);

  const hitTestSource = useRef<null | XRHitTestSource | undefined>(null);
  const localSpace = useRef<null | XRReferenceSpace | XRBoundedReferenceSpace>(
    null
  );
  const hitTestSourceInitialized = useRef(false);

  useEffect(() => {
    const initializeHitTestSource = async () => {
      if (!initialized) return;

      const session = gl.xr.getSession();
      if (session === null) return;

      // Reference spaces express relationships between an origin and the world.
      // For hit testing, we use the "viewer" reference space,
      // which is based on the device's pose at the time of the hit test.
      const viewerSpace = await session.requestReferenceSpace("viewer");
      if (!viewerSpace) return;
      hitTestSource.current = await session.requestHitTestSource({
        space: viewerSpace,
      });

      // We're going to use the reference space of "local" for drawing things.
      // which gives us stability in terms of the environment.
      // read more here: https://developer.mozilla.org/en-US/docs/Web/API/XRReferenceSpace
      localSpace.current = await session.requestReferenceSpace("local");

      // set this to true so we don't request another hit source for the rest of the session
      hitTestSourceInitialized.current = true;

      // todo: add cleanup function
      // In case we close the AR session by hitting the button "End AR"
      // session.addEventListener('end', () => {
      //   hitTestSourceInitialized = false;
      //   hitTestSource = null;
      // });
    };

    initializeHitTestSource();
  }, [initialized, gl.xr]);

  const ring = useRef<three.Mesh>(null);
  const reticle = useRef<three.Mesh>(null);
  const group = useRef<three.Group>(null);

  useHitTest((hitMatrix, hit) => {
    if (ScenePlaced) return;
    if (!reticle.current || !localSpace.current) return;
    if (hitTestSourceInitialized.current) {
      // Get a pose from the hit test result. The pose represents the pose of a point on a surface.
      const pose = hit.getPose(localSpace.current);

      reticle.current.visible = true;
      if (pose === undefined) return;

      // Transform/move the reticle image to the hit test position
      reticle.current.matrix.fromArray(pose.transform.matrix);

      reticle.current.rotateX(-Math.PI / 2);
      // hitMatrix.decompose(
      //   reticle.current.position,
      //   reticle.current.quaternion,
      //   reticle.current.scale
      // );
    } else {
      // reticle.current.position.set(0, 2, -6);
      reticle.current.visible = false;
    }

    // reticle.current.needsUpdate = true;
  });
  console.log(`reticle.current`, reticle.current);
  const handleClick = (e: any) => {
    if (ScenePlaced) return;
    if (!group.current || !reticle.current) return;

    group.current.position.setFromMatrixPosition(reticle.current.matrix);
    group.current.quaternion.setFromRotationMatrix(reticle.current.matrix);

    // toggleVisibility(true);
    setScenePlaced(true);
  };

  const xMarksTheSpot = useTexture("/images/storyPage/marker.png");

  // useEffect(() => {
  //   // reticle.current?.rotateY(-Math.PI / 2);
  // }, []);

  return (
    <Suspense fallback={null}>
      <group
        visible={initialized}
        // rotation={[-Math.PI / 2, 0, 0]}
      >
        <mesh
          onClick={handleClick}
          ref={reticle}
          position={[0, 2, -2]}
          matrixAutoUpdate={false}
          visible={!ScenePlaced}
        >
          {/* <Circle args={[0.1, 32]} ref={ring}> */}
          <Plane args={[0.3, 0.3]} ref={ring}>
            <meshBasicMaterial
              transparent
              side={three.DoubleSide}
              // color={"#ff0000"}
              map={xMarksTheSpot}
            />
          </Plane>
          {/* </Circle> */}
        </mesh>
      </group>
      <group visible={ScenePlaced} ref={group} position={[0, 0.8, -0.7]}>
        <SceneContainer />
      </group>
    </Suspense>
  );
};

export default CanvasContainerAndroid;
