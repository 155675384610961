import {
  Header,
  Paragraph,
  ContainerCentered,
} from "../styles/sideModal.styles";

export const About = (
  <ContainerCentered>
    <Header>Zion Cemetery</Header>
    <Paragraph>
      Documentation on Zion Cemetery is scarce.  There are no images of the
      actual cemetery. AR/VR technology is used to reimagine, memorialize,
      preserve, and educate others about the cemetery. By blending technology,
      art, oral histories, and primary sources, we’ve tried to recreate the
      visual history of Zion Cemetery. Documentation on Zion Cemetery is
      scarce.  There are no images of the actual cemetery. AR/VR technology is
      used to reimagine, memorialize, preserve, and educate others about the
      cemetery. By blending technology, art, oral histories, and primary
      sources, we’ve tried to recreate the visual history of Zion Cemetery.
    </Paragraph>
  </ContainerCentered>
);
