import styled, { css } from "styled-components";
import background from "../../../../assets/images/loader/zionLoadingBackground.jpg";

export const Background = styled.div<{ hide: boolean; show: boolean }>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 23;
  min-height: 100vh;
  background: url(${background}) no-repeat center center fixed;
  background-size: cover;
  opacity: 0;
  transition: opacity 0.75s ease-in-out;
  pointer-events: none;
  background-color: transparent;
  ${(props) =>
    props.show &&
    css`
      opacity: 1;
    `}

  ${(props) =>
    props.hide &&
    css`
      opacity: 0;
    `}
`;

export const Container = styled.div<{ hide: boolean; show: boolean }>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  z-index: 24;
  pointer-events: auto;
  opacity: 0;
  transition: opacity 0.75s ease-in-out;
  background-image: url("./images/storyDesktop/window.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 38rem 70rem;

  ${(props) =>
    props.show &&
    css`
      opacity: 1;
    `}

  ${(props) =>
    props.hide &&
    css`
      opacity: 0;
      pointer-events: none;
    `}
    @media (max-width: 768px) {
    background-size: 100% 100%;
  }
`;

export const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30rem;
  justify-content: center;

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 14rem;
`;

export const TitleContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 20rem;
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 24rem;
  min-height: 28rem;
  @media (max-width: 768px) {
    min-height: unset;
  }
`;

export const Title = styled.h1`
  font-size: 7rem;
  font-family: "KinfolkReg";
  margin: 0;
  margin-top: 6rem;
  @media (max-width: 768px) {
    margin-top: 5rem;
  }
`;

export const Subtitle = styled.h2`
  font-size: 1.9rem;
  font-family: "KinfolkReg";
  margin: 0;
  margin-bottom: 2rem;
  @media (max-width: 768px) {
    margin-bottom: 0rem;
  }
`;

export const Header = styled.h3`
  font-size: 2.7rem;
  text-align: center;
  font-family: "ApothecarySerifSpaced", serif;
  margin: 0;
  padding: 0;
  letter-spacing: -5px;
  @media (max-width: 768px) {
    margin-bottom: 0rem;
  }
`;

export const Text = styled.p`
  /* font-size: 1.4rem; */
  font-family: "Iowan Old Style";
  text-align: left;
  /* margin-bottom: 5rem; */
  font-size: 1.3rem;
  line-height: 1.8;
  @media (max-width: 768px) {
    margin: 0 0 1rem 0;
  }
`;

export const Image = styled.img`
  /* font-size: 1.4rem; */
  margin: 1rem 0;
  width: 20rem;
  @media (max-width: 768px) {
    height: 100%;
  }
`;

export const Link = styled.a`
  pointer-events: auto;
  /* font-size: 1.4rem; */
`;

export const Button = styled.button<{ hide: boolean; selected: boolean }>`
  box-sizing: border-box;
  font-size: 3rem;
  line-height: 1.6rem;
  letter-spacing: -2.5px;
  font-family: "ApothecarySerifSpaced", serif;
  padding-bottom: 1.75rem; // ! button text not centering fix
  background-color: white;
  border-radius: 1.8rem;
  width: 90%;
  height: 3.6rem;
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: none;
  color: ${(props) => (props.selected ? "grey" : "black")};
  opacity: ${(props) => (props.selected ? 0.5 : 1)};
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.05);
  transition: all 0.5s cubic-bezier(0, 0.2, 0.5, 1);
  pointer-events: ${(props) =>
    props.hide ? "none" : props.selected ? "none" : "auto"};
  cursor: pointer;
  @media (hover: hover) {
    &:hover {
      scale: 1.1;
    }
  }
  @media (max-width: 768px) {
    margin-top: 0rem;
    scale: 0.8;
  }
`;
