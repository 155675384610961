export const vertexShader = `

varying vec2 vertexUV;
varying vec3 vertexNormal;
varying vec2 vuv;

void main() {
    vertexUV = uv;
    vuv = uv;
    vertexNormal = normalize(normalMatrix * normal);
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
}
`;

export const fragmentShader = `

  varying vec2 vertexUV;
  varying vec3 vertexNormal;
  uniform float iTime;
  varying vec2 vuv;
  uniform sampler2D tTile; 
 

  float timeSpeed = .45; //.8;
  float reflectionAmount = 65.0; //100.0 = 100.0%;
  float reflectionIntensity = .0;
  // float reflectionIntensity = .8;
  float objectScale = .5; //1.0 is default;
  float causticScale = .14; // 1.0 is default;


float randomVal (float inVal){
    return fract(sin(dot(vec2(inVal, 2523.2361) ,vec2(12.9898,78.233))) * 43758.5453)-0.5;
}

vec2 randomVec2 (float inVal){
    return normalize(vec2(randomVal(inVal), randomVal(inVal+151.523)));
}
float makeWaves(vec2 uv, float theTime, float offset){
    float result = 0.0 + ((reflectionAmount/100.0)-(1.0));
    float direction = 0.0;
    float sineWave = 0.0;
    vec2 randVec = vec2(1.0,0.0);
    float i;
    for(int n = 0; n < 16; n++){
        i = float(n)+offset;
        //theTime += cursorDistance;
        randVec = randomVec2(float(i));
  		direction = (uv.x*randVec.x+uv.y*randVec.y);
        sineWave = sin(direction*randomVal(i+1.6516)+(theTime*timeSpeed));
        sineWave = smoothstep(0.0,1.0,sineWave);
    	result += randomVal(i+123.0)*sineWave;
    }
    return result;
}


  void main() {



    // intensity of atmospheric effect
    // float intensity = .35 + dot(vertexNormal, vec3(0.3, 0.6, 1.0));
    // atmospheric glow
    // vec4 waterColor = vec4(0.3,0.6,1.0, .5)* pow(intensity, 1.5);




    // * Caustic water
    //vec2 uv = fragCoord.xy / iResolution.x; //Uncomment for square image
    vec2 uv = vec2(vuv.x, vuv.y);
    vec2 uv2 = uv * (150.0/causticScale); // scale;
    uv /= objectScale;
    
    
    float result = 0.0;
    float result2 = 0.0;
    
    result = makeWaves( uv2+vec2(iTime*timeSpeed,0.0), iTime, 0.1);
    result2 = makeWaves( uv2-vec2(iTime*0.8*timeSpeed,0.0), iTime*0.8+0.06, 0.26);
    
    //result *= 2.6;
    
    result = smoothstep(0.4,1.1,1.0-abs(result))+(reflectionIntensity*.6);
    result2 = smoothstep(0.4,1.1,1.0-abs(result2))+(reflectionIntensity*.6);
    
    result = 2.0*smoothstep(0.35,1.8,(result+result2)*0.5);
    
	//fragColor = vec4(result)*0.7+texture( iChannel0 , uv );
    
    // thank for this code below Shane!
    vec2 p = vec2(result, result2)*.02 + sin(uv*16.0 - cos(uv.yx*16.0 + iTime*timeSpeed))*.005; 
    // vec4 tile = texture2D(tTile, vuv);

	  // vec4 col = (waterColor * .0) + vec4(result)*0.7;
	  vec4 col = vec4(result)*0.7;


    // col = mix(col, vec4(vec3(.5), 1.), result);

    
// fragColor = col+texture( tTile , uv + p );
    // * color correct
    // col = pow(col, vec4(1. / 2.2));


    gl_FragColor = col+texture( tTile , (mod(uv , .1) * 10.) + p  );
    // gl_FragColor = vec4(col);
  }


`;
