import {
  Image,
  Header,
  Paragraph,
  Container,
} from "../styles/sideModal.styles";

export const MapBW = (
  <Container>
    <Image src="./images/map/map01-512.jpg" alt="map" />
    <Header>THE DEED</Header>
    <Paragraph>
      On February 20th, 1901, a document was filed with the Hillsborough County
      Clerk of the Courts mapping Zion Cemetery. The cemetery was founded by
      Black businessman and philanthropist Richard Dolby. The land covers almost
      three acres. Zion is the oldest known African American Cemetery in Tampa,
      Florida. The map indicates the entrance to the cemetery was on Florida
      Avenue.  The cemetery includes 98 marked plats that families could
      purchase to bury their deceased. The map also indicates that there is a
      Potter’s field for people interred at the site that were unknown or
      indigent. Zion Cemetery has been inactive since the early 1920s.
    </Paragraph>
  </Container>
);

export const MapRG = (
  <Container>
    <Image src="./images/map/map02-512.png" alt="map" />
    <Header>THE DEED</Header>
    <Paragraph>
      On February 20th, 1901, a document was filed with the Hillsborough County
      Clerk of the Courts mapping Zion Cemetery. The cemetery was founded by
      Black businessman and philanthropist Richard Dolby. The land covers almost
      three acres. Zion is the oldest known African American Cemetery in Tampa,
      Florida. The map indicates the entrance to the cemetery was on Florida
      Avenue.  The cemetery includes 98 marked plats that families could
      purchase to bury their deceased. The map also indicates that there is a
      Potter’s field for people interred at the site that were unknown or
      indigent. Zion Cemetery has been inactive since the early 1920s.
    </Paragraph>
  </Container>
);

export const MapPresent = (
  <Container>
    <Image src="./images/map/map03-512.png" alt="map" />
    <Header>THE DEED</Header>
    <Paragraph>
      On February 20th, 1901, a document was filed with the Hillsborough County
      Clerk of the Courts mapping Zion Cemetery. The cemetery was founded by
      Black businessman and philanthropist Richard Dolby. The land covers almost
      three acres. Zion is the oldest known African American Cemetery in Tampa,
      Florida. The map indicates the entrance to the cemetery was on Florida
      Avenue.  The cemetery includes 98 marked plats that families could
      purchase to bury their deceased. The map also indicates that there is a
      Potter’s field for people interred at the site that were unknown or
      indigent. Zion Cemetery has been inactive since the early 1920s.
    </Paragraph>
  </Container>
);
