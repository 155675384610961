import state from "../state/state";
import { activateMouseTracker } from "../util/changeMouseTracker";

const setModalIndex = state.getState().setModalIndex;
const setObject = state.getState().setObject;
const setAnimating = state.getState().setAnimating;
const setRadarActive = state.getState().setRadarActive;
const setRenderCallToAction = state.getState().setRenderCallToAction;
const setShowZionIntro = state.getState().setShowZionIntro;
const setHideZionIntro = state.getState().setHideZionIntro;
const setCandleLit = state.getState().setCandleLit;

interface ClickableLocation {
  x: number;
  y: number;
  z: number;
  onClick: () => void;
}

interface StorySection {
  label: string;
  data: ClickableLocation[];
}

const moveToObject = (object: number, modalIndex: number) => {
  setObject(object);
  setAnimating(true);
  setModalIndex(modalIndex);
  setTimeout(() => {
    activateMouseTracker("EXIT");
  }, 2600);
};

export const storyData: StorySection[] = [
  {
    label: "Robles Pond",
    data: [
      {
        x: -3.6,
        y: 0.75,
        z: 1.65,
        onClick: () => {
          setModalIndex(null);
          setShowZionIntro(true);
          setHideZionIntro(false);
        },
      }, // gate
      {
        x: 1.5,
        y: 1.5,
        z: -1,
        onClick: () => {
          moveToObject(4, 0);
        },
      }, // school house
      {
        x: 3,
        y: 1,
        z: 1.6,
        onClick: () => {
          moveToObject(3, 1);
        },
      }, // paper
    ],
  },
  {
    label: "The Ceremony",
    data: [
      {
        x: -0.7,
        y: 0.5,
        z: 1,
        onClick: () => {
          moveToObject(1, 4);
        },
      }, // plate
      {
        x: -1.5,
        y: 0.8,
        z: 0.2,
        onClick: () => {
          moveToObject(2, 5);
        },
      }, // bottle
      {
        x: 1,
        y: 1,
        z: 1,
        onClick: () => {
          moveToObject(0, 3);
        },
      }, // conk shell
    ],
  },
  {
    label: "Segregation",
    data: [
      {
        x: 0,
        y: 0.5,
        z: 0,
        onClick: () => {
          moveToObject(5, 8);
        },
      }, // shovel/ exumed graves
      {
        x: -0.5,
        y: 0.8,
        z: 4,
        onClick: () => {
          moveToObject(6, 7);
        },
      }, // sign/ deed
      {
        x: 2,
        y: 2,
        z: 2,
        onClick: () => {
          moveToObject(7, 9);
        },
      }, // building frame/ Gentrification
    ],
  },
  {
    label: "Present Day",
    data: [
      {
        x: -3.1,
        y: 2.6,
        z: 3,
        onClick: () => {
          moveToObject(8, 10);
        },
      }, // left building
      {
        x: -0.8,
        y: 1.0,
        z: 1,
        onClick: () => {
          setModalIndex(12);
          setRadarActive(true);
          activateMouseTracker("EXIT");
        },
      }, // hula girl/ GPR
      {
        x: 3,
        y: 2.4,
        z: 3,
        onClick: () => {
          moveToObject(9, 11);
        },
      }, // right building
    ],
  },
  {
    label: "Take Action",
    data: [
      {
        x: -0.2,
        y: 1.19,
        z: 4.75,
        onClick: () => {
          setCandleLit(true);
          setObject(10);
          setAnimating(true);
          setTimeout(() => {
            setAnimating(false);
            setObject(null);
            // * can put previous 2 in useTimeout
            setRenderCallToAction(true);
            setModalIndex(0);
            activateMouseTracker("TAKE\nACTION");
          }, 4000);
        },
      },
      {
        x: -100,
        y: 1.5,
        z: 2,
        onClick: () => console.log("hotspot click"),
      },

      { x: -100, y: 1.5, z: 2, onClick: () => console.log("hotspot click") },
    ],
  },
  {
    label: "",
    data: [
      { x: 100, y: 1.5, z: 2, onClick: () => console.log("hotspot click") },
      { x: 100, y: 1.5, z: 2, onClick: () => console.log("hotspot click") },
      { x: -100, y: 1.5, z: 2, onClick: () => console.log("hotspot click") },
    ],
  },
];
