import React, { Suspense } from "react";
import { Canvas } from "@react-three/fiber";
import Church from "./Church";
import PreRenderLogic from "../../../components/preRenderLogic";
import Camera from "../../../components/camera";
import LightAssembly from "../../../components/lights/LightAssembly";
import DevToolsWegGL from "../../../components/devTools/webgl";
import Text from "../../../components/text";
import state from "../../../state/state";
import storyState from "../../../state/arState";
import SwirlingSpirits from "../../../shaders/SwirlingSpirits";
// const LobbyPeople = React.lazy(() => import("./LobbyPeople"));
import LobbyPeople from "./LobbyPeople";
// const Story = React.lazy(() => import("../../../components/story/webgl")); //todo: lazy load after hit the lobby?
import Story from "../../../components/story/webgl";
// import PostProcessing from "../../../components/postProcessing";
import RoomPlanes from "../../../components/roomPlanes";
const Map = React.lazy(() => import("../../../components/map"));
// const Map = React.lazy(() => import("../../../components/map"));
const Timeline = React.lazy(() => import("../../../components/timeline"));

const Scene = () => {
  const Area = state((s) => s.Area);
  const Hotspot = state((s) => s.Hotspot);
  const Chapter = storyState((s) => s.Chapter);

  return (
    <>
      <Canvas camera={{ position: [0, 2.3, 22] }} dpr={[1, 2]}>
        <PreRenderLogic />
        <RoomPlanes />
        <Camera Area={Area} Hotspot={Hotspot} Chapter={Chapter} />
        <LightAssembly />
        <Church />
        <Text Area={Area} Hotspot={Hotspot} />
        <SwirlingSpirits />
        {/* <Suspense fallback={null}> */}
        <LobbyPeople Area={Area} />
        {/* </Suspense> */}
        {/* <Suspense fallback={null}> */}
        <Story Area={Area} Chapter={Chapter} />
        {/* </Suspense> */}
        <Suspense fallback={null}>
          <Map Area={Area} Hotspot={Hotspot} />
        </Suspense>
        <Suspense fallback={null}>
          <Timeline Area={Area} />
        </Suspense>
        {/* <PostProcessing /> */}
        <DevToolsWegGL />
      </Canvas>
    </>
  );
};

export default Scene;
