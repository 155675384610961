import React, { ReactNode } from "react";
import SheetModal from "./SheetModal/SheetModal";
import MusicAndRestart from "./MusicAndRestart/MusicAndRestart";
import CarouselContainer from "./Carousel/CarouselContainer";
import CallToAction from "./CallToAction/CallToAction";
import state from "../../../../state/arState";

interface UIProps {
  ScenePlaced: boolean;
  Chapter: number;
  TextIndex: number;
  children?: ReactNode;
}

const UI: React.FC<UIProps> = ({
  ScenePlaced,
  Chapter,
  TextIndex,
  children,
}) => {
  const setChapter = state((s) => s.setChapter);

  return (
    <>
      <CarouselContainer
        Chapter={Chapter}
        setChapter={setChapter}
        ScenePlaced={ScenePlaced}
      />
      <MusicAndRestart ScenePlaced={ScenePlaced} Chapter={Chapter} />
      <SheetModal
        Chapter={Chapter}
        TextIndex={TextIndex}
        ScenePlaced={ScenePlaced}
      />
      <CallToAction Chapter={Chapter} setChapter={setChapter} />
      {children}
    </>
  );
};

export default UI;
