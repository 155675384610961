import { MapBW, MapRG, MapPresent } from "./sideModalData/map/Map.modal";
import {
  TimelineIntro,
  Timeline1,
  Timeline2,
  Timeline3,
  Timeline4,
  Timeline5,
  Timeline6,
  Timeline7,
  Timeline8,
} from "./sideModalData/timeline/Timeline.modal";
import { About } from "./sideModalData/about/About.modal";
import { CallToActionMobile } from "./sideModalData/callToActionMobile/CallToActionMobile.modal";
import { SchoolHouse, Deed } from "./sideModalData/zionStory/ChapterOne";
import {
  Ceremony,
  ConchShell,
  BrokenPlate,
  Bottle,
} from "./sideModalData/zionStory/ChapterTwo";
import {
  Segregation,
  TheDeed,
  ExhumedGraves,
  Gentrification,
} from "./sideModalData/zionStory/ChapterThree";
import {
  PresentDay,
  PresentDayTwo,
  GPR,
} from "./sideModalData/zionStory/ChapterFour";
import state from "../state/state";
import { deactivateMouseTracker } from "../util/changeMouseTracker";
import { history } from "../state/util/history";

interface ModalData {
  label: string;
  active: boolean;
  data?: Array<{
    content?: any;
    onClose?: () => void;
    delay?: number;
    delayFunc?: () => number;
  }>;
}

const setRadarActive = state.getState().setRadarActive;
const setObject = state.getState().setObject;
const setRenderAbout = state.getState().setRenderAbout;
const setModalIndex = state.getState().setModalIndex;
const transitionAppState = state.getState().transitionAppState;
const setHotspot = state.getState().setHotspot;
const setRenderCallToAction = state.getState().setRenderCallToAction;
const setRenderWhiteOverlay = state.getState().setRenderWhiteOverlay;

const calculateMapDelay = () => {
  if (history.length < 2) return 0;
  if (history[history.length - 2].ModalIndex === 1) return 0;

  return 1000;
};

export const sideModalData: ModalData[] = [
  {
    label: "landing",
    active: false,
  },
  {
    label: "lobby",
    active: false,
  },
  {
    label: "map",
    active: true,
    data: [
      {
        content: MapBW,
        delayFunc: calculateMapDelay,
        onClose: () => {
          setHotspot(0);
          setModalIndex(null);
        },
      },
      {
        content: MapRG,
        onClose: () => {
          setHotspot(0);
          setModalIndex(null);
        },
      },
      {
        content: MapPresent,
        onClose: () => {
          setHotspot(0);
          setModalIndex(null);
        },
      },
    ],
  },
  {
    label: "story",
    active: true,
    data: [
      {
        content: SchoolHouse,
        onClose: () => setObject(null),
      },
      {
        // CH1
        content: Deed, //todo: gate?
        onClose: () => setObject(null),
      },
      {
        // CH2
        content: Ceremony,
        delay: 4500,
      },
      {
        content: ConchShell,
        delay: 2300,
        onClose: () => setObject(null),
      },
      {
        //4
        content: BrokenPlate,
        delay: 2300,
        onClose: () => setObject(null),
      },
      {
        content: Bottle,
        delay: 2300,
        onClose: () => setObject(null),
      },
      {
        // CH3 - 6
        content: Segregation,
        delay: 4500,
      },
      {
        content: TheDeed,
        onClose: () => setObject(null),
      },
      {
        content: ExhumedGraves,
        onClose: () => setObject(null),
      },
      {
        content: Gentrification,
        onClose: () => setObject(null),
      },
      {
        // CH4 - 10
        content: PresentDay,
        onClose: () => setObject(null),
      },
      {
        content: PresentDayTwo,
        onClose: () => setObject(null),
      },
      {
        content: GPR,
        // onOpen: ()=>
        onClose: () => {
          deactivateMouseTracker();
          setRadarActive(false);
        },
      },
    ],
  },
  {
    label: "timeline",
    active: true,
    data: [
      {
        content: TimelineIntro,
        onClose: () => setModalIndex(null),
      },
      {
        content: Timeline1,
        onClose: () => setModalIndex(null),
      },
      {
        content: Timeline2,
        onClose: () => setModalIndex(null),
      },
      {
        content: Timeline3,
        onClose: () => setModalIndex(null),
      },
      {
        content: Timeline4,
        onClose: () => setModalIndex(null),
      },
      {
        content: Timeline5,
        onClose: () => setModalIndex(null),
      },
      {
        content: Timeline6,
        onClose: () => setModalIndex(null),
      },
      {
        content: Timeline7,
        onClose: () => setModalIndex(null),
      },
      {
        content: Timeline8,
        onClose: () => setModalIndex(null),
      },
    ],
  },
  {
    label: "about",
    active: true,
    data: [
      {
        content: About,
        onClose: () => {
          setRenderWhiteOverlay(true);
          setModalIndex(null);
          setTimeout(() => {
            setRenderAbout(false);
          }, 1800);
        },
      },
    ],
  },
  {
    label: "mobileCallToAction",
    active: true,
    data: [
      {
        content: CallToActionMobile,
        onClose: () => {
          setRenderWhiteOverlay(true);
          setModalIndex(null);
          setTimeout(() => {
            // setRenderCallToActionText(false);
            setRenderCallToAction(false);
            transitionAppState(1, 0);
          }, 1800);
        },
      },
    ],
  },
];
