import React, { useState, useEffect, useRef } from "react";
import { useDrag } from "react-use-gesture";
import {
  TimeBarContainer,
  TimeBarBar,
  TimeBarCircle,
  TimeBarTimeInfo,
} from "./styles/audioPlayer.styles";

function formatTime(seconds: number) {
  return [Math.floor(seconds / 60), Math.floor(seconds % 60)]
    .map((x) => x.toString())
    .map((x) => (x.length === 1 ? `0${x}` : x))
    .join(":");
}

const minMax = (min: number, max: number, value: number) => {
  if (value > max) {
    return max;
  }
  if (value < min) {
    return min;
  }
  return value;
};

function getNewTimeProps(barRect: DOMRect, clientX: number, duration: number) {
  const seconds = minMax(
    0,
    duration,
    Math.floor(((clientX - barRect.left) / barRect.width) * duration)
  );

  const progress = (seconds / duration) * 100;

  return { seconds, progress };
}

interface TimeBarProps {
  style?: React.CSSProperties;
  className?: string;
  duration: number;
  progress: number;
  currentTime: number;
  isSeeking: boolean;
  setTime: (time: number) => void;
}

const TimeBar: React.FC<TimeBarProps> = ({
  style,
  className,
  duration,
  progress,
  currentTime,
  isSeeking,
  setTime,
}) => {
  const barRef = useRef<HTMLDivElement | null>(null);

  const [ignoreTimeUpdates, setIgnoreTimeUpdates] = useState(false);
  const [circlePosition, setCirclePosition] = useState("3%");
  const [barBackground, setBarBackground] = useState(
    `linear-gradient(to right, #000  0%, #000  ${0}%, #737373 ${0}%, #737373 100%)`
  );

  function setStyles(progress: number) {
    setCirclePosition(`${progress + 3}%`);

    setBarBackground(
      `linear-gradient(to right, #000 0%, #000 ${progress}%, #737373 ${progress}%, #737373 100%)`
    );
  }

  const bind = useDrag(
    ({ xy, first, last, event }) => {
      event.preventDefault();

      if (first) {
        setIgnoreTimeUpdates(true);
      }
      if (!barRef.current) return;

      const { seconds, progress } = getNewTimeProps(
        barRef.current.getBoundingClientRect(),
        xy[0],
        duration
      );

      if (last) {
        setTime(seconds);
        setIgnoreTimeUpdates(false);
        return;
      }

      setStyles(progress);
    },
    { event: { passive: false, capture: true } }
  );

  useEffect(() => {
    if (ignoreTimeUpdates) {
      return;
    }
    setStyles(progress);
  }, [ignoreTimeUpdates, progress]);

  return (
    <TimeBarContainer>
      <TimeBarBar ref={barRef} barBackground={barBackground} />
      <TimeBarCircle left={circlePosition} {...bind()} />
      <TimeBarTimeInfo>
        <div>{isSeeking ? "buffering..." : formatTime(currentTime)}</div>
        <div>{formatTime(duration)}</div>
      </TimeBarTimeInfo>
    </TimeBarContainer>
  );
};

export default TimeBar;
