import { motion } from "framer-motion";
import styled from "styled-components";

interface SvgLargeProps {
  hovering?: boolean;
  angle?: number;
  rotation?: number;
  transition?: boolean;
}

export const SvgContainer = styled.div<{
  alignLeft: boolean;
  revealed: boolean;
  layerabovemodal?: boolean;
}>`
  position: absolute;
  bottom: 5rem;
  opacity: ${(props) => (props.revealed ? 1 : 0)};
  left: ${(props) => (props.alignLeft ? "34%" : "50%")};
  transform: ${(props) =>
    props.alignLeft ? "translateX(0)" : "translateX(-50%)"};
  display: flex;
  align-items: flex-end;
  z-index: 22;
  pointer-events: none;
  transition: all 1.8s 1.3s ease-in-out;

  @media (max-width: 768px) {
    left: 50%;
    transform: translateX(-50%);
    z-index: ${(props) => (props.layerabovemodal ? 25 : 22)};
  }
`;

export const SvgSmall = styled.div<{ faded: boolean }>`
  width: 5.5rem;
  height: 5.5rem;
  margin-right: 1rem;
  margin-left: 1rem;
  display: flex;
  justify-content: center;
  align-items: bottom;

  pointer-events: ${(props) => (props.faded ? "none" : "auto")};
  cursor: pointer;
  transition: all 0.25s ease-in-out;
  opacity: ${(props) => (props.faded ? 0.35 : 1)};
  @media (hover: hover) {
    &:hover {
      transform: ${(props) => (!props.faded ? "scale(1.1)" : "none")};
    }
  }
  @media (max-width: 768px) {
    width: 5rem;
    height: 5rem;
    padding-right: 0.3rem;
    padding-left: 0.3rem;
    margin-bottom: 0.5rem;
  }
`;

export const transitionClassName = "SvgLarge-transition";
export const transitionDuration = 280;
export const transitionStyle = `
  .${transitionClassName} {
    transition: all ${transitionDuration}ms ease-in-out;
  }
`;

export const SvgLargeNext = styled.div<{
  rendered: boolean;
  disabled: boolean;
}>`
  width: 10rem;
  height: 10rem;
  display: flex;
  justify-content: center;
  opacity: ${({ rendered, disabled }) => (rendered ? (disabled ? 0.5 : 1) : 0)};
  align-items: bottom;
  margin: 0 0rem 2rem 0rem;
  pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  transition: all 0.4s ease-in-out;

  @media (hover: hover) {
    &:hover {
      transform: scale(1.1);
    }
  }
  @media (max-width: 768px) {
    width: 8rem;
    height: 8rem;
  }
`;

export const SvgLarge = styled(motion.div)<SvgLargeProps>`
  width: 10rem;
  height: 10rem;
  display: flex;
  justify-content: center;
  align-items: bottom;
  margin: 0 0rem 2.5rem 0rem;
  pointer-events: auto;
  /* opacity: 0.8; */
  cursor: pointer;

  transition: ${(props) =>
    props.hovering ? "all 0.1s linear;" : "all 0.4s ease-in-out;"};
  transform: ${(props) => {
    const rotation = (() => {
      if (props.hovering) {
        return `rotate(${props.angle}deg)`;
      } else {
        switch (props.rotation) {
          case 1:
            return "rotate(-75deg)";
          case 2:
            return "rotate(75deg)";
          default:
            return "rotate(0deg)";
        }
      }
    })();
    const scale = props.transition ? 1 : 1;
    return `${rotation} scale(${scale})`;
  }};
  @media (max-width: 768px) {
    margin-bottom: 3rem;
    width: 9rem;
    height: 9rem;
  }
`;

export const DiscoverSvg = styled(motion.div)<SvgLargeProps>`
  width: 10rem;
  height: 10rem;
  margin: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin: 0 0rem 2.5rem 0rem;
  pointer-events: auto;
  border: 2px solid white;
  box-sizing: border-box;
  cursor: pointer;

  div {
    width: 9rem;
    height: 9rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.9);
  }

  @media (max-width: 768px) {
    margin-bottom: 3rem;
    width: 9rem;
    height: 9rem;

    div {
      width: 8.2rem;
      height: 8.2rem;
    }
  }
`;

export const DateContainer = styled.div`
  position: relative;
  width: 10rem;
  height: 10rem;
  display: flex;
  justify-content: center;
  align-items: bottom;
  margin: 0 0rem 2.5rem 0rem;

  &::before,
  &::after {
    content: "";
    position: absolute;
    border-radius: 50%;
    /* border-radius: 50%; */
    /* background: rgba(255, 255, 255, 0.5); // semi-transparent white background */
  }

  &::before {
    width: 100%;
    height: 100%;
    border: 3.5px solid rgba(255, 255, 255, 1);
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.15);
  }

  &::after {
    width: calc(100% - 2.5rem); // 1rem space from outer circle
    height: calc(100% - 2.5rem); // 1rem space from outer circle
    top: 1.375rem; // 1rem space from top
    left: 10px; // 1rem space from left
    border: 2px solid rgba(255, 255, 255, 1);
    @media (max-width: 768px) {
      top: 1.25rem;
    }
  }

  @media (max-width: 768px) {
    width: 9rem;
    height: 9rem;
    z-index: 25;
  }
`;

export const Date = styled.div`
  display: flex;
  justify-content: center;
  color: white;
  font-size: 2.5rem;
  margin-top: 3.75rem;
  color: white;
  z-index: 20;

  @media (max-width: 768px) {
    font-size: 2.2rem;
    margin-top: 3.5rem;
    z-index: 25;
  }
`;
