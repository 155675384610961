import React, { useEffect, useRef, useState } from "react";
import { useControls } from "leva";
import ZionCemeteryModel from "../../../models/ZionCemeteryRiggedModel";
import Hotspots from "../../hotspots";
import InitStory from "./InitStory";

type StoryProps = {
  Area: number;
  Chapter: number;
};

const Story: React.FC<StoryProps> = ({ Area, Chapter }) => {
  const previousArea = useRef(1);
  const firstRender = useRef(true);
  const [isVisible, setVisible] = useState(false);
  const { rotation, x, y, z, visible } = useControls("Story", {
    rotation: {
      value: Math.PI / 12,
      min: -Math.PI * 2,
      max: Math.PI * 2,
      step: 0.001,
    },
    x: {
      value: 0,
      min: -20,
      max: 40,
      step: 0.001,
    },
    y: {
      value: -0.01,
      min: -0.5,
      max: 0.5,
      step: 0.001,
    },
    z: {
      value: -51,
      min: -100,
      max: 100,
      step: 0.001,
    },
    visible: true,
  });

  useEffect(() => {
    if (previousArea.current === 3) {
      setTimeout(() => {
        setVisible(false);
      }, 1600);
    } else if (previousArea.current !== 3 && Area === 3) {
      setTimeout(() => {
        setVisible(true);
      }, 1500);
    }
    previousArea.current = Area;
  }, [Area]);

  return (
    <>
      {Area === 3 && <InitStory />}
      <group visible={isVisible && visible} position={[x, y, z]}>
        <group rotation={[0, 0, 0]}>
          {/* @ts-ignore */}
          <ZionCemeteryModel Area={Area} />
        </group>
        <group>{Area === 3 && <Hotspots Chapter={Chapter} />}</group>
      </group>
    </>
  );
};

export default Story;
