import { create } from "zustand";

interface StoreState {
  DevEnvironment: boolean;
  setDevEnvironment: (DevEnvironment: boolean) => void;
  Animating: boolean;
  setAnimating: (Animating: boolean) => void;
  Area: number;
  setArea: (Area: number) => void;
  Hotspot: number;
  setHotspot: (Hotspot: number) => void;
  CenterImage: number;
  setCenterImage: (CenterImage: number) => void;
  Object: number | null;
  setObject: (Object: number | null) => void;
  RadarActive: boolean;
  setRadarActive: (RadarActive: boolean) => void;
  RenderCallToAction: boolean;
  setRenderCallToAction: (RenderCallToAction: boolean) => void;
  RenderCallToActionText: boolean;
  setRenderCallToActionText: (RenderCallToActionText: boolean) => void;
  RenderAbout: boolean;
  setRenderAbout: (RenderAbout: boolean) => void;
  CloseActive: boolean;
  setCloseActive: (CloseActive: boolean) => void;
  CandleLit: boolean;
  setCandleLit: (CandleLit: boolean) => void;
  IntroVideoComplete: boolean;
  setIntroVideoComplete: (IntroVideoComplete: boolean) => void;
  SoundOn: boolean;
  SoundVolume: string;
  lowerSoundVolume: (SoundVolume: string) => void;
  toggleSound: () => void;
  HideZionIntro: boolean;
  setHideZionIntro: (HideZionIntro: boolean) => void;
  ShowZionIntro: boolean;
  setShowZionIntro: (ShowZionIntro: boolean) => void;
  RenderWhiteOverlay: boolean;
  setRenderWhiteOverlay: (RenderWhiteOverlay: boolean) => void;
  AnimatedTextHovered: boolean;
  setAnimatedTextHovered: (AnimatedTextHovered: boolean) => void;
  ActiveDate: number | null;
  setActiveDate: (ActiveDate: number | null) => void;
  ActivePlane: number | null;
  setActivePlane: (ActivePlane: number | null) => void;
  ModalIndex: number | null;
  setModalIndex: (ModalIndex: number | null) => void;
  transitionAppState: (
    area: number | undefined,
    hotspot: number | undefined,
    RenderCallToAction?: boolean,
    RenderAbout?: boolean
  ) => void;
  showSideModal: boolean;
  setShowSideModal: (showSideModal: boolean) => void;
  showIntro: boolean;
  setShowIntro: (showIntro: boolean) => void;
}

const useStore = create<StoreState>((set) => ({
  DevEnvironment: false,
  setDevEnvironment: (DevEnvironment) => {
    set(() => ({ DevEnvironment }));
  },
  Animating: true,
  setAnimating: (Animating) => {
    set(() => ({ Animating }));
  },
  // * Camera Position
  Area: 0,
  setArea: (Area) => {
    set(() => ({ Area }));
  },

  Hotspot: 0,
  setHotspot: (Hotspot) => {
    set(() => ({ Hotspot }));
  },

  CenterImage: 0,
  setCenterImage: (CenterImage) => {
    set(() => ({ CenterImage }));
  },

  Object: null,
  setObject: (Object) => {
    set(() => ({ Object }));
  },

  RadarActive: false,
  setRadarActive: (RadarActive) => {
    set(() => ({ RadarActive }));
  },

  RenderCallToAction: false,
  setRenderCallToAction: (RenderCallToAction) => {
    set(() => ({ RenderCallToAction }));
  },

  RenderCallToActionText: false,
  setRenderCallToActionText: (RenderCallToActionText) => {
    set(() => ({ RenderCallToActionText }));
  },

  RenderAbout: false,
  setRenderAbout: (RenderAbout) => {
    set(() => ({ RenderAbout }));
  },

  HideZionIntro: false,
  setHideZionIntro: (HideZionIntro) => {
    set(() => ({ HideZionIntro }));
  },

  ShowZionIntro: false,
  setShowZionIntro: (ShowZionIntro) => {
    set(() => ({ ShowZionIntro }));
  },

  RenderWhiteOverlay: false,
  setRenderWhiteOverlay: (RenderWhiteOverlay) => {
    set(() => ({ RenderWhiteOverlay }));
  },

  ActivePlane: null,
  setActivePlane: (ActivePlane) => {
    set(() => ({ ActivePlane }));
  },

  ActiveDate: null,
  setActiveDate: (ActiveDate) => {
    set(() => ({ ActiveDate }));
  },

  ModalIndex: null,
  setModalIndex: (ModalIndex) => {
    set(() => ({ ModalIndex }));
  },

  CloseActive: false,
  setCloseActive: (CloseActive) => {
    set(() => ({ CloseActive }));
  },

  SoundOn: true,
  toggleSound: () => set((state) => ({ SoundOn: !state.SoundOn })),
  SoundVolume: "high",
  lowerSoundVolume: (SoundVolume) => {
    set(() => ({ SoundVolume }));
  },

  CandleLit: true,
  setCandleLit: (CandleLit) => {
    set(() => ({ CandleLit }));
  },

  IntroVideoComplete: false,
  setIntroVideoComplete: (IntroVideoComplete) => {
    set(() => ({ IntroVideoComplete }));
  },

  AnimatedTextHovered: false,
  setAnimatedTextHovered: (AnimatedTextHovered) => {
    set(() => ({ AnimatedTextHovered }));
  },

  transitionAppState: (
    area: number | undefined,
    hotspot: number | undefined,
    RenderCallToAction: boolean = false,
    RenderAbout: boolean = false
  ) => {
    set((state) => ({
      RenderCallToAction,
      RenderAbout,
      Area: area !== undefined ? area : state.Area,
      Hotspot: hotspot !== undefined ? hotspot : state.Hotspot,
    }));
  },

  showSideModal: false,
  setShowSideModal: (showSideModal) => {
    set(() => ({ showSideModal }));
  },

  showIntro: false,
  setShowIntro: (showIntro) => {
    set(() => ({ showIntro }));
  },
}));

export default useStore;
