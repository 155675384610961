import React, { useEffect, useRef } from "react";
import state from "../state";
import { history } from "./history";
interface Props {
  Area: number;
  Hotspot: number;
}

const PreviousStateUpdater: React.FC<Props> = ({ Area, Hotspot }) => {
  const ModalIndex = state((s) => s.ModalIndex);
  const index = useRef(0);

  useEffect(() => {
    //todo: this could allow for going back in time.
    // @ts-ignore
    history.push({
      index: index.current,
      Area: Area,
      Hotspot: Hotspot,
      ModalIndex: ModalIndex,
    });
    // console.log(`history`, history);
    // todo: track modalIndex seperately if needed.
    index.current += 1;
  }, [Area, Hotspot, ModalIndex]);

  return null;
};

export default PreviousStateUpdater;
