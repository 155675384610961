import React, { Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import Desktop from "../pages/desktop";
import { FallbackLoader } from "../components/fallbackLoader";
import LandingPageIndex from "../pages/arExperience/landing";
import Story from "../pages/arExperience/story";

const DesktopRouteHandler: React.FC = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <Suspense fallback={<FallbackLoader />}>
            <Desktop />
          </Suspense>
        }
      />
      <Route
        path="/ar"
        element={
          <Suspense fallback={null}>
            <LandingPageIndex />
          </Suspense>
        }
      />
      <Route
        path="/ar/story"
        element={
          <Suspense fallback={null}>
            <Story />
          </Suspense>
        }
      />
    </Routes>
  );
};

export default DesktopRouteHandler;
