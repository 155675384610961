import React, { useRef } from "react";
import * as three from "three";
// import Scene, { Instances } from "./ZionCemetery";
import Scene from "../../../../../models/ZionCemeteryRiggedModel";

interface SceneContainerProps {}

const SceneContainer: React.FC<SceneContainerProps> = () => {
  const allScenes = useRef<three.Group>(null);
  // scale={0.03}
  return (
    <>
      <group ref={allScenes} scale={0.045} position={[0, 0, 0]}>
        {/* <Instances> */}
        <Scene />
        {/* </Instances> */}
        <ambientLight />
      </group>
    </>
  );
};

export default SceneContainer;
