import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import styled, { keyframes, css } from "styled-components";
import state from "../../state/state";
import home from "../../assets/images/home.svg";
import { deactivateMouseTracker } from "../../util/changeMouseTracker";
import ChapterTitles from "../story/ui/ChapterTitles";

const fadeIn = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

const slowFadeIn = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

const Background = styled.div<{
  image: string;
  animation: any;
  zIndex: number;
  pointerEvents: string;
}>`
  position: absolute;
  /* background-color: white; */
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  opacity: 0;
  pointer-events: ${(props) => props.pointerEvents};
  background-image: url(${(props) => props.image});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  animation: ${(props) => props.animation} 2s ease-in-out forwards;
  z-index: ${(props) => props.zIndex};
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 10rem; */
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 25;
  pointer-events: none;
`;

const TextBox = styled.div<{ animation: any }>`
  display: flex;
  pointer-events: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 35rem;
  opacity: 0;
  animation: ${(props) => props.animation} 3s ease-in-out forwards 1s;
`;

const HomeCircle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 6rem;
  height: 6rem;
  z-index: 25;
  background-color: rgba(255, 255, 255, 0.7);
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.1);
  transition: all 0.9s cubic-bezier(0, 1, 0, 1);
  cursor: pointer;
  position: absolute;
  bottom: 5rem;
  right: 5rem;
  filter: invert(1);
  @media (hover: hover) {
    &:hover {
      transform: scale(2);
    }
  }

  pointer-events: auto;
`;

const HomeIcon = styled.img`
  z-index: 25;
  pointer-events: none;
  margin-left: 2.5px;
  margin-bottom: 3.5px;
  filter: invert(1);
`;

const TextBoxLeft = styled(TextBox)`
  margin-left: 5rem;
`;

const TextBoxRight = styled(TextBox)`
  margin-right: 5rem;

  img {
    margin-top: 1rem;
  }

  a {
    pointer-events: auto;
    cursor: pointer;
  }
`;

const Heading = styled.h1`
  font-size: 2.7rem;
  font-family: "ApothecarySerifSpaced", serif;
  text-align: center;
  margin: 0;
  margin-bottom: 1rem;
`;

const Text = styled.p`
  font-size: 1.9rem;
  font-family: "Iowan Old Style";
  text-align: center;
  margin: 0;
  margin-bottom: 5rem;
`;

const A = styled.a`
  pointer-events: auto;
  cursor: pointer;
`;

const CallToAction: React.FC = () => {
  const [loaded, setLoaded] = useState(false);
  const RenderCallToActionText = state((s) => s.RenderCallToActionText);
  const setRenderCallToActionText = state((s) => s.setRenderCallToActionText);
  const setRenderCallToAction = state((s) => s.setRenderCallToAction);
  const RenderCallToAction = state((s) => s.RenderCallToAction);
  const transitionAppState = state((s) => s.transitionAppState);
  const setModalIndex = state((s) => s.setModalIndex);
  const setRenderWhiteOverlay = state((s) => s.setRenderWhiteOverlay);

  useEffect(() => {
    setLoaded(true);
  }, []);

  const handleHomeClick = () => {
    setRenderWhiteOverlay(true);
    deactivateMouseTracker();
    setModalIndex(null);
    setTimeout(() => {
      setRenderCallToActionText(false);
      setRenderCallToAction(false);
      transitionAppState(1, 0);
    }, 1800);
  };

  const handleBackgroundClick = () => {
    setRenderCallToActionText(true);
    deactivateMouseTracker();
  };

  return (
    <>
      <Background
        onClick={handleBackgroundClick}
        image="./images/storyDesktop/callToActionBackground.png"
        animation={
          loaded
            ? css`
                ${fadeIn}
              `
            : ""
        }
        zIndex={23}
        pointerEvents={"auto"}
      />
      {!isMobile && (
        <>
          <Background
            image="./images/storyDesktop/windowframe.png"
            animation={
              RenderCallToActionText
                ? css`
                    ${slowFadeIn}
                  `
                : ""
            }
            pointerEvents={"none"}
            zIndex={24}
          />
          <HomeCircle onClick={handleHomeClick}>
            <HomeIcon src={home} />
          </HomeCircle>
          {/* {RenderCallToAction && !RenderCallToActionText && (
            <ChapterTitles title={"TAKE ACTION"} HideZionIntro />
          )} */}

          <Container>
            <TextBoxLeft
              animation={
                RenderCallToActionText
                  ? css`
                      ${slowFadeIn}
                    `
                  : ""
              }
            >
              <Heading>MAKE CHANGE</Heading>
              <Text>
                It's time to get involved!! Contact your local city council and
                state leadership to ask them to preserve, protect, and include
                Zion Cemetery and local Florida African American history in
                education.
              </Text>
              <Heading>EMAIL CITY COUNCIL</Heading>
              <Text>
                <A
                  href="mailto:tampacitycouncil@tampagov.net"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  tampacitycouncil@tampagov.net
                </A>
              </Text>
              <Heading>EMAIL FLORIDA GOVERNOR</Heading>
              <Text>
                <A
                  href="https://www.flgov.com/email-the-governor/"
                  target="_blank"
                >
                  flgov.com/email-the-governor
                </A>
              </Text>
              <Heading>EMAIL DEPARTMENT OF EDUCATION</Heading>
              <Text>
                <A
                  href="mailto:Commissioner@fldoe.org"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  commissioner@fldoe.org
                </A>
              </Text>
            </TextBoxLeft>

            <TextBoxRight
              animation={
                RenderCallToActionText
                  ? css`
                      ${slowFadeIn}
                    `
                  : ""
              }
            >
              <Heading>CALL REP</Heading>
              <Text>
                <A href="tel:18004250900">1.800.425.0900</A>
              </Text>
              <Heading>CREDITS</Heading>
              <Text>
                Dr. Queen Chiku Ngozi, Paul Guzzo Tampa Bay Times, Dr. Shannon
                Peck-Bartle, Dr. Lisa Armstrong.
              </Text>
              <div style={{ height: "10rem" }} />
              <Heading>DEVELOPED BY</Heading>
              <a href="https://groovejones.com" target="_blank">
                <img
                  src="/images/storyDesktop/logo.png"
                  alt="Groove Jones"
                  width="200"
                />
                <Text>groovejones.com</Text>
              </a>
            </TextBoxRight>
          </Container>
        </>
      )}
    </>
  );
};

export default CallToAction;
