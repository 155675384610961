import styled from "styled-components";

export const AudioPlayer = styled.div`
  margin: 2rem 0 0 0;
`;

export const Controls = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const TimeBarContainer = styled.div`
  position: relative;
`;

export const TimeBarBar = styled.div<{ barBackground: string }>`
  height: 0.4rem;
  background: ${(props) => props.barBackground};
  width: 24rem;
  margin-top: 1.55rem;
  border-radius: 0 0.2rem 0.2rem 0;
`;

export const TimeBarCircle = styled.div<{ left: string }>`
  pointer-events: auto;
  background-color: #fff;
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 50%;
  position: absolute;
  top: 0px;
  margin-top: 1.55rem;
  left: ${(props) => props.left};
  transform: translate(-50%, calc(-50% + 2px));
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
  cursor: pointer;
`;

export const TimeBarTimeInfo = styled.div`
  margin-top: 0.6rem;
  font-size: 0.8rem;
  color: #222;
  display: flex;
  justify-content: space-between;
`;

export const PlaybackButton = styled.button<{ isHovered: boolean }>`
  transition: 0.2s;
  width: 3rem;
  height: 3rem;
  display: flex;
  background-color: black;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 0rem;
  cursor: pointer;

  svg {
    transition: 0.25s;
    margin-left: 0.2rem;
    width: 0.75rem;
    height: 0.75rem;
    color: ${(props) => (props.isHovered ? "#fff" : "#fff")};
    transform: ${(props) => (props.isHovered ? "scale(1.5)" : "none")};
  }
`;
