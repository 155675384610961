import { MouseTrackerState } from "../components/mouseTracker/data/mouseTracker.data";

export const activateMouseTracker = (name: string) => {
  document.body.style.cursor = "pointer";
  MouseTrackerState.hoveredFigure = true;
  MouseTrackerState.name = name;
};

export const deactivateMouseTracker = () => {
  document.body.style.cursor = "default";
  MouseTrackerState.hoveredFigure = false;
  MouseTrackerState.name = "";
};
