/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import { useEffect, useRef } from "react";
import { useThree, useFrame } from "@react-three/fiber";
import { MathUtils } from "three";
import { DeviceOrientationControls } from "three-stdlib";
//* Hooks
import { useDeviceGyro } from "../../../../hooks/useDeviceGyro";
//* Stores
import state from "../../../../state/arState";

const CameraRotation = (): null => {
  const UserEventRecorded = state((s) => s.UserEventRecorded);

  const { orientation, requestAccess, revokeAccess } = useDeviceGyro();

  const camera = useThree((state) => state.camera);

  const controlsRef = useRef<DeviceOrientationControls>();

  useEffect(() => {
    if (UserEventRecorded) requestAccess();
    else revokeAccess();
    if (controlsRef.current) {
      controlsRef.current.dispose();
    }
    controlsRef.current = new DeviceOrientationControls(camera); // todo: cb
    return () => {
      if (controlsRef.current) {
        controlsRef.current.dispose();
      }
    };
  }, [UserEventRecorded, camera, requestAccess, revokeAccess]);

  // useEffect(() => {
  //   const result = UserEventRecorded ? requestAccess() : revokeAccess();
  //   controlsRef.current = new DeviceOrientationControls(camera); // todo: cb
  // }, [UserEventRecorded, camera, requestAccess, revokeAccess]);

  useFrame(() => {
    if (orientation) {
      const controls = controlsRef.current;

      if (controls) {
        const device = controls.deviceOrientation;

        if (device) {
          const alpha = device.alpha
            ? MathUtils.degToRad(device.alpha) + controls.alphaOffset
            : 0; // Z
          // todo: modify to default to 0
          const beta = device.beta
            ? MathUtils.degToRad(device.beta)
            : MathUtils.degToRad(180 - device.beta); // X'

          const gamma = device.gamma ? MathUtils.degToRad(device.gamma) : 0; // Y''

          const orient = controls.screenOrientation
            ? MathUtils.degToRad(controls.screenOrientation)
            : 0; // O

          controls.euler.set(beta, alpha, -gamma, "YXZ"); // 'ZXY' for the device, but 'YXZ' for us
          controls.object.quaternion.setFromEuler(controls.euler); // orient the device
          controls.object.quaternion.multiply(controls.q1); // camera looks out the back of the device, not the top

          controls.object.quaternion.multiply(
            controls.q0.setFromAxisAngle(controls.zee, -orient)
          ); // adjust for screen orientation

          // controls.object.quaternion.invert();

          if (
            8 * (1 - controls.lastQuaternion.dot(controls.object.quaternion)) >
            controls.EPS
          ) {
            controls.lastQuaternion.copy(controls.object.quaternion);
            controls.dispatchEvent({ type: "change" });
          }
        }
      }
    }
  });

  return null;
};

export default CameraRotation;
