export const vertexShader = `
varying vec2 vUv;
uniform vec2 iResolution;
void main() {
    vUv = uv;
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
}
`;

export const fragmentShader = `
varying vec2 vUv;
// uniform vec2 iResolution;
uniform float iTime;
uniform sampler2D grass;

float circle2(vec2 uv, vec2 pos, float radius, float vignette){
    return float(smoothstep(radius*vignette, radius, distance(uv, pos)));
}

float circle3(vec2 uv, vec2 pos, float radius){
    return float(.1-smoothstep(radius, radius+0.01, distance(uv, pos)));
}

void main() {
    vec2 uv = vUv;

    vec2 pos = vec2(.5,1.);
    uv -= pos;
    uv += pos;
    
    
    // float radius = .1;
    float radius3 = mod(iTime * .035,1.) * 4. ; // todo: everytime each reaches .99 /2.2 * 1.8? color would change
    radius3 = mod(radius3,.25) * 9.;
    
    // vec3 greenBlue = vec3(0.27,0.99,1.0);
    vec4 red = vec4(.3,1.,.7,1.);
    vec4 blue = vec4(0.,0.,1.,1.);
    // vec3 green = vec3(0.,1.,0.0);
    
    vec4 color = vec4(0.);
    vec4 grassTexture = texture2D(grass, vUv);
    // color *= .5;
    
    
  
    //* animated circle
    color += vec4(circle3(uv, pos, radius3 + .05)); 
    color += vec4(circle2(uv, pos, radius3, 0.5)) ;
    // + vec3(circle3(uv,pos,radius *100.5)) ;//.05
    
    
    // color *= greenBlue;
    
    // color = mix()
    color = mix(grassTexture, red, color);
    // color *= mix(green, red, 100. * mod(pow(uv.x, 2.),.01));

    
    gl_FragColor = vec4(color);
}`;
