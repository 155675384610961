import React from "react";
import {
  LandingPageWrapper,
  Button,
  LandingPage2Wrapper,
} from "../../../landing/styles/landingPage";

interface LandingPageProps {
  backgroundImageUrl: string;
  buttonImageUrl: string;
  setUserEventRecorded: (value: boolean) => void;
  setScenePlaced: (ScenePlaced: boolean) => void;
}

const LandingPage2: React.FC<LandingPageProps> = ({
  backgroundImageUrl,
  buttonImageUrl,
  setUserEventRecorded,
  setScenePlaced,
}) => {
  const handleClick = () => {
    setUserEventRecorded(true);
    setScenePlaced(true);
  };

  return (
    <LandingPage2Wrapper>
      <LandingPageWrapper backgroundImageUrl={backgroundImageUrl}>
        <Button src={buttonImageUrl} alt="Button" onClick={handleClick} />
      </LandingPageWrapper>
    </LandingPage2Wrapper>
  );
};

export default LandingPage2;
