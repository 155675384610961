import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  height: 100%;
  width: 100%;
  position: absolute;
  background: transparent;
  z-index: 11;
  pointer-events: none;
`;
const Circle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 6rem;
  height: 6rem;
  margin: 0;
  padding: 0;
  /* background-color: rgba(255, 255, 255, 0.7); */
  pointer-events: auto;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.1);
  transition: all 0.9s cubic-bezier(0, 1, 0, 1);
  cursor: pointer;
  position: absolute;
  @media (hover: hover) {
    &:hover {
      transform: scale(2);
    }
  }
  @media (max-width: 768px) {
    width: 4.5rem;
    height: 4.5rem;
  }
`;

export const HomeCircle = styled(Circle)`
  bottom: 5rem;
  right: 5rem;
  z-index: 19;
  @media (max-width: 768px) {
    bottom: 2.5rem;
    right: 2.5rem;
  }
`;

export const SoundCircle = styled(Circle)`
  bottom: 5rem;
  left: 5rem;
  z-index: 19;
  @media (max-width: 768px) {
    bottom: 2.5rem;
    left: 2.5rem;
  }
`;

export const Icon = styled.img`
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  pointer-events: none;
  /* @media (max-width: 768px) {
    width: 1.5rem;
    height: 1.5rem;
  } */
`;
export const TextContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 6rem;
  height: 6rem;
  position: absolute;
  top: 5rem;
  left: 5rem;
  @media (max-width: 768px) {
    top: 2.5rem;
    left: 1rem;
  }
`;

export const Text = styled.div`
  font-family: "kinfolk";
  font-size: 5.5rem;
  color: white;
  margin-top: 6rem;
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  text-align: base-line;
  background: transparent;
  text-shadow: 0 3px 10px rgb(0 0 0 / 0.1);
  @media (max-width: 768px) {
    font-size: 3.5rem;
    margin-top: 3rem;
  }
`;

export const MenuContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
`;
