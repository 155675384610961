import React from "react";
import StartARPageApple from "./StartARPageApple";
import state from "../../../../../state/arState";

const LandingPageComponent: React.FunctionComponent = () => {
  const setUserEventRecorded = state((s) => s.setUserEventRecorded);
  const setScenePlaced = state((s) => s.setScenePlaced);

  return (
    <>
      <StartARPageApple
        backgroundImageUrl={"/images/landingPage/Landing2Background.png"}
        buttonImageUrl={"/images/landingPage/landing2Btn.png"}
        setUserEventRecorded={setUserEventRecorded}
        setScenePlaced={setScenePlaced}
      />
    </>
  );
};

export default LandingPageComponent;
