import React from "react";
import nextNavigator from "../../../assets/images/nextNavigator.svg";
import {
  SvgContainer,
  SvgLargeNext,
} from "../../../pages/desktop/ui/styles/navigationStyles";
import state from "../../../state/state";

interface Props {
  nextChapter: () => void;
  HideZionIntro: boolean;
  Chapter: number;
  Animating: boolean;
  RadarActive: boolean;
}

const NavigationUI: React.FC<Props> = ({
  nextChapter,
  HideZionIntro,
  Chapter,
  Animating,
  RadarActive,
}) => {
  return (
    <SvgContainer revealed={true} alignLeft={false}>
      <SvgLargeNext
        rendered={HideZionIntro}
        disabled={Chapter > 3 || Animating || RadarActive}
      >
        <img
          src={nextNavigator}
          alt="Navigation Pointer"
          onClick={() => nextChapter()}
        />
      </SvgLargeNext>
    </SvgContainer>
  );
};

export default NavigationUI;
