import React from "react";
import ReactDOM from "react-dom";
import {
  ModalWrapper,
  ModalOverlay,
  ModalContent,
} from "../../styles/CallToAction";

interface ModalProps {
  onClose: () => void;
  isOpen: boolean;
  children: React.ReactNode;
}

const Modal: React.FC<ModalProps> = ({ onClose, isOpen, children }) => {
  if (!isOpen) {
    return null;
  }

  return ReactDOM.createPortal(
    <ModalWrapper isOpen={isOpen}>
      <ModalOverlay onClick={onClose} />
      <ModalContent isOpen={isOpen}>{children}</ModalContent>
    </ModalWrapper>,
    document.body
  );
};

export default Modal;
