import React from "react";
import Menu from "../../../components/menu";
import sound from "../../../assets/images/sound.png";
import noSound from "../../../assets/images/noSound.png";
import home from "../../../assets/images/home.png";
import state from "../../../state/state";
import {
  Container,
  TextContainer,
  Text,
  Icon,
  MenuContainer,
  HomeCircle,
  SoundCircle,
} from "./styles/constantUI.styles";

interface Props {
  Area: number;
}

const ConstantUI: React.FC<Props> = ({ Area }) => {
  const transitionAppState = state((s) => s.transitionAppState);
  const setModalIndex = state((s) => s.setModalIndex);
  const setRenderWhiteOverlay = state((s) => s.setRenderWhiteOverlay);
  const toggleSound = state((s) => s.toggleSound);
  const SoundOn = state((s) => s.SoundOn);
  const showSideModal = state((s) => s.showSideModal);

  const handleHomeClick = () => {
    if (Area === 3) {
      setRenderWhiteOverlay(true);
      setModalIndex(null);
      setTimeout(() => {
        transitionAppState(1, 0);
      }, 1800);
    } else {
      setModalIndex(null);
      transitionAppState(1, 0);
    }
  };

  return (
    <Container>
      <TextContainer>
        <Text>ZION</Text>
      </TextContainer>
      <MenuContainer>
        <Menu Area={Area} />
      </MenuContainer>
      {!showSideModal && (
        <HomeCircle onClick={handleHomeClick}>
          <Icon src={home} alt="Home" />
        </HomeCircle>
      )}
      <SoundCircle onClick={() => toggleSound()}>
        <Icon src={SoundOn ? sound : noSound} alt="Sound" />
      </SoundCircle>
    </Container>
  );
};

export default ConstantUI;
