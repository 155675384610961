import React, { useCallback } from "react";
import ChapterTitles from "./ChapterTitles";
import StoryNavigation from "../../../components/story/ui/StoryNavigation";
import storyState from "../../../state/arState";
import state from "../../../state/state";
import { storyData } from "../../../data/story.data";
import Intro from "./intro";

import PopupModal from "../../popupModal";

const StoryUI = () => {
  const Chapter = storyState((s) => s.Chapter);
  const incrementChapter = storyState((s) => s.incrementChapter);
  const setAnimating = state((s) => s.setAnimating);
  const setModalIndex = state((s) => s.setModalIndex);
  const RenderCallToActionText = state((s) => s.RenderCallToActionText);
  const setRenderCallToAction = state((s) => s.setRenderCallToAction);
  const transitionAppState = state((s) => s.transitionAppState);
  const HideZionIntro = state((s) => s.HideZionIntro);
  const RadarActive = state((s) => s.RadarActive);
  const setRadarActive = state((s) => s.setRadarActive);
  const Animating = state((s) => s.Animating);
  const Object = state((s) => s.Object);
  const setObject = state((s) => s.setObject);

  const nextChapter = useCallback(() => {
    setAnimating(true);
    if (RadarActive) setRadarActive(false);
    // if (Object !== null) { //todo: make this return camera back to normal if user is zoomed in on object when move chapter
    //   setObject(null);
    //   setToggleAnim(false);
    // }
    if (Chapter === 1) {
      setModalIndex(6);
    } else if (Chapter === 0) {
      setModalIndex(2);
    } else if (Chapter === 5) {
      setModalIndex(0);
      setRenderCallToAction(true);
      setTimeout(() => {
        transitionAppState(1, 0, true, false);
      }, 2000);
    } else setModalIndex(null);
    incrementChapter();
  }, [
    Chapter,
    RadarActive,
    incrementChapter,
    setAnimating,
    setModalIndex,
    setRadarActive,
    setRenderCallToAction,
    transitionAppState,
  ]);

  return (
    <>
      {HideZionIntro && <PopupModal />}
      <Intro />
      {!RenderCallToActionText && (
        <ChapterTitles
          title={storyData[Chapter].label}
          HideZionIntro={HideZionIntro}
        />
      )}
      <StoryNavigation
        Chapter={Chapter}
        nextChapter={nextChapter}
        HideZionIntro={HideZionIntro}
        Animating={Animating}
        RadarActive={RadarActive}
      />
    </>
  );
};

export default StoryUI;
