import React, { useRef, useMemo, useEffect } from "react";
import { useFrame, useThree } from "@react-three/fiber";
import * as THREE from "three";
import { vertexShader, fragmentShader } from "./colorReveal/shader";

interface ShaderProps {
  hovered?: boolean;
  show: boolean;
  revealSpeed?: number;
}

const Shader: React.FC<ShaderProps> = ({
  hovered = false,
  show,
  revealSpeed = 1,
}) => {
  const mat = useRef(null);
  const { clock } = useThree();
  const startHoverTime = useRef(0);
  const startNotHoverTime = useRef(0);
  const startValue = useRef(0);

  useEffect(() => {
    if (!mat.current) return;

    if (hovered) {
      startHoverTime.current = clock.getElapsedTime();
    } else {
      startNotHoverTime.current = clock.getElapsedTime();
    }

    // @ts-ignore
    startValue.current = mat.current.uniforms.time.value;
  }, [hovered, clock]);

  useFrame(({ clock }) => {
    if (mat.current) {
      if (hovered) {
        const elapsedTime = clock.getElapsedTime() - startHoverTime.current;
        // @ts-ignore
        mat.current.uniforms.time.value = THREE.MathUtils.clamp(
          startValue.current + elapsedTime,
          0,
          1.5
        );
      } else if (startNotHoverTime.current !== null) {
        const elapsedTime = clock.getElapsedTime() - startNotHoverTime.current;
        // @ts-ignore
        mat.current.uniforms.time.value = THREE.MathUtils.clamp(
          startValue.current - elapsedTime,
          0,
          1.5
        );
      }
    }
  });

  useFrame((state, delta) => {
    if (mat.current === null) return;
    if (!show) {
      // @ts-ignore
      let opacity = mat.current.uniforms.uOpacity.value - delta * 1.8;
      opacity = THREE.MathUtils.clamp(opacity, 0, 1);
      // @ts-ignore
      mat.current.uniforms.uOpacity.value = opacity;
    } else {
      // @ts-ignore
      let opacity = mat.current.uniforms.uOpacity.value + delta * revealSpeed;
      opacity = THREE.MathUtils.clamp(opacity, 0, 1);
      // @ts-ignore
      mat.current.uniforms.uOpacity.value = opacity;
    }
  });

  const uniforms = useMemo(
    () => ({
      time: {
        value: 0.0,
      },
      uOpacity: {
        value: 0.0,
      },
    }),
    []
  );

  return (
    <shaderMaterial
      transparent
      ref={mat}
      vertexShader={vertexShader}
      fragmentShader={fragmentShader}
      uniforms={uniforms}
    />
  );
};

export default Shader;
