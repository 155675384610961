import React, { FC, useEffect, useRef } from "react";
import * as THREE from "three";
import { css } from "@emotion/css";
import { MouseTrackerState } from "./data/mouseTracker.data";

const styles = {
  container: css`
    position: absolute;
    z-index: 9999999;
    top: var(--y);
    left: var(--x);
    width: 10px;
    height: 10px;
    border: 5px solid #fff;
    border-radius: 50%;
    box-shadow: 0 0 10px #fff, 0 0 20px #fff;
    transform: translate(-50%, -50%);
    user-select: none;
    pointer-events: none;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: width 0.5s, height 0.5s, border 0.5s;

    &::before {
      content: "";
      position: absolute;
      width: 90%; // 90% to the border
      height: 90%;
      border-radius: 50%;
      background: rgba(255, 255, 255, 0.5); // faded inner circle
      z-index: -1;
      transition: width 0.5s, height 0.5s;
    }

    &::after {
      content: "";
      position: absolute;
      width: 0%; // 50% to the border
      height: 0%;

      /* border: 2px solid #ff0000; // thin solid border */
      border-radius: 50%;
      background: #fff; // solid inner circle
      z-index: -1;
      transition: width 0.5s, height 0.5s, border 0.5s;
    }

    &.active {
      width: 200px;
      height: 200px;
      border: 6px solid #fff;

      &::before {
        width: 90%;
        height: 90%;
      }

      &::after {
        width: 50%;
        height: 50%;
        /* border: 2px solid #fff; */
        /* border: 2px solid #ff0000; // thin solid border */
      }
    }
  `,
  text: css`
    color: #000000;
    font-size: 1.7rem;
    opacity: 0;
    transition: opacity 0.5s;

    &.active {
      opacity: 1;
    }
  `,
};

const MouseTracker: FC = () => {
  const ref = useRef<HTMLDivElement>(null);
  const textRef = useRef<HTMLSpanElement>(null);
  const animeID = useRef<number>();
  const firstCall = useRef<boolean>(true);

  useEffect(() => {
    const currentPos = new THREE.Vector2();
    const targetPos = new THREE.Vector2();
    const handleMouseMove = (e: MouseEvent) => {
      targetPos.set(e.clientX, e.clientY);
      if (firstCall.current) {
        firstCall.current = false;
        anime();
      }
    };

    window.addEventListener("mousemove", handleMouseMove);

    const anime = () => {
      if (ref.current !== null) {
        currentPos.lerp(targetPos, 0.1);

        ref.current!.style.setProperty("--x", currentPos.x + "px");
        ref.current!.style.setProperty("--y", currentPos.y - 4 + "px");

        ref.current!.classList.toggle(
          "active",
          MouseTrackerState.hoveredFigure
        );
        textRef.current!.classList.toggle(
          "active",
          MouseTrackerState.hoveredFigure
        );
        textRef.current!.textContent = MouseTrackerState.name;
      }
      animeID.current = requestAnimationFrame(anime);
    };

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      animeID.current && cancelAnimationFrame(animeID.current);
    };
  }, []);

  return (
    <div ref={ref} className={styles.container}>
      <span ref={textRef} className={styles.text} />
    </div>
  );
};

export default React.memo(MouseTracker);
