export const vertexShader = `

    attribute vec3 aVelocity;
    attribute float aRandomness;
    uniform float uSize;
    uniform float uTime;
    uniform float uGravity;



    void main(){

        vec4 modelPosition = modelMatrix * vec4(position, 1.0);

        modelPosition.xy -= 2.5;

        //* anti gravity
        modelPosition.y = (modelPosition.y - ((uTime * .6) * uGravity * aVelocity.y));

        modelPosition.y = mod(modelPosition.y , -5.);

        modelPosition.xy += 2.5;

        // Final
        vec4 viewPosition = viewMatrix * modelPosition;
        vec4 projectedPosition = projectionMatrix * viewPosition;
        gl_Position = projectedPosition;

        // Size
        gl_PointSize = uSize * 10.;
        gl_PointSize *= (1.0 / - viewPosition.z);

        
        // Props
        
    }
`;
