import React from "react";
import { Perf } from "r3f-perf";
import { useControls } from "leva";

const PerformanceMonitor = () => {
  const { Enable } = useControls("PerformanceMonitoring", {
    Enable: { value: false },
  });

  if (!Enable) return null;
  return <Perf position={"top-left"} />;
};

export default PerformanceMonitor;
