import {
  Header,
  Paragraph,
  Container,
  ImageText,
} from "../styles/sideModal.styles";

export const TimelineIntro = (
  <Container>
    <Header>Timeline Intro</Header>
    <Paragraph>
      Many studies on the history of African American communities and life
      include importance of community/family, the Black church, and education as
      forces to resist and overcome the continual social and economic struggles
      perpetuated by slavery and the Jim Crow Era. The Raper Report (1927) is
      documentation of the life and socialization of African Americans in Tampa
      that informs this interpretation. Narratives about African American
      communities often omitted many sacred and intimate details about
      segregated African American cemeteries. Recent studies by anthropologist,
      journalists, communities, and scholars aLempt to capture and preserve
      narratives about the cemeteries and the lives of those laid to rest within
      them. “Separate but Equal Laws” and the “Black Codes” were additional set
      of laws that were passed in Southern States to governed every aspect of
      the African American experience in life and death after the ruling in the
      Plessy v. Ferguson (1896). These laws' impacts became prevalent in Tampa
      and continue to have a residual impact. In the latter part of the 19th
      century in Tampa it was often common and a survival necessity for Black
      and White people to live in or be interred near one another regardless of
      race. The Oak Lawn Cemetery in Downtown Tampa is one example of an early
      racially integrated burial site.
    </Paragraph>
  </Container>
);

export const Timeline1 = (
  <Container>
    <Header>1900-1930s</Header>
    <Paragraph>
      In the early part of the 20th century many African American families
      migrated to Tampa, Florida for better opportunities. Over several decades,
      from the 1900s to the 1930s (according to the U.S. Census), the African
      American population in Tampa grew from 4382 to 21,172. The White
      population was increasing at an even greater rate. As the population grew
      in Tampa so did the emphasis of racial segregation laws. African Americans
      lived in a few designated Black communities in Tampa such as the Scrubs,
      Robles Pond, and College Hill. Many Tampa Bay area African Americans
      socialized and shopped in the Central Avenue Business District. Zion
      cemetery is believed to be the oldest known segregated African American
      cemetery in the City of Tampa with the first known interment recorded in
      1901.
    </Paragraph>
    <Paragraph>
      The Black Codes perpetuated unequal racial segregation that often-enforced
      designated spaces for Black people to live, socialize, study, and be
      buried. There were limited school for Black children to attend. Typically,
      Black segregated schools were in Black neighborhoods. Many African
      Americans who recall integration refer to their communities as spaces of
      refuge. One of the earliest known schools for Tampa’s Black children
      post-Civil War was Robles Pond, known today as Robles Park Village.
      However, there were only a few Black schools in Tampa and some Black
      families traveled great distances for their children’s education. Many of
      these old schools are now demolished and replaced by urban development.
    </Paragraph>
  </Container>
);

export const Timeline2 = (
  <Container>
    <Header>1900-1930s</Header>
    <Paragraph>
      Several Tampa Bay area Black schools have been preserved as museums and
      heritage sites. Pictured below is Union Academy, a Black segregated school
      in Tarpon Springs, Florida. When no school building was available, schools
      were in churches. For example, the Sulphur Springs Negro School, also
      known as Spring Hill Negro School, began in Spring Hill Missionary Baptist
      Church. (Picture segregated Bay Area schools including Robles)
    </Paragraph>
    <ImageText>Robles Pond School (FloridaMemory.com)</ImageText>
  </Container>
);

export const Timeline3 = (
  <Container>
    <Header>1900-1930s</Header>
    <Paragraph>
      Dobyville Elementary in Hyde Park community of Tampa, Florida. Children’s
      coronation at Dobyville Elementary School, 307 South Dakota Avenue: Tampa,
      Fla.
    </Paragraph>
    <ImageText>
      Photo by Tampa-Hillsborough County Public Library System, Digital
      Collections ~ Creator: Burgert Brothers ~ Date: 1963
    </ImageText>
  </Container>
);

export const Timeline4 = (
  <Container>
    <Header>1900-1930s</Header>
    <Paragraph>
      Union Academy First Grade Class in Tarpon Springs, Florida with Teacher
      Alicia Roberts
    </Paragraph>
    <ImageText>
      Gerakios, Naomi and Patricia Landon on behalf of Heritage Village. "Union
      Academy." Clio: Your Guide to History. May 18, 2020. Accessed July 31,
      2023. https://theclio.com/entry/100471
    </ImageText>
  </Container>
);

export const Timeline5 = (
  <Container>
    <Header>1940-1960s</Header>
    <Paragraph>
      From the 1930-1960s there were many changes in Tampa; Tampa Housing
      Authority purchased land that included a portion of Zion Cemetery, new
      housing development, roads, and infrastructures were necessary to
      accommodate the population increase in Tampa, Ybor City was set to become
      the city center pushing Black families out of the Scrubs in to new
      developing segregated communities like Carver City, Lincoln Gardens,
      Thonotosassa, and Belmont Heights. Unfortunately, the Robles Park Village
      development came at the expense of African American community of Robles
      Pond and Zion Cemetery. Development over African American cemeteries was
      common in the Tampa Bay area. Schools, stadiums, businesses, and housing
      sit where African American cemeteries once were. In many cases, those
      interred at the cemetery were never relocated. Interstate expansions
      displaced many African American Communities. Zion Cemetery had been
      inactive for a decade before development planning and the property and
      deeds were transferred from one owner to the next. The cemetery was being
      erased due to development and gentrification. African American cemeteries
      lacked the protections afforded to White cemeteries. By 1960, African
      American residents of Robles Park Village had no knowledge they were atop
      Zion Cemetery. African Americans born after the mid-1920s had no memories
      of procession or burials that took place at Zion. Through the years,
      memories of the cemetery and those interred faded.
    </Paragraph>
    <ImageText>
      Photo Courtesy: Queenchiku Ngozi Art Gallery: Photography by Shaina Murphy
      and acrylic paint by Dr. Queenchiku Ngozi.
    </ImageText>
  </Container>
);

export const Timeline6 = (
  <Container>
    <Header>1970-2000s</Header>
    <Paragraph>
      Brown V. Board of Education (1954) reversed the Plessy v. Ferguson (1896)
      decision that legalized racial segregation in the United States. Still,
      desegregation would prove to be a near twenty-year process for
      Hillsborough County Schools and other institutions. Hillsborough County
      Schools desegregated in 1971. Many African Americans who experienced
      desegregation as children shared fond memories of teachers, parent
      engagement, school spirit, friends, and talents that was a part of their
      experiences in Black segregated schools. However, the inequality between
      White and Black schools was palpable in the Tampa Bay area. With these
      changes many of the original Black segregated schools in Hillsborough
      County and Pinellas County were demolished.
    </Paragraph>
    <ImageText>
      Union Academy in Tarpon Springs, Florida was saved from demolition and is
      restored at the Heritage Village in Largo, Florida. Gerakios, Naomi and
      Patricia Landon on behalf of Heritage Village. "Union Academy." Clio: Your
      Guide to History. May 18, 2020. Accessed July 31, 2023.
      https://theclio.com/entry/100471
    </ImageText>
  </Container>
);

export const Timeline7 = (
  <Container>
    <Header>2000-2020</Header>
    <Paragraph>
      The Black churches in Tampa, like many African American churches from
      slavery through the Civil Rights era, were known for the numerous ways
      they engaged within the community. Church buildings continued to serve as
      school buildings during the week. Black churches often kept important
      records about its members, including death records. Black churches also
      listed deaths in newspaper obituaries. These documents were vital in the
      rediscovery of African American cemeteries in 2019. Zion Cemetery was the
      first of 10 cemeteries rediscovered between 2019 and 2022. It is believed
      that 400 African Americans are interred in Zion Cemetery. Oral histories
      of residents at Robles Park Village highlight the trauma and grief
      experienced during the discovery of the erasure of Zion and their lost
      history.
    </Paragraph>
    <ImageText>
      Image of death certificate for infant twin boy buried at Zion Cemetery,
      Tampa. Image uploaded by Ray Reed.
      https://www.findagrave.com/memorial/199090552/infant-twin_2_of_will-davies#view-photo=198197501
    </ImageText>
  </Container>
);

export const Timeline8 = (
  <Container>
    <Header>2022</Header>
    <Paragraph>
      Since 2022, politicians have passed bills to support research,
      preservation, and the memorialization of African American cemeteries
      including Florida House Bill 4815.
    </Paragraph>
    <ImageText>Photo by Lisa Armstrong</ImageText>
  </Container>
);
