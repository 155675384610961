export const introData = {
  default: {
    paragraph:
      "Documentation on Zion Cemetery is scarce. There are no images of the actual cemetery. AR/VR technology is used to reimagine, memorialize, preserve, and educate others about the cemetery. By blending technology, art, oral histories, and primary sources, we’ve tried to recreate the visual history of Zion Cemetery.",
  },
  ar: {
    title: "Scan QR code to view in AR",
    image: "./images/storyDesktop/qrCode.png",
  },
  vr: {
    title: "Coming Soon",
    link: "https://www.groovejones.com/",
    linkText: "link to oculus store preview",
    image: "./images/storyDesktop/vrHeadset.png",
  },
} as const;
