import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;
export const ContainerCentered = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow-y: scroll;
`;

export const Image = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
  margin-bottom: 2rem;
`;

export const ImageSmall = styled.img`
  width: 50%;
  height: auto;
  object-fit: cover;
  margin-bottom: 1rem;
  margin-top: 1rem;
`;

export const Title = styled.div`
  font-family: "KinfolkReg", serif;
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 6rem;
  letter-spacing: 0.1;
`;

export const Header = styled.div`
  font-family: "ApothecarySerifSpaced", serif;
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-size: 4rem;
  letter-spacing: -7px;
  line-height: 0.8;
  margin-left: -4px;
`;

export const Paragraph = styled.div`
  font-family: "Iowan Old Style";
  font-size: 1.6rem;
  line-height: 1.6;
  margin-bottom: 2rem;
`;

export const ParagraphSmall = styled.div`
  font-family: "Iowan Old Style";
  font-size: 1.2rem;
  line-height: 1.6;
`;

export const ImageText = styled.div`
  font-family: "Iowan Old Style";
  font-size: 1.2rem;
  line-height: 1.6;
  font-style: italic;
`;
