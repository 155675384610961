export const sidebar = {
  open: (height = 1000) => ({
    clipPath: `circle(${height * 2 + 200}px at 27rem 8rem)`,
    transition: {
      type: "spring",
      stiffness: 20,
      restDelta: 0.2,
    },
    opacity: 0.8,
    boxShadow: "0 3px 10px rgb(0 0 0 / 0.1)",
  }),
  closed: {
    clipPath: "circle(30px at 27rem 8rem)",
    transition: {
      delay: 0.5,
      type: "spring",
      stiffness: 400,
      damping: 40,
    },
    opacity: 0.0,
    boxShadow: "0 3px 10px rgb(0 0 0 / 0.1)",
  },
};
