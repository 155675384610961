import { useRef, useState } from "react";
import { useCycle } from "framer-motion";
import { useDimensions } from "./hooks/useDimensions";
import { MenuToggle } from "./MenuToggle";
import { Navigation } from "./Menu.navigation";
import { Nav, Background } from "./styles/menuStyles";
import { sidebar } from "./util/sidebar";
import state from "../../state/state";
import arState from "../../state/arState";
import { isMobile } from "react-device-detect";
import {
  activateMouseTracker,
  deactivateMouseTracker,
} from "../../util/changeMouseTracker";
import Intro from "../story/ui/intro/Intro";
import IntroParent from "../story/ui/intro";
import { useNavigate } from "react-router-dom";

interface Props {
  Area: number;
}

const Menu: React.FC<Props> = ({ Area }) => {
  const transitionAppState = state((s) => s.transitionAppState);
  const setChapter = arState((s) => s.setChapter);
  const setModalIndex = state((s) => s.setModalIndex);
  const setShowZionIntro = state((s) => s.setShowZionIntro);
  const setHideZionIntro = state((s) => s.setHideZionIntro);
  const showSideModal = state((s) => s.showSideModal);
  const setRenderWhiteOverlay = state((s) => s.setRenderWhiteOverlay);
  const showIntro = state((s) => s.showIntro);
  const setShowIntro = state((s) => s.setShowIntro);
  const setObject = state((s) => s.setObject);
  const [isOpen, toggleOpen] = useCycle(false, true);
  const containerRef = useRef(null);
  const { height } = useDimensions(containerRef);
  const navigate = useNavigate();

  const links = [
    () => {
      if (Area === 3) {
        setRenderWhiteOverlay(true);
        setTimeout(() => {
          setModalIndex(null);
          transitionAppState(1, 0);
        }, 1800);
      } else {
        setModalIndex(null);
        transitionAppState(1, 0);
        toggleOpen();
      }

      // console.log("home");
    },
    () => {
      setModalIndex(null);
      if (Area === 3) {
        setRenderWhiteOverlay(true);
        setTimeout(() => {
          transitionAppState(1, 0);
        }, 1600);

        setTimeout(() => {
          toggleOpen();
          setObject(null);
          setChapter(0);
          setShowZionIntro(true);
          setHideZionIntro(false);
          transitionAppState(3, 0);
        }, 1800);
      } else {
        toggleOpen();
        setChapter(0);
        transitionAppState(3, 0);
        setShowZionIntro(false);
        setHideZionIntro(false);
      }
      // console.log("story of zion");
    },
    () => {
      setModalIndex(null);
      toggleOpen();
      transitionAppState(1, 2);
      // console.log("timeline");
    },
    () => {
      setModalIndex(0);
      toggleOpen();
      transitionAppState(1, 1);
      // console.log("map");
    },
    () => {
      setModalIndex(0);
      if (isMobile) toggleOpen();
      transitionAppState(undefined, undefined, true);
      setTimeout(() => {
        activateMouseTracker("TAKE\nACTION");
      }, 1000);
      // console.log("take action");
    },
    () => {
      if (isMobile) {
        window.open("/ar");
      } else {
        setShowIntro(true);
        deactivateMouseTracker();
        transitionAppState(3, 0);
        setChapter(0);
        setShowZionIntro(false);
        setHideZionIntro(false);
      }
    },
    () => {
      toggleOpen();
      // setRenderAbout(true);
      setModalIndex(0);
      transitionAppState(undefined, undefined, false, true);
      // console.log("about");
    },
  ];

  return (
    <>
      <Nav
        initial={false}
        animate={isOpen ? "open" : "closed"}
        custom={height}
        ref={containerRef}
      >
        <Background className="background" variants={sidebar} />
        <Navigation isOpen={isOpen} links={links} />
        {!showSideModal && (
          <MenuToggle isOpen={isOpen} toggle={() => toggleOpen()} />
        )}
      </Nav>
      {showIntro && <IntroParent />}
    </>
  );
};

export default Menu;
