import React from "react";
import { useControls } from "leva";
import { LobbyPeopleModel } from "../../../models/LobbyPeopleModel";
import {
  activateMouseTracker,
  deactivateMouseTracker,
} from "../../../util/changeMouseTracker";
import state from "../../../state/state";
import arState from "../../../state/arState";

type LobbyPeopleProps = {
  Area: number;
};

const LobbyPeople: React.FC<LobbyPeopleProps> = ({ Area }) => {
  const transitionAppState = state((s) => s.transitionAppState);
  const setChapter = arState((s) => s.setChapter);
  const setHideZionIntro = state((s) => s.setHideZionIntro);
  const setShowZionIntro = state((s) => s.setShowZionIntro);

  const { x, y, z, visible } = useControls("LobbyPeople", {
    x: {
      value: 0.18,
      min: -20,
      max: 40,
      step: 0.001,
    },
    y: {
      value: 0,
      min: -10,
      max: 10,
      step: 0.001,
    },
    z: {
      value: 0.17,
      min: -10,
      max: 10,
      step: 0.001,
    },
    visible: true,
  });

  const handleHoverEnter = (e: any) => {
    e.stopPropagation();
    if (Area !== 1) return;
    activateMouseTracker("ENTER");
  };

  const handleHoverExit = (e: any) => {
    e.stopPropagation();
    if (Area !== 1) return;
    deactivateMouseTracker();
  };

  const handleClick = (e: any) => {
    e.stopPropagation();
    if (Area !== 1) return;
    deactivateMouseTracker();
    transitionAppState(3, 0);
    setChapter(0);
    setShowZionIntro(false);
    setHideZionIntro(false);
  };

  return (
    <group
      visible={visible}
      renderOrder={-10}
      position={[x, y, z]}
      scale={0.01}
      onPointerEnter={handleHoverEnter}
      onPointerLeave={handleHoverExit}
      onClick={handleClick}
    >
      <LobbyPeopleModel Area={Area} />
    </group>
  );
};

export default LobbyPeople;
