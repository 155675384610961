import React, { useRef, useEffect } from "react";
import * as three from "three";
import { OrbitControls, PresentationControls } from "@react-three/drei";
import Scene from "../../../../../models/ZionCemeteryRiggedModel";
// import Scene, { Instances } from "./ZionCemetery";

interface SceneContainerProps {}

const SceneContainer: React.FC<SceneContainerProps> = () => {
  const sceneRef = useRef<three.Group>(null);

  useEffect(() => {
    // * Adjust for how gyroscopes default positioning of camera so that scene is in front of user
    // sceneRef.current?.position.set(0, 0, 0);
    // sceneRef.current?.rotation.set(0, Math.PI / 2, 0);
    // sceneRef.current?.translateZ(-2.25);
  }, []);

  return (
    <group ref={sceneRef}>
      <OrbitControls
        makeDefault={false}
        enableDamping
        enableRotate={false}
        enablePan={false}
        enableZoom={true}
      />
      <PresentationControls
        config={{ mass: 2, tension: 500 }}
        polar={[-Math.PI / 12, Math.PI / 4]}
        azimuth={[-Math.PI / 2, Math.PI / 2]}
      >
        <group scale={0.1}>
          {/* <Instances> */}
          <Scene />
          {/* </Instances> */}
          {/* <ambientLight /> */}
        </group>
      </PresentationControls>
    </group>
  );
};

export default SceneContainer;
