import { useRef } from 'react';
import { useFrame } from '@react-three/fiber';

const PrintCameraData = ({ printCamPosition, printTarget }) => {
  const oldCamera = useRef({ x: 0, y: 0, z: 0 });

  useFrame(({ camera, controls }) => {
    if (printCamPosition) {
      const newCamera = {
        x: camera.position.x.toFixed(2),
        y: camera.position.y.toFixed(2),
        z: camera.position.z.toFixed(2),
      };

      if (
        oldCamera.current.x !== newCamera.x ||
        oldCamera.current.y !== newCamera.y ||
        oldCamera.current.z !== newCamera.z
      ) {
        console.log(`camera`, {
          x: camera.position.x.toFixed(2),
          y: camera.position.y.toFixed(2),
          z: camera.position.z.toFixed(2),
        });

        oldCamera.current = newCamera;
      }
    }

    if (controls && printTarget) {
      console.log(`target`, {
        x: controls.target.x,
        y: controls.target.y,
        z: controls.target.z,
      });
    }
  });

  return null;
};

export default PrintCameraData;
