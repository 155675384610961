import { useControls } from "leva";
import { Environment } from "@react-three/drei";

export default function LightAssembly() {
  const {
    dlIntensity,
    ambientIntensity,
    amColor,
    HDROn,
    HdrBG,
    dlColor,
    dlX,
    dlY,
    dlZ,
    // renderPointLight,
    px,
    py,
    pz,
    pIntensity,
  } = useControls("Lights", {
    dlIntensity: { value: 0.5, min: 0, max: 5, step: 0.001 },
    dlColor: "#cbcbcb", //3.74
    dlX: { value: 0, min: -100, max: 100, step: 0.001 }, //-3.74
    dlY: { value: 40, min: -100, max: 100, step: 0.001 }, //40.00
    dlZ: { value: -25, min: -100, max: 100, step: 0.001 }, //-0.02
    ambientIntensity: { value: 0.0, min: 0, max: 3, step: 0.001 }, //.2
    amColor: "#ffffff",
    HDROn: { value: true },
    HdrBG: { value: "only", options: [true, "only", false] },
    // renderPointLight: { value: false },
    px: { value: 0, min: -100, max: 100, step: 0.001 },
    py: { value: 5.67, min: -20, max: 20, step: 0.001 },
    pz: { value: -5, min: -100, max: 100, step: 0.001 },
    pIntensity: { value: 0.3, min: 0, max: 10, step: 0.001 },
  });

  return (
    <>
      <ambientLight color={amColor} intensity={ambientIntensity} />
      <directionalLight
        intensity={dlIntensity}
        position={[dlX, dlY, dlZ]}
        color={dlColor}
      />
      {HDROn && (
        // @ts-ignore
        <Environment files={"/images/islandsun.hdr"} background={HdrBG} />
      )}

      {/* {renderPointLight && (
        <pointLight intensity={pIntensity} position={[px, py, pz]} />
      )} */}
    </>
  );
}
