import styled from "styled-components";
import { motion } from "framer-motion";

export const Nav = styled(motion.nav)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 35rem;
  z-index: 16;
  /* opacity: 0.8; */
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const Background = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 35rem;
  background: rgba(255, 255, 255, 0.9);
  z-index: 10;
  pointer-events: auto;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const StyledButton = styled.button<{ isOpen?: boolean }>`
  outline: none;
  border: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
  position: fixed;
  top: 5rem;
  right: 5rem;
  width: 5.2rem;
  height: 5.2rem;
  padding: 0.5rem 0rem 0 0.2rem;
  border-radius: 50%;
  background: ${({ isOpen }) =>
    isOpen ? "rgba(255, 255, 255, 0.0)" : "rgba(255, 255, 255, 0.8)"};
  z-index: 18;
  pointer-events: auto;
  transition: ${({ isOpen }) =>
    !isOpen
      ? "box-shadow .5s ease-in-out .6s;"
      : "box-shadow 0s ease-in-out 0s;"};

  box-shadow: ${({ isOpen }) =>
    !isOpen ? "0 3px 10px rgb(0 0 0 / 0.1)" : "0 3px 10px rgb(0 0 0 / 0.0)"};

  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.9s cubic-bezier(0, 1, 0, 1);
  // * add Outline that works on all browsers
  &::before {
    content: "";
    position: absolute;
    top: -4px;
    left: -4px;
    right: -4px;
    bottom: -4px;
    border: 2.5px solid #ffffff;
    border-radius: inherit;
    z-index: -1;
  }

  @media (hover: hover) {
    &:hover {
      transform: ${({ isOpen }) => (isOpen ? "scale(1.5)" : "scale(2)")};
    }
  }
  @media (max-width: 768px) {
    width: 3.9rem;
    height: 3.9rem;
    top: 2.5rem;
    right: 2.5rem;

    &::before {
      border: 2px solid #ffffff;
      top: -3px;
      left: -3px;
      right: -3px;
      bottom: -3px;
    }
  }
`;

export const StyledUl = styled(motion.ul)`
  padding: 2.5rem;
  padding-right: 0;
  position: absolute;
  top: 12rem;
  width: 26rem;
  /* width: 100%; */
  z-index: 18;
  pointer-events: none;
  @media (max-width: 768px) {
    width: calc(100% - 6rem);
  }
`;

export const StyledLi = styled(motion.li)<{ isOpen?: boolean }>`
  font-family: "KinfolkReg";
  list-style: none;
  margin-bottom: 2.8rem;
  display: flex;
  justify-content: flex-end;
  pointer-events: ${({ isOpen }) => (!isOpen ? "none" : "auto")};
  align-items: center;
  cursor: ${({ isOpen }) => (!isOpen ? "none" : "pointer")};
  z-index: 20;
  font-size: 2.3rem;
`;
