import React from "react";
import "./App.css";
import ArRouteHandler from "./routes/ArRoutes";
import { isDesktop } from "react-device-detect";
import DesktopRouteHandler from "./routes/DesktopRoutes";

function App(): JSX.Element {
  console.log(`Developed by Kristopher Sorensen`);
  return <DesktopRouteHandler />;
  // if (isDesktop) return <DesktopRouteHandler />;
  // return <ArRouteHandler />;
}

export default App;
