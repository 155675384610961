import React, { useMemo, useRef, useEffect } from "react";
import * as THREE from "three";
import { useFrame } from "@react-three/fiber";
import { vertexShader } from "./hotspot/vertexShader";
import { fragmentShader } from "./hotspot/fragmentShader";
import { Plane } from "@react-three/drei";
import { isMobile } from "react-device-detect";

interface HotspotData {
  position: THREE.Vector3;
  onClick: () => void;
}

interface HotspotProps {
  CandleLit: boolean;
  Chapter: number;
  data: HotspotData;
  Animating: boolean;
  offset: number;
  stager: number;
  transparent: boolean;
}

const Hotspot: React.FC<HotspotProps> = ({
  CandleLit,
  data,
  Chapter,
  Animating,
  offset,
  stager,
  transparent,
}) => {
  const material = useRef<THREE.ShaderMaterial>(null);
  const mesh = useRef<THREE.Mesh>(null);
  const futurePosition = useRef<undefined | THREE.Vector3>(undefined);
  const isHovered = useRef(false);

  useEffect(() => {
    if (!data) return;
    if (mesh.current) {
      if (!futurePosition.current) {
        futurePosition.current = data.position;
        mesh.current.position.set(
          futurePosition.current.x,
          futurePosition.current.y,
          futurePosition.current.z
        );
      } else {
        futurePosition.current = data.position;
      }
    }
  }, [data]);

  // * reveal/disappear animation
  const animationTime = useRef(2);

  useFrame(({ clock }) => {
    if (mesh.current && material.current) {
      // if (Chapter === 0 && Animating) {
      //   return;
      // }
      if (!Animating) {
        // * Reveal Hotspot
        if (!futurePosition.current) return;
        if (material.current.uniforms.time.value >= 1) return;

        const elapsed =
          (clock.getElapsedTime() - offset) / animationTime.current - stager;

        mesh.current.position.set(
          futurePosition.current.x,
          futurePosition.current.y,
          futurePosition.current.z
        ); // todo: only run once per hotspot change

        const modifiedElapsed = elapsed - 1;

        material.current.uniforms.time.value = THREE.MathUtils.clamp(
          modifiedElapsed,
          0,
          1
        );
      } else {
        // * Hide Hotspot
        if (transparent) return;
        if (material.current.uniforms.time.value <= 0) return;

        const elapsed = offset - clock.getElapsedTime() + 1;
        material.current.uniforms.time.value = THREE.MathUtils.clamp(
          elapsed * 1.5,
          0,
          1
        );
      }
    }
  });

  // * Hover Animation scale
  useFrame((state, delta) => {
    if (isMobile) return;

    //todo: move to vertex shader. only update when needed
    if (mesh.current && !Animating) {
      const targetScale = isHovered.current ? 1.4 : 1;
      // Lerp function
      mesh.current.scale.x += (targetScale - mesh.current.scale.x) * 0.05;
      mesh.current.scale.y += (targetScale - mesh.current.scale.y) * 0.05;
      mesh.current.scale.z += (targetScale - mesh.current.scale.z) * 0.05;

      //* inner circle hover animation
      if (!material.current) return;
      if (isHovered.current) {
        material.current.uniforms.uHovered.value = THREE.MathUtils.clamp(
          material.current.uniforms.uHovered.value + delta * 1.5,
          0,
          1
        );
      } else {
        material.current.uniforms.uHovered.value = THREE.MathUtils.clamp(
          material.current.uniforms.uHovered.value - delta * 1.5,
          0,
          1
        );
      }
    }
  });
  // * Hotspots look at Camera
  useFrame(({ camera }) => {
    if (!mesh.current) return;
    mesh.current.lookAt(
      camera.position.x,
      camera.position.y,
      camera.position.z
    );
  });

  const uniforms = useMemo(
    () => ({
      time: { value: 0 },
      uHovered: { value: 0 },
    }),
    []
  );

  const handleClick = () => {
    // * prevents clicking on hotspot when hotspot is invisible
    if (Animating) return;
    data.onClick();
  };

  if (CandleLit && Chapter === 4) return null;

  return (
    <mesh
      onClick={handleClick}
      onPointerOver={() => {
        document.body.style.cursor = "pointer";
        isHovered.current = true;
      }}
      onPointerOut={() => {
        document.body.style.cursor = "default";
        isHovered.current = false;
      }}
      ref={mesh}
      scale={isMobile ? 2 : 1}
    >
      <Plane args={[0.35, 0.35]}>
        <shaderMaterial
          side={THREE.DoubleSide}
          transparent
          ref={material}
          fragmentShader={fragmentShader}
          vertexShader={vertexShader}
          uniforms={uniforms}
        />
      </Plane>
    </mesh>
  );
};

export default Hotspot;
