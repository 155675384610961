import { create } from "zustand";

interface StoreState {
  Chapter: number;
  setChapter: (Chapter: number) => void;
  incrementChapter: () => void;
  TextIndex: number;
  setTextIndex: (TextIndex: number) => void;
  CameraPermission: boolean;
  setCameraPermission: (CameraPermission: boolean) => void;
  UserEventRecorded: boolean;
  setUserEventRecorded: (UserEventRecorded: boolean) => void;
  ScenePlaced: boolean;
  setScenePlaced: (ScenePlaced: boolean) => void;
  Restart: () => void;
}

const useStore = create<StoreState>((set) => ({
  CameraPermission: false,
  setCameraPermission: (CameraPermission) => {
    set(() => ({ CameraPermission }));
  },

  UserEventRecorded: false,
  setUserEventRecorded: (UserEventRecorded) => {
    set(() => ({ UserEventRecorded }));
  },

  ScenePlaced: false,
  setScenePlaced: (ScenePlaced) => {
    set(() => ({ ScenePlaced }));
  },

  Chapter: 0,
  setChapter: (Chapter) => {
    set(() => ({ Chapter }));
  },

  incrementChapter: () => {
    set((state) => ({
      Chapter: state.Chapter + 1,
    }));
  },

  TextIndex: 2,
  setTextIndex: (TextIndex) => {
    set(() => ({ TextIndex }));
  },

  Restart: () => {
    set(() => ({
      ScenePlaced: false,
      Chapter: 0,
      TextIndex: 0,
    }));
  },
}));

export default useStore;
