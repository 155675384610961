export interface HistoryItem {
  index: number;
  Area: number;
  Hotspot: number;
  ModalIndex: number | null;
}

export const history: HistoryItem[] = [];

export const returnPreviousHistory = () => {
  if (history.length > 1) return history[history.length - 2];
  else return null;
};
