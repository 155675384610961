import React from "react";
import { Btn } from "../../styles/RestartMusic";

interface RestartProps {
  handleRestart: () => void;
  ScenePlaced: boolean;
  Chapter: number;
}

const Restart: React.FC<RestartProps> = ({
  handleRestart,
  ScenePlaced,
  Chapter,
}) => {
  if (!ScenePlaced || Chapter === 4) return null;
  return (
    <Btn
      backgroundImageUrl={"/images/storyPage/restart.png"}
      onClick={handleRestart}
    />
  );
};

export default Restart;
