import React, { Suspense, useRef } from "react";
import * as three from "three";
import { Loader } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import SceneContainer from "./Scene/AppleSceneContainer";
import { CanvasWrapper } from "../styles/CanvasWrapper";
import GyroData from "../ios/GyroData";
import CameraOverlay from "../ios/CameraOverlay";
import RisingSpirits from "../../../../shaders/RisingSpirits";
import LandingPage2 from "../UI/Landing2";

interface Props {
  UserEventRecorded: boolean;
  setScenePlaced: (ScenePlaced: boolean) => void;
  ScenePlaced: boolean;
}

const CanvasContainerApple: React.FC<Props> = ({
  UserEventRecorded,
  setScenePlaced,
  ScenePlaced,
}) => {
  const group = useRef<three.Group>(null);

  return (
    <>
      {!UserEventRecorded && <LandingPage2 />}
      {/* <LandingPage2 /> */}
      <CanvasWrapper>
        <Canvas
          camera={{
            // far: 250, near: 0.5,
            // position: [0, 1.2, 0.01],
            position: [0, 0.35, 0.75],
          }}
          dpr={[1, 2]}
        >
          <group visible={ScenePlaced} ref={group}>
            <SceneContainer />
          </group>
          <GyroData />
          {/* <Suspense fallback={null}>
          </Suspense> */}
          {ScenePlaced && <RisingSpirits />}
        </Canvas>
      </CanvasWrapper>
      <Suspense fallback={null}>
        <CameraOverlay />
      </Suspense>
      <Loader />
    </>
  );
};

export default CanvasContainerApple;
