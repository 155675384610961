import React, { useState, useEffect } from "react";
import { SheetModalDiv, CustomSheet } from "../../styles/SheetModal";
import ModalCarousel from "./ModalCarousel";
import { data } from "../../Util/SceneData";

interface SheetModalProps {
  Chapter: number;
  TextIndex: number;
  ScenePlaced: boolean;
}
//todo: slide count based on data. move to child.

const SheetModal: React.FC<SheetModalProps> = ({
  Chapter,
  TextIndex,
  ScenePlaced,
}) => {
  const [isOpen] = useState<boolean>(true);
  const SLIDE_COUNT = 5; // todo: based on length of text array
  const [slides, setSlides] = useState(Array.from(Array(SLIDE_COUNT).keys()));

  useEffect(() => {
    setSlides(Array.from(Array(data[Chapter].text.length).keys()));
  }, [Chapter]);

  if (!ScenePlaced) return null;

  return (
    <SheetModalDiv>
      <CustomSheet
        backgroundimageurl={"/images/storyPage/FrameHQ.png"}
        isOpen={Chapter > 3 ? false : isOpen}
        onClose={() => console.log("close")}
        detent="content-height"
        // initialSnap={0}
        snapPoints={[450, 180]}
      >
        <CustomSheet.Container>
          {/* <CustomSheet.Header /> */}
          <CustomSheet.Content>
            <div>
              <ModalCarousel
                slides={slides}
                Chapter={Chapter}
                TextIndex={TextIndex}
              />
            </div>
          </CustomSheet.Content>
        </CustomSheet.Container>
        <CustomSheet.Backdrop />
      </CustomSheet>
    </SheetModalDiv>
  );
};

export default SheetModal;
