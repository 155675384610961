import { Text } from "../../../pages/arExperience/story/styles/ModalCarousel";
import {
  Title,
  Header,
  Paragraph,
  ContainerCentered,
} from "../styles/sideModal.styles";

export const CallToActionMobile = (
  <div
    style={{
      marginBottom: "8rem",
      textAlign: "center",
      maxHeight: "100%",
      overflowY: "scroll",
    }}
  >
    <ContainerCentered>
      <Header>MAKE CHANGE</Header>
      <Paragraph>
        It's time to get involved!! Contact your local city council and state
        leadership to ask them to preserve, protect, and include Zion Cemetery
        and local Florida African American history in education.
      </Paragraph>
      <Header>EMAIL CITY COUNCIL</Header>
      <Paragraph>
        <a href="mailto:tampacitycouncil@tampagov.net">
          tampacitycouncil@tampagov.net
        </a>
      </Paragraph>
      <Header>EMAIL FLORIDA GOVERNOR</Header>
      <Paragraph>
        <a href="https://www.flgov.com/email-the-governor" target="_blank">
          flgov.com/email-the-governor
        </a>
      </Paragraph>
      <Header>EMAIL DEPARTMENT OF EDUCATION</Header>
      <Paragraph>
        <a href="mailto:commissioner@fldoe.org">commissioner@fldoe.org</a>
      </Paragraph>
      <Header>CALL REP</Header>
      <Paragraph>
        <a href="tel:+18004250900">1.800.425.0900</a>
      </Paragraph>
      <Header>CREDITS</Header>
      <Paragraph>
        Dr. Queen Chiku Ngozi, Paul Guzzo Tampa Bay Times, Dr. Shannon
        Peck-Bartle, Dr. Lisa Armstrong.
      </Paragraph>
      <Header>Developed by</Header>
      <Paragraph>
        <a href="https://groovejones.com" target="_blank">
          <img
            src="/images/storyDesktop/logo.png"
            alt="Groove Jones"
            width="200"
          />
          groovejones.com
        </a>
      </Paragraph>
    </ContainerCentered>
  </div>
);
