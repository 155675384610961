/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 church.glb -k
*/

import React, { useRef } from "react";
import { useGLTF, useTexture } from "@react-three/drei";
import WaterMaterial from "../shaders/Water";

export default React.memo(function Model() {
  // @ts-ignore
  const { nodes, materials } = useGLTF("./models/church.glb");

  const [
    lightMapFloor,
    lightMapMound,
    lightMapPew,
    lightMapWall,
    // lightPlaneAlpha,
    // lightMapWaterFloor,
  ] = useTexture([
    "./textures/church/floor_LightMap.jpg",
    "./textures/church/mound_LightMap.jpg",
    "./textures/church/pew_LightMap.jpg",
    "./textures/church/wall_LightMap.jpg",
    // "./textures/church/light_plane_alpha.jpg",
    // "./textures/church/waterFloor_LightMap.jpg",
  ]);
  lightMapFloor.flipY = false;
  lightMapMound.flipY = false;
  lightMapPew.flipY = false;
  lightMapWall.flipY = false;
  // lightMapWaterFloor.flipY = false;

  materials.church_floor_Mat.lightMap = lightMapFloor;
  materials.grassField_mat.lightMap = lightMapFloor;
  materials.church_mound_Mat.lightMap = lightMapMound;
  materials.church_pew_Mat.lightMap = lightMapPew;
  materials.church_wall_Mat.lightMap = lightMapWall;
  // materials.church_waterFloor_mat.lightMap = lightMapWaterFloor;

  return (
    <group dispose={null}>
      <mesh
        name="church_floor_main"
        geometry={nodes.church_floor_main.geometry}
        material={materials.church_mound_Mat}
      />
      <mesh
        name="church_mound"
        geometry={nodes.church_mound.geometry}
        material={materials.church_mound_Mat}
      />
      <mesh
        name="church_pew"
        geometry={nodes.church_pew.geometry}
        material={materials.church_pew_Mat}
      />
      <mesh
        name="church_wall"
        geometry={nodes.church_wall.geometry}
        material={materials.church_wall_Mat}
        material-lightMap={lightMapWall}
      />
      <mesh
        renderOrder={-0.1}
        name="church_water"
        geometry={nodes.church_water.geometry}
        // material={materials.church_water_mat}
      >
        <WaterMaterial floor={"./textures/church/waterFloor.jpg"} />
      </mesh>
      <mesh
        name="church_waterFloor"
        geometry={nodes.church_waterFloor.geometry}
        material={materials.church_waterFloor_mat}
      />
      <mesh
        name="lightPlanes"
        geometry={nodes.lightPlanes.geometry}
        material={materials.lightPlane_mat}
        position={[15.59, 7.666, -8.399]}
        rotation={[-0.023, -0.947, 0.567]}
        scale={[0.01, 0.009, 0.01]}
      />
      <mesh
        name="Meesh019"
        geometry={nodes.Mesh019.geometry}
        material={materials.church_floor_Mat}
      />
      <mesh
        name="Mesh019_1"
        geometry={nodes.Mesh019_1.geometry}
        material={materials.grassField_mat}
      />
    </group>
  );
});

useGLTF.preload("./models/church.glb");
