import styled from "styled-components";

interface CircleProps {
  selected: boolean;
  selectedBackground: string;
  circle: string;
}

export const Circle = styled.div<CircleProps>`
  width: ${(props) => (props.selected ? "35px" : "20px")};
  height: ${(props) => (props.selected ? "35px" : "20px")};
  // border: 3.75px solid #fff;
  border-radius: 50%;
  margin: ${(props) => (props.selected ? "0 8px" : "0 16px")};
  cursor: pointer;
  pointer-events: auto;
  z-index: 10;
  transition: background-image 0.2s ease-in-out;
  // transition: width 0.2s ease-in-out;
  // transition: height 0.2s ease-in-out;
  background-size: cover;
  background-position: center;
  background-image: url(${(props) =>
    props.selected ? props.selectedBackground : props.circle});
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
`;

export const OuterContainer = styled.div`
  position: absolute;
  z-index: 10;
  width: 100%;
`;
