import { useLayoutEffect } from "react";
import { useThree } from "@react-three/fiber";

const CompileShaders = () => {
  const { gl, scene, camera } = useThree();

  useLayoutEffect(() => {
    gl.compile(scene, camera);
  }, [camera, gl, scene]);

  return null;
};

export default CompileShaders;
