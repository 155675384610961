import React from "react";
import { Leva } from "leva";

export interface GuiTypes {
  DevEnvironment: boolean;
}

const Gui: React.FC<GuiTypes> = ({ DevEnvironment }) => {
  if (!DevEnvironment) return <Leva hidden />;

  return <Leva hidden />;
};

export default React.memo(Gui);
