import * as THREE from "three";

export const cameraObjectData = [
  {
    label: "conkShell",
    position: new THREE.Vector3(0, 1.66, -49),
    target: new THREE.Vector3(1.09, 0.66, -51.36),
  },
  {
    label: "plate",
    position: new THREE.Vector3(0, 1.66, -49),
    target: new THREE.Vector3(-0.8, 0.19, -49.94),
  },
  {
    label: "bottle",
    position: new THREE.Vector3(-1, 1.66, -48.8),
    target: new THREE.Vector3(-1.0, 0.66, -51.36),
  },
  {
    label: "deed",
    position: new THREE.Vector3(1.56, 1.65, -50.32),
    target: new THREE.Vector3(3, 1.4, -50.2),
  },
  {
    label: "schoolHouse",
    position: new THREE.Vector3(0.7, 1.9, -51.6),
    target: new THREE.Vector3(3, 1, -52.7),
  },
  {
    label: "shovel",
    position: new THREE.Vector3(-2.06, 1.3, -48.38),
    target: new THREE.Vector3(3, 1, -52.7),
  },
  {
    label: "sign",
    position: new THREE.Vector3(0, 1.5, -45.38),
    target: new THREE.Vector3(0.7, 1, -47.6),
  },
  {
    label: "tower",
    position: new THREE.Vector3(0, 3, -47.58),
    target: new THREE.Vector3(2, 2, -48.5),
  },
  {
    label: "buildingLeft",
    position: new THREE.Vector3(0, 3, -47.58),
    target: new THREE.Vector3(-2, 2, -48.5),
  },
  {
    label: "buildingRight",
    position: new THREE.Vector3(0, 3, -47.58),
    target: new THREE.Vector3(2, 2, -48.5),
  },
  {
    label: "candle",
    position: new THREE.Vector3(0, 1.19, -44),
    target: new THREE.Vector3(0, 1.19, -48.5),
  },
];
