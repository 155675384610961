import styled, { keyframes } from "styled-components";

export const slideIn = keyframes`
  from {
    transform: translateX(130%);
    opacity: 0;
  }
  to {
    transform: translateX(0%);
    opacity: 1;
  }
`;

export const slideOut = keyframes`
  from {
    transform: translateX(0%);
    opacity: 1;
  }
  to {
    transform: translateX(130%);
    opacity: 0;
  }
`;

export const slideInButton = keyframes`
  from {
    bottom:-10rem
  }
  to {
    bottom:-4.5rem;
  }
`;
export const slideOutButton = keyframes`
  from {
    bottom:-4.5rem;
  }
  to {
    bottom:-10rem
  }
`;
// export const slideInGradient = keyframes`
//   from {
//     bottom:-30rem
//   }
//   to {
//     bottom:-9rem;
//   }
// `;
// export const slideOutGradient = keyframes`
//   from {
//     bottom:-9rem;
//   }
//   to {
//     bottom:-30rem
//   }
// `;

export const ModalWrapper = styled.div<{ toggleAnim: boolean; area?: number }>`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 30rem;
  background-color: rgba(255, 255, 255, 0.7);
  overflow-y: visible;
  z-index: 24;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
  animation: ${({ toggleAnim }) => (toggleAnim ? slideIn : slideOut)} 0.75s
    ease-out;
  padding: 7rem;
  @media (max-width: 768px) {
    /* width: auto; */
    max-height: calc(100vh - 8rem);
    padding: ${({ area }) =>
      area === 2
        ? "4rem 4rem 12rem 4rem;"
        : area === 4
        ? "4rem 4rem 18rem 4rem;"
        : "4rem 4rem 8rem 4rem;"};
    overflow-y: hidden;
    pointer-events: auto;
  }
`;

export const Paragraph = styled.p`
  /* overflow: scroll; */
  font-size: 1.2rem;
  line-height: 1.8;
`;

export const CloseButton = styled.button`
  position: absolute;
  left: -2.5rem;
  top: 7rem;
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  background-color: black;
  border: 2px solid white;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 25;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.1);
  opacity: 0.8;
  pointer-events: auto;
  transition: all 0.3s ease-in;

  @media (hover: hover) {
    &:hover {
      scale: 1.1;
    }
  }
`;

export const CloseButtonMobileWrapper = styled.div<{ toggleAnim: boolean }>`
  z-index: 40;
  position: fixed;
  bottom: -30rem;
  left: -50%;
  width: 200vw;
  height: 24rem;
  pointer-events: none;
  background: radial-gradient(
    rgba(255, 246, 234, 1) 15%,
    rgba(255, 246, 234, 0.9) 25%,
    rgba(255, 246, 234, 0) 50%
  );
`;
/* animation: ${({ toggleAnim }) =>
    toggleAnim ? slideInGradient : slideOutGradient}
    0.5s forwards; */

export const CloseButtonMobile = styled(CloseButton)<{ toggleAnim: boolean }>`
  animation: ${({ toggleAnim }) =>
      toggleAnim ? slideInButton : slideOutButton}
    0.5s forwards;

  @media (max-width: 768px) {
    position: fixed;
    border-radius: 50% 50% 0 0;
    opacity: 0.8;
    border: 1.5px solid white;
    top: unset;
    left: 50%;
    bottom: -10rem;
    width: 9rem;
    height: 9rem;
    transform: translate(-50%, 0);
    z-index: 25;
  }
`;

export const Cross = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  position: relative;
  &:before,
  &:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 2px;
    height: 80%;
    background-color: white;
  }
  &:before {
    transform: translate(-50%, -50%) rotate(45deg);
  }
  &:after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
  @media (max-width: 768px) {
    /* margin-left: 1.9rem; */
    scale: 1.5;
    margin-bottom: 4.5rem;
  }
`;

export const VideoCross = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  position: relative;
  &:before,
  &:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 2px;
    height: 80%;
    background-color: white;
  }
  &:before {
    transform: translate(-50%, -50%) rotate(45deg);
  }
  &:after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
`;
