import React, { useEffect, useState } from "react";
import Lottie from "react-lottie";
import { motion } from "framer-motion";
import instructionsLottie from "../../../../assets/lotties/ui_apple_instruc.json";
import styled from "styled-components";

const LottieWrapper = styled(motion.div)`
  position: absolute;
  z-index: 9999999;
  left: 50%;
  top: 80px;
  pointer-events: none;
  transform: translateX(-50%);
`;

interface UIProps {
  ScenePlaced: boolean;
}

const LottiePrompt: React.FC<UIProps> = ({ ScenePlaced }) => {
  const [showLottie, setShowLottie] = useState(true);

  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: instructionsLottie,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    if (!ScenePlaced) {
      setShowLottie(true);
      return;
    }
    setTimeout(() => {
      setShowLottie(false);
    }, 15000);
    return () => setShowLottie(true);
  }, [ScenePlaced]);

  return (
    <>
      {showLottie && (
        <LottieWrapper>
          <motion.div
            key="instructionsDiv"
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
            }}
            exit={{
              opacity: 0,
            }}
            className="instructions"
          >
            <Lottie options={lottieOptions} width={180} height={210} />
          </motion.div>
        </LottieWrapper>
      )}
    </>
  );
};

export default LottiePrompt;
