import React, { useRef } from "react";
import { Sphere } from "@react-three/drei";
import { useControls } from "leva";

const TargetFinder = () => {
  const target = useRef(null);
  const { x, y, z, radius, visible } = useControls("CameraTargetFinder", {
    x: {
      value: 0,
      min: -50,
      max: 50,
      step: 0.01,
    },
    y: {
      value: 2,
      min: -10,
      max: 10,
      step: 0.001,
    },
    z: {
      value: -50,
      min: -100,
      max: 100,
      step: 0.01,
    },
    radius: {
      value: 0.3,
      min: 0.1,
      max: 15,
      step: 0.01,
    },
    visible: {
      value: false,
    },
  });
  const handleClick = () => {
    console.log(`target position`, target.current.position);
  };

  return (
    <mesh
      onClick={handleClick}
      visible={visible}
      ref={target}
      position={[x, y, z]}
    >
      <Sphere args={[radius]}>
        <meshBasicMaterial color={"red"} />
      </Sphere>
    </mesh>
  );
};

export default TargetFinder;
