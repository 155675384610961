import React from "react";
import TargetFinder from "./TargetFinder";
import PerformanceMonitor from "./PerformanceMonitor";
import { useControls } from "leva";
import PrintCameraData from "./PrintCameraData";
import Helpers from "./Helpers";
// import state from "../../../state/state";

const DevToolsWegGL = () => {
  // const DevEnvironment = state((s) => s.DevEnvironment);

  const { enabled, printCamPosition, printTarget, helpersEnabled } =
    useControls("CameraData", {
      enabled: { value: false },
      printCamPosition: { value: false },
      printTarget: { value: false },
      helpersEnabled: { value: false },
    });

  // if (!DevEnvironment) return null;
  return (
    <>
      <TargetFinder />
      <PerformanceMonitor />
      {helpersEnabled && <Helpers />}
      {enabled && (
        <PrintCameraData
          printCamPosition={printCamPosition}
          printTarget={printTarget}
        />
      )}
    </>
  );
};

export default DevToolsWegGL;
