import React, { useRef, useEffect } from "react";
import { useGLTF, useAnimations, Plane } from "@react-three/drei";
import state from "../state/arState";
import desktopState from "../state/state";
import RadarMaterial from "../shaders/Radar";
import { useFrame } from "@react-three/fiber";
import * as THREE from "three";
import { deactivateMouseTracker } from "../util/changeMouseTracker";

export default React.memo(function Model({ Area }) {
  const Chapter = state((s) => s.Chapter);
  const ScenePlaced = state((s) => s.ScenePlaced);
  const Animating = desktopState((s) => s.Animating);
  const setAnimating = desktopState((s) => s.setAnimating);
  const HideZionIntro = desktopState((s) => s.HideZionIntro);
  const RadarActive = desktopState((s) => s.RadarActive);
  const setRadarActive = desktopState((s) => s.setRadarActive);
  const setModalIndex = desktopState((s) => s.setModalIndex);
  const setObject = desktopState((s) => s.setObject);
  const Object = desktopState((s) => s.Object);
  const CandleLit = desktopState((s) => s.CandleLit);
  const setCandleLit = desktopState((s) => s.setCandleLit);
  const lowerSoundVolume = desktopState((s) => s.lowerSoundVolume);
  const RenderCallToAction = desktopState((s) => s.RenderCallToAction);

  const group = useRef();
  const paused = useRef(false);
  const reversed = useRef(false);
  const chapter = useRef(0);
  const initialIntro = useRef(true);
  const flame = useRef(null);
  const glow = useRef(null);
  const candleLightGroup = useRef(null);

  const { nodes, materials, animations } = useGLTF(
    "/models/zionCemetery-rigged.glb"
  );

  const { actions, mixer } = useAnimations(animations, group);
  //Frames 2109=>2106

  useEffect(() => {
    if (!ScenePlaced) {
      chapter.current = 0;
    } else {
      actions.Bake_animation.play();
    }
  }, [ScenePlaced, actions.Bake_animation]);

  const resetLoop = (time) => mixer.setTime(time);

  const animatingToFalse = () => {
    if (Animating === true && Object === null) {
      // console.log(`animating to false`);
      setAnimating(false);
    }
  };
  // * Re-displaying Intro won't cause animation to restart
  useEffect(() => {
    if (HideZionIntro && initialIntro.current) {
      initialIntro.current = false;
    }
  }, [HideZionIntro]);

  useEffect(() => {
    if (Chapter === 3) {
      glow.current.material.opacity = 0;
      flame.current.material.opacity = 0;
      flame.current.scale.set(0, 0, 0);
      setCandleLit(false);
    }
  }, [Chapter, setCandleLit]);

  useFrame((state, delta) => {
    if (CandleLit && Chapter >= 4 && RenderCallToAction === false) {
      const flameSize = THREE.MathUtils.clamp(
        flame.current.scale.x + delta * 2,
        0,
        1
      );
      const opacityGlow = THREE.MathUtils.clamp(
        glow.current.material.opacity + delta,
        0,
        1
      );
      const opacityFlame = THREE.MathUtils.clamp(
        flame.current.material.opacity + delta * 0.3,
        0,
        1
      );

      glow.current.material.opacity = opacityGlow;
      flame.current.material.opacity = opacityFlame;
      flame.current.scale.set(flameSize, flameSize, flameSize);
    }
  });

  // const { resetPoint, stopPoint } = useControls("Animation Timing", { // *for getting start, stop, and loop times correct
  //   resetPoint: {
  //     value: 22.9,
  //     min: 10,
  //     max: 27,
  //     step: 0.0001,
  //   },
  //   stopPoint: {
  //     value: 24.01,
  //     min: 16,
  //     max: 28,
  //     step: 0.0001,
  //   },
  // });

  useFrame(() => {
    if (Area !== 3) return;
    if (paused.current) return;
    if (initialIntro.current) actions.Bake_animation.time = 0; // * delay reveal until intro is clicked.
    let currentTime = actions.Bake_animation.time;
    // ? trans mean ? time mean in relation to seconds ? can it just play until end of loop and reset at start of loop when switch modes
    if (!reversed.current) {
      if (Chapter === 0) {
        if (currentTime > 1)
          // * Zion
          animatingToFalse();
        if (currentTime < 2.85) return;
        else {
          resetLoop(2.5);
        } // * fixes delay into next section
      } else if (Chapter === 1) {
        // * Rem
        if (currentTime < 2.59)
          resetLoop(2.59); // * fixes delay into next section
        else if (currentTime < 6.37) return;
        else {
          resetLoop(6.15);
          animatingToFalse();
        } // 1.6
      } else if (Chapter === 2) {
        //* Jim Crow
        if (currentTime < 10.2) return;
        else {
          resetLoop(10);
          animatingToFalse();
        }
      } else if (Chapter === 3) {
        //* Present Day
        if (currentTime > 14.3) {
          animatingToFalse();
        } //13.8
        // if (currentTime < 24.01) return; // 16.52
        if (currentTime < 18) return; // 16.52
        else {
          resetLoop(15.75); //22.9
        }
      } else if (Chapter >= 4 || RadarActive) {
        if (currentTime > 23.9) {
          animatingToFalse();
        } //13.8
        if (RadarActive) {
          if (currentTime > 24.12) resetLoop(23);
        } else if (currentTime > 26.12) resetLoop(25);
      }
    } else {
      console.log("reverse reverse");
    }
  });

  const handleGroupClick = (e) => {
    e.stopPropagation();
    if (Object !== null) {
      setObject(null);
      setModalIndex(null);
      deactivateMouseTracker();
      lowerSoundVolume("high");
    }
    if (RadarActive) {
      setRadarActive(false);
      deactivateMouseTracker();
      setModalIndex(null);
    }
  };

  return (
    <group
      ref={group}
      dispose={null}
      scale={0.42}
      rotation={[0, Math.PI, 0]}
      position={[0, 0, 0]}
      onClick={handleGroupClick}
    >
      <group name="Scene">
        <group name="Armature">
          <group name="Model">
            <mesh
              visible={RadarActive}
              name="ground"
              position={[0, 0.2, 0]}
              rotation={[Math.PI / 2, Math.PI, Math.PI / 2]}
            >
              <Plane args={[20, 20]}>
                <RadarMaterial />
              </Plane>
            </mesh>
            <skinnedMesh // * grass
              visible={!RadarActive}
              name="Cube003_1"
              geometry={nodes.Cube003_1.geometry}
              material={materials["cemetery_mat.331"]}
              skeleton={nodes.Cube003_1.skeleton}
            />

            <group visible={CandleLit} ref={candleLightGroup}>
              <skinnedMesh
                renderOrder={2}
                ref={flame}
                name="Cube003_5"
                geometry={nodes.Cube003_5.geometry}
                material={materials.flame}
                material-transparent={true}
                skeleton={nodes.Cube003_5.skeleton}
              />
              <skinnedMesh
                renderOrder={2}
                ref={glow}
                name="Cube003_9"
                geometry={nodes.Cube003_9.geometry}
                material={materials.glow_candle}
                skeleton={nodes.Cube003_9.skeleton}
              />
            </group>

            <skinnedMesh
              name="Cube003"
              geometry={nodes.Cube003.geometry}
              material={materials.BG}
              skeleton={nodes.Cube003.skeleton}
            />
            <skinnedMesh
              name="Cube003_2"
              geometry={nodes.Cube003_2.geometry}
              material={materials.ppl}
              skeleton={nodes.Cube003_2.skeleton}
            />
            <skinnedMesh
              name="Cube003_3"
              geometry={nodes.Cube003_3.geometry}
              material={materials.jimCrow_mat}
              skeleton={nodes.Cube003_3.skeleton}
            />
            <skinnedMesh
              name="Cube003_4"
              geometry={nodes.Cube003_4.geometry}
              material={materials["remembrance_mat.001"]}
              skeleton={nodes.Cube003_4.skeleton}
            />
            <skinnedMesh
              name="Cube003_6"
              geometry={nodes.Cube003_6.geometry}
              material={materials.cemetery_mat}
              skeleton={nodes.Cube003_6.skeleton}
            />
            <skinnedMesh
              name="Cube003_7"
              geometry={nodes.Cube003_7.geometry}
              material={materials.remembrance_mat}
              skeleton={nodes.Cube003_7.skeleton}
            />
            <skinnedMesh
              name="Cube003_8"
              geometry={nodes.Cube003_8.geometry}
              material={materials.red}
              skeleton={nodes.Cube003_8.skeleton}
            />

            <skinnedMesh
              name="Cube003_10"
              geometry={nodes.Cube003_10.geometry}
              material={materials.People}
              skeleton={nodes.Cube003_10.skeleton}
            />
            <skinnedMesh
              name="Cube003_11"
              geometry={nodes.Cube003_11.geometry}
              material={materials.Girl}
              skeleton={nodes.Cube003_11.skeleton}
            />
            <skinnedMesh
              name="Cube003_12"
              geometry={nodes.Cube003_12.geometry}
              material={materials.Shadow_grass}
              skeleton={nodes.Cube003_12.skeleton}
            />
            <skinnedMesh
              name="Cube003_13"
              geometry={nodes.Cube003_13.geometry}
              material={materials.txt_extra}
              skeleton={nodes.Cube003_13.skeleton}
            />
            <skinnedMesh
              name="Cube003_14"
              geometry={nodes.Cube003_14.geometry}
              material={materials.grass_mat}
              skeleton={nodes.Cube003_14.skeleton}
            />
            <skinnedMesh
              name="Cube003_15"
              geometry={nodes.Cube003_15.geometry}
              material={materials["Material.001"]}
              skeleton={nodes.Cube003_15.skeleton}
            />
          </group>
          <primitive object={nodes.Bone_bg01_2} />
          <primitive object={nodes.Bone_bg01_1} />
          <primitive object={nodes.Master} />
          <primitive object={nodes.bg02_3001} />
          <primitive object={nodes.school_shadow_1001} />
          <primitive object={nodes.Occluder001} />
          <primitive object={nodes.fire_1001} />
          <primitive object={nodes.Candle001} />
          <primitive object={nodes.zionFence001} />
          <primitive object={nodes.apartmentBldg001} />
          <primitive object={nodes.apartmentBldg_4001} />
          <primitive object={nodes.apartmentBldg_5001} />
          <primitive object={nodes.apartmentBldg_6001} />
          <primitive object={nodes.grave_01001} />
          <primitive object={nodes.grave_01_2001} />
          <primitive object={nodes.grave_01_3001} />
          <primitive object={nodes.grave_01_4001} />
          <primitive object={nodes.grave_01_5001} />
          <primitive object={nodes.grave_01_6001} />
          <primitive object={nodes.grave_01_7001} />
          <primitive object={nodes.grave_01_8001} />
          <primitive object={nodes.grave_01_9001} />
          <primitive object={nodes.grave_01_10001} />
          <primitive object={nodes.grave_01_11001} />
          <primitive object={nodes.grave_01_12001} />
          <primitive object={nodes.grave_01_13001} />
          <primitive object={nodes.grave_01_14001} />
          <primitive object={nodes.grave_01_15001} />
          <primitive object={nodes.grave_01_16001} />
          <primitive object={nodes.grave_01_17001} />
          <primitive object={nodes.grave_01_18001} />
          <primitive object={nodes.grave_01_19001} />
          <primitive object={nodes.grave_01_20001} />
          <primitive object={nodes.grave_01_21001} />
          <primitive object={nodes.grave_01_22001} />
          <primitive object={nodes.grave_01_23001} />
          <primitive object={nodes.grave_01_24001} />
          <primitive object={nodes.grave_01_25001} />
          <primitive object={nodes.grave_01_26001} />
          <primitive object={nodes.grave_01_27001} />
          <primitive object={nodes.grave_01_28001} />
          <primitive object={nodes.grave_01_29001} />
          <primitive object={nodes.grave_01_30001} />
          <primitive object={nodes.grave_01_31001} />
          <primitive object={nodes.grave_01_32001} />
          <primitive object={nodes.grave_01_33001} />
          <primitive object={nodes.grave_01_34001} />
          <primitive object={nodes.grave_01_35001} />
          <primitive object={nodes.grave_01_36001} />
          <primitive object={nodes.grave_01_37001} />
          <primitive object={nodes.grave_01_38001} />
          <primitive object={nodes.grave_01_39001} />
          <primitive object={nodes.grave_01_40001} />
          <primitive object={nodes.grave_01_41001} />
          <primitive object={nodes.grave_01_42001} />
          <primitive object={nodes.grave_01_43001} />
          <primitive object={nodes.grave_01_44001} />
          <primitive object={nodes.grave_01_45001} />
          <primitive object={nodes.grave_01_46001} />
          <primitive object={nodes.grave_01_47001} />
          <primitive object={nodes.grave_01_48001} />
          <primitive object={nodes.grave_01_49001} />
          <primitive object={nodes.grave_01_50001} />
          <primitive object={nodes.grave_01_51001} />
          <primitive object={nodes.grave_01_52001} />
          <primitive object={nodes.grave_01_53001} />
          <primitive object={nodes.grave_01_54001} />
          <primitive object={nodes.grave_01_55001} />
          <primitive object={nodes.grave_01_56001} />
          <primitive object={nodes.grave_01_57001} />
          <primitive object={nodes.grave_01_58001} />
          <primitive object={nodes.grave_01_59001} />
          <primitive object={nodes.grave_01_60001} />
          <primitive object={nodes.grave_01_61001} />
          <primitive object={nodes.grave_01_62001} />
          <primitive object={nodes.grave_01_63001} />
          <primitive object={nodes.grave_01_64001} />
          <primitive object={nodes.grave_01_65001} />
          <primitive object={nodes.grave_01_66001} />
          <primitive object={nodes.grave_01_67001} />
          <primitive object={nodes.grave_01_68001} />
          <primitive object={nodes.grave_01_69001} />
          <primitive object={nodes.grave_01_70001} />
          <primitive object={nodes.girl001} />
          <primitive object={nodes.hoop001} />
          <primitive object={nodes.bldgConstruction_1_3001} />
          <primitive object={nodes.bldgConstruction_1_4001} />
          <primitive object={nodes.bldgConstruction_1_5001} />
          <primitive object={nodes.bldgConstruction_1_6001} />
          <primitive object={nodes.bldgConstruction_1_7001} />
          <primitive object={nodes.bldgConstruction_1_8001} />
          <primitive object={nodes.bldgConstruction_1_9001} />
          <primitive object={nodes.bldgConstruction_1_10001} />
          <primitive object={nodes.bldgConstruction_1_11001} />
          <primitive object={nodes.bldgConstruction_1_12001} />
          <primitive object={nodes.bldgConstruction_1_13001} />
          <primitive object={nodes.bldgConstruction_1_14001} />
          <primitive object={nodes.bldgConstruction_1_15001} />
          <primitive object={nodes.bldgConstruction_1_16001} />
          <primitive object={nodes.bldgConstruction_1_2001} />
          <primitive object={nodes.bldgConstruction_1_17001} />
          <primitive object={nodes.shovel001} />
          <primitive object={nodes.nail001} />
          <primitive object={nodes.stake001} />
          <primitive object={nodes.sign_2001} />
          <primitive object={nodes.woodFence_01001} />
          <primitive object={nodes.woodFence_01_2001} />
          <primitive object={nodes.woodFence_01_3001} />
          <primitive object={nodes.woodFence_01_4001} />
          <primitive object={nodes.woodFence_01_5001} />
          <primitive object={nodes.headstone_A_0001} />
          <primitive object={nodes.headstone_C_1001} />
          <primitive object={nodes.headstone_B_2001} />
          <primitive object={nodes.headstone_D_11001} />
          <primitive object={nodes.headstone_B_2_1001} />
          <primitive object={nodes.headstone_A_0_1001} />
          <primitive object={nodes.headstone_C_1_1001} />
          <primitive object={nodes.headstone_D_11_1001} />
          <primitive object={nodes.headstone_B_2_2001} />
          <primitive object={nodes.headstone_C_1_2001} />
          <primitive object={nodes.headstone_D_11_2001} />
          <primitive object={nodes.headstone_C_1_3001} />
          <primitive object={nodes.headstone_A_0_2001} />
          <primitive object={nodes.headstone_D_11_3001} />
          <primitive object={nodes.entrance_fracturepart18001} />
          <primitive object={nodes.entrance_fracturepart43_3001} />
          <primitive object={nodes.entrance_fracturepart8_1001} />
          <primitive object={nodes.entrance_fracturepart12001} />
          <primitive object={nodes.entrance_fracturepart37_4001} />
          <primitive object={nodes.entrance_fracturepart37_3001} />
          <primitive object={nodes.entrance_fracturepart43001} />
          <primitive object={nodes.entrance_fracturepart48_1001} />
          <primitive object={nodes.entrance_fracturepart48001} />
          <primitive object={nodes.entrance_fracturepart37_2001} />
          <primitive object={nodes.entrance_fracturepart4_3001} />
          <primitive object={nodes.entrance_fracturepart37_1001} />
          <primitive object={nodes.entrance_fracturepart37001} />
          <primitive object={nodes.entrance_fracturepart4001} />
          <primitive object={nodes.entrance_fracturepart16_1001} />
          <primitive object={nodes.entrance_fracturepart33001} />
          <primitive object={nodes.entrance_fracturepart49001} />
          <primitive object={nodes.entrance_fracturepart2_2001} />
          <primitive object={nodes.entrance_fracturepart45_3001} />
          <primitive object={nodes.entrance_fracturepart20001} />
          <primitive object={nodes.entrance_fracturepart13001} />
          <primitive object={nodes.entrance_fracturepart24001} />
          <primitive object={nodes.entrance_fracturepart30001} />
          <primitive object={nodes.entrance_fracturepart35_1001} />
          <primitive object={nodes.schoolhouse_fracturepart5001} />
          <primitive object={nodes.schoolhouse_fracturepart16001} />
          <primitive object={nodes.schoolhouse_fracturepart1001} />
          <primitive object={nodes.schoolhouse_fracturepart11001} />
          <primitive object={nodes.schoolhouse_fracturepart5_2001} />
          <primitive object={nodes.schoolhouse_fracturepart16_2001} />
          <primitive object={nodes.schoolhouse_fracturepart1_2001} />
          <primitive object={nodes.schoolhouse_fracturepart10001} />
          <primitive object={nodes.schoolhouse_fracturepart14001} />
          <primitive object={nodes.schoolhouse_fracturepart2001} />
          <primitive object={nodes.schoolhouse_fracturepart17001} />
          <primitive object={nodes.schoolhouse_fracturepart19001} />
          <primitive object={nodes.schoolhouse_fracturepart14_2001} />
          <primitive object={nodes.schoolhouse_fracturepart18001} />
          <primitive object={nodes.schoolhouse_fracturepart2_2001} />
          <primitive object={nodes.schoolhouse_fracturepart2_3001} />
          <primitive object={nodes.schoolhouse_fracturepart8001} />
          <primitive object={nodes.schoolhouse_fracturepart19_2001} />
          <primitive object={nodes.schoolhouse_fracturepart001} />
          <primitive object={nodes.schoolhouse_fracturepart_2001} />
          <primitive object={nodes.schoolhouse_fracturepart_3001} />
          <primitive object={nodes.schoolhouse_fracturepart_4001} />
          <primitive object={nodes.schoolhouse_fracturepart_5001} />
          <primitive object={nodes.schoolhouse_fracturepart_6001} />
          <primitive object={nodes.schoolhouse_fracturepart_7001} />
          <primitive object={nodes.schoolhouse_fracturepart_8001} />
          <primitive object={nodes.schoolhouse_fracturepart_9001} />
          <primitive object={nodes.schoolhouse_fracturepart_10001} />
          <primitive object={nodes.schoolhouse_fracturepart_11001} />
          <primitive object={nodes.schoolhouse_fracturepart_12001} />
          <primitive object={nodes.schoolhouse_fracturepart_13001} />
          <primitive object={nodes.schoolhouse_fracturepart_14001} />
          <primitive object={nodes.schoolhouse_fracturepart3001} />
          <primitive object={nodes.schoolhouse_fracturepart7001} />
          <primitive object={nodes.schoolhouse_fracturepart8_2001} />
          <primitive object={nodes.schoolhouse_fracturepart8_3001} />
          <primitive object={nodes.schoolhouse_fracturepart_15001} />
          <primitive object={nodes.schoolhouse_fracturepart_16001} />
          <primitive object={nodes.schoolhouse_fracturepart_17001} />
          <primitive object={nodes.schoolhouse_fracturepart_18001} />
          <primitive object={nodes.schoolhouse_fracturepart3_2001} />
          <primitive object={nodes.schoolhouse_fracturepart8_4001} />
          <primitive object={nodes.schoolhouse_fracturepart3_3001} />
          <primitive object={nodes.schoolhouse_fracturepart8_5001} />
          <primitive object={nodes.schoolhouse_fracturepart_19001} />
          <primitive object={nodes.schoolhouse_fracturepart_20001} />
          <primitive object={nodes.schoolhouse_fracturepart6001} />
          <primitive object={nodes.schoolhouse_fracturepart7_2001} />
          <primitive object={nodes.schoolhouse_fracturepart6_2001} />
          <primitive object={nodes.schoolhouse_fracturepart18_2001} />
          <primitive object={nodes.schoolhouse_fracturepart_21001} />
          <primitive object={nodes.schoolhouse_fracturepart_22001} />
          <primitive object={nodes.schoolhouse_fracturepart_23001} />
          <primitive object={nodes.schoolhouse_fracturepart_24001} />
          <primitive object={nodes.schoolhouse_fracturepart_25001} />
          <primitive object={nodes.schoolhouse_fracturepart_26001} />
          <primitive object={nodes.schoolhouse_fracturepart_27001} />
          <primitive object={nodes.schoolhouse_fracturepart_28001} />
          <primitive object={nodes.schoolhouse_fracturepart_29001} />
          <primitive object={nodes.schoolhouse_fracturepart_30001} />
          <primitive object={nodes.schoolhouse_fracturepart_31001} />
          <primitive object={nodes.schoolhouse_fracturepart_32001} />
          <primitive object={nodes.schoolhouse_fracturepart_33001} />
          <primitive object={nodes.schoolhouse_fracturepart_34001} />
          <primitive object={nodes.schoolhouse_fracturepart_35001} />
          <primitive object={nodes.schoolhouse_fracturepart_36001} />
          <primitive object={nodes.schoolhouse_fracturepart_37001} />
          <primitive object={nodes.schoolhouse_fracturepart_38001} />
          <primitive object={nodes.schoolhouse_fracturepart_39001} />
          <primitive object={nodes.schoolhouse_fracturepart_40001} />
          <primitive object={nodes.schoolhouse_fracturepart_41001} />
          <primitive object={nodes.schoolhouse_fracturepart_42001} />
          <primitive object={nodes.schoolhouse_fracturepart_43001} />
          <primitive object={nodes.schoolhouse_fracturepart1_3001} />
          <primitive object={nodes.schoolhouse_fracturepart1_4001} />
          <primitive object={nodes.schoolhouse_fracturepart14_3001} />
          <primitive object={nodes.schoolhouse_fracturepart_44001} />
          <primitive object={nodes.schoolhouse_fracturepart_45001} />
          <primitive object={nodes.schoolhouse_fracturepart_46001} />
          <primitive object={nodes.schoolhouse_fracturepart_47001} />
          <primitive object={nodes.schoolhouse_fracturepart_48001} />
          <primitive object={nodes.schoolhouse_fracturepart_49001} />
          <primitive object={nodes.schoolhouse_fracturepart_50001} />
          <primitive object={nodes.schoolhouse_fracturepart_51001} />
          <primitive object={nodes.schoolhouse_fracturepart_52001} />
          <primitive object={nodes.schoolhouse_fracturepart10_2001} />
          <primitive object={nodes.schoolhouse_fracturepart12001} />
          <primitive object={nodes.schoolhouse_fracturepart_53001} />
          <primitive object={nodes.schoolhouse_fracturepart1_5001} />
          <primitive object={nodes.schoolhouse_fracturepart14_4001} />
          <primitive object={nodes.schoolhouse_fracturepart18_3001} />
          <primitive object={nodes.schoolhouse_fracturepart_54001} />
          <primitive object={nodes.schoolhouse_fracturepart17_2001} />
          <primitive object={nodes.schoolhouse_fracturepart19_3001} />
          <primitive object={nodes.schoolhouse_fracturepart20001} />
          <primitive object={nodes.schoolhouse_fracturepart_55001} />
          <primitive object={nodes.schoolhouse_fracturepart2_4001} />
          <primitive object={nodes.schoolhouse_fracturepart2_5001} />
          <primitive object={nodes.schoolhouse_fracturepart8_6001} />
          <primitive object={nodes.schoolhouse_fracturepart19_4001} />
          <primitive object={nodes.schoolhouse_fracturepart_56001} />
          <primitive object={nodes.schoolhouse_fracturepart_57001} />
          <primitive object={nodes.schoolhouse_fracturepart_58001} />
          <primitive object={nodes.schoolhouse_fracturepart_59001} />
          <primitive object={nodes.schoolhouse_fracturepart2_6001} />
          <primitive object={nodes.schoolhouse_fracturepart8_7001} />
          <primitive object={nodes.schoolhouse_fracturepart19_5001} />
          <primitive object={nodes.schoolhouse_fracturepart2_7001} />
          <primitive object={nodes.schoolhouse_fracturepart19_6001} />
          <primitive object={nodes.schoolhouse_fracturepart_60001} />
          <primitive object={nodes.schoolhouse_fracturepart_61001} />
          <primitive object={nodes.schoolhouse_fracturepart_62001} />
          <primitive object={nodes.schoolhouse_fracturepart10_3001} />
          <primitive object={nodes.schoolhouse_fracturepart14_5001} />
          <primitive object={nodes.schoolhouse_fracturepart_63001} />
          <primitive object={nodes.schoolhouse_fracturepart10_4001} />
          <primitive object={nodes.schoolhouse_fracturepart14_6001} />
          <primitive object={nodes.schoolhouse_fracturepart_64001} />
          <primitive object={nodes.schoolhouse_fracturepart10_5001} />
          <primitive object={nodes.schoolhouse_fracturepart14_7001} />
          <primitive object={nodes.schoolhouse_fracturepart10_6001} />
          <primitive object={nodes.schoolhouse_fracturepart14_8001} />
          <primitive object={nodes.schoolhouse_fracturepart10_7001} />
          <primitive object={nodes.schoolhouse_fracturepart14_9001} />
          <primitive object={nodes.schoolhouse_fracturepart_65001} />
          <primitive object={nodes.schoolhouse_fracturepart_66001} />
          <primitive object={nodes.schoolhouse_fracturepart_67001} />
          <primitive object={nodes.schoolhouse_fracturepart_68001} />
          <primitive object={nodes.schoolhouse_fracturepart_69001} />
          <primitive object={nodes.schoolhouse_fracturepart_70001} />
          <primitive object={nodes.schoolhouse_fracturepart_71001} />
          <primitive object={nodes.schoolhouse_fracturepart_72001} />
          <primitive object={nodes.schoolhouse_fracturepart10_8001} />
          <primitive object={nodes.schoolhouse_fracturepart12_2001} />
          <primitive object={nodes.schoolhouse_fracturepart_73001} />
          <primitive object={nodes.schoolhouse_fracturepart_74001} />
          <primitive object={nodes.schoolhouse_fracturepart15001} />
          <primitive object={nodes.schoolhouse_fracturepart19_7001} />
          <primitive object={nodes.schoolhouse_fracturepart_75001} />
          <primitive object={nodes.schoolhouse_fracturepart3_4001} />
          <primitive object={nodes.schoolhouse_fracturepart4001} />
          <primitive object={nodes.schoolhouse_fracturepart_76001} />
          <primitive object={nodes.schoolhouse_fracturepart_77001} />
          <primitive object={nodes.schoolhouse_fracturepart3_5001} />
          <primitive object={nodes.schoolhouse_fracturepart4_2001} />
          <primitive object={nodes.schoolhouse_fracturepart7_3001} />
          <primitive object={nodes.schoolhouse_fracturepart8_8001} />
          <primitive object={nodes.schoolhouse_fracturepart_78001} />
          <primitive object={nodes.schoolhouse_fracturepart_79001} />
          <primitive object={nodes.schoolhouse_fracturepart_80001} />
          <primitive object={nodes.schoolhouse_fracturepart10_9001} />
          <primitive object={nodes.schoolhouse_fracturepart14_10001} />
          <primitive object={nodes.schoolhouse_fracturepart6_3001} />
          <primitive object={nodes.schoolhouse_fracturepart7_4001} />
          <primitive object={nodes.schoolhouse_fracturepart_81001} />
          <primitive object={nodes.schoolhouse_fracturepart_82001} />
          <primitive object={nodes.schoolhouse_fracturepart8_9001} />
          <primitive object={nodes.schoolhouse_fracturepart17_3001} />
          <primitive object={nodes.schoolhouse_fracturepart19_8001} />
          <primitive object={nodes.schoolhouse_fracturepart_83001} />
          <primitive object={nodes.schoolhouse_fracturepart3_6001} />
          <primitive object={nodes.schoolhouse_fracturepart4_3001} />
          <primitive object={nodes.schoolhouse_fracturepart8_10001} />
          <primitive object={nodes.schoolhouse_fracturepart1_6001} />
          <primitive object={nodes.schoolhouse_fracturepart2_8001} />
          <primitive object={nodes.schoolhouse_fracturepart3_7001} />
          <primitive object={nodes.schoolhouse_fracturepart5_3001} />
          <primitive object={nodes.schoolhouse_fracturepart6_4001} />
          <primitive object={nodes.schoolhouse_fracturepart7_5001} />
          <primitive object={nodes.schoolhouse_fracturepart8_11001} />
          <primitive object={nodes.schoolhouse_fracturepart11_2001} />
          <primitive object={nodes.schoolhouse_fracturepart13001} />
          <primitive object={nodes.schoolhouse_fracturepart14_11001} />
          <primitive object={nodes.schoolhouse_fracturepart16_3001} />
          <primitive object={nodes.schoolhouse_fracturepart18_4001} />
          <primitive object={nodes.schoolhouse_fracturepart19_9001} />
          <primitive object={nodes.schoolhouse_fracturepart2_9001} />
          <primitive object={nodes.schoolhouse_fracturepart8_12001} />
          <primitive object={nodes.schoolhouse_fracturepart19_10001} />
          <primitive object={nodes.schoolhouse_fracturepart14_12001} />
          <primitive object={nodes.schoolhouse_fracturepart18_5001} />
          <primitive object={nodes.schoolhouse_fracturepart11_3001} />
          <primitive object={nodes.schoolhouse_fracturepart20_2001} />
          <primitive object={nodes.schoolhouse_fracturepart1_7001} />
          <primitive object={nodes.schoolhouse_fracturepart1_8001} />
          <primitive object={nodes.schoolhouse_fracturepart1_9001} />
          <primitive object={nodes.schoolhouse_fracturepart2_10001} />
          <primitive object={nodes.schoolhouse_fracturepart3_8001} />
          <primitive object={nodes.schoolhouse_fracturepart4_4001} />
          <primitive object={nodes.schoolhouse_fracturepart4_5001} />
          <primitive object={nodes.schoolhouse_fracturepart5_4001} />
          <primitive object={nodes.schoolhouse_fracturepart6_5001} />
          <primitive object={nodes.schoolhouse_fracturepart7_6001} />
          <primitive object={nodes.schoolhouse_fracturepart8_13001} />
          <primitive object={nodes.schoolhouse_fracturepart9001} />
          <primitive object={nodes.schoolhouse_fracturepart10_10001} />
          <primitive object={nodes.schoolhouse_fracturepart11_4001} />
          <primitive object={nodes.schoolhouse_fracturepart12_3001} />
          <primitive object={nodes.schoolhouse_fracturepart12_4001} />
          <primitive object={nodes.schoolhouse_fracturepart14_13001} />
          <primitive object={nodes.schoolhouse_fracturepart15_2001} />
          <primitive object={nodes.schoolhouse_fracturepart16_4001} />
          <primitive object={nodes.schoolhouse_fracturepart17_4001} />
          <primitive object={nodes.schoolhouse_fracturepart17_5001} />
          <primitive object={nodes.schoolhouse_fracturepart18_6001} />
          <primitive object={nodes.schoolhouse_fracturepart19_11001} />
          <primitive object={nodes.schoolhouse_fracturepart20_3001} />
          <primitive object={nodes.headstone_A_0_2_2001} />
          <primitive object={nodes.headstone_C_1_2_2001} />
          <primitive object={nodes.headstone_B_2_2_2001} />
          <primitive object={nodes.headstone_E_3_1001} />
          <primitive object={nodes.headstone_B_5_1001} />
          <primitive object={nodes.headstone_E_6_1001} />
          <primitive object={nodes.headstone_E_3001} />
          <primitive object={nodes.headstone_B_5001} />
          <primitive object={nodes.headstone_E_6001} />
          <primitive object={nodes.headstone_A_8001} />
          <primitive object={nodes.headstone_A_7001} />
          <primitive object={nodes.headstone_A_0_3001} />
          <primitive object={nodes.headstone_B_5_2001} />
          <primitive object={nodes.headstone_E_6_2001} />
          <primitive object={nodes.headstone_A_7_2001} />
          <primitive object={nodes.headstone_A_8_2001} />
          <primitive object={nodes.headstone_D_10001} />
          <primitive object={nodes.headstone_A_2001} />
          <primitive object={nodes.headstone_C_3001} />
          <primitive object={nodes.headstone_C_4001} />
          <primitive object={nodes.headstone_B_5_3001} />
          <primitive object={nodes.headstone_E_9_2001} />
          <primitive object={nodes.headstone_D_10_3001} />
          <primitive object={nodes.headstone_D_11_3_2001} />
          <primitive object={nodes.headstone_C_1_3_2001} />
          <primitive object={nodes.headstone_B_2_3001} />
          <primitive object={nodes.headstone_E_3_2001} />
          <primitive object={nodes.headstone_D_11_2_2001} />
          <primitive object={nodes.headstone_D_6001} />
          <primitive object={nodes.headstone_E_7001} />
          <primitive object={nodes.headstone_A_8_3001} />
          <primitive object={nodes.headstone_B_9001} />
          <primitive object={nodes.headstone_D_10_2001} />
          <primitive object={nodes.headstone_E_11001} />
          <primitive object={nodes.headstone_A_0_5001} />
          <primitive object={nodes.headstone_C_1_5001} />
          <primitive object={nodes.headstone_B_2_4001} />
          <primitive object={nodes.headstone_E_3_3001} />
          <primitive object={nodes.headstone_B_4_2001} />
          <primitive object={nodes.headstone_B_5_4001} />
          <primitive object={nodes.headstone_E_6_3001} />
          <primitive object={nodes.headstone_A_7_3001} />
          <primitive object={nodes.headstone_B_4001} />
          <primitive object={nodes.headstone_A_0_4001} />
          <primitive object={nodes.headstone_A_8_4001} />
          <primitive object={nodes.headstone_C_1_4001} />
          <primitive object={nodes.entrance_fracturepart14_2001} />
          <primitive object={nodes.entrance_fracturepart29001} />
          <primitive object={nodes.entrance_fracturepart14_1001} />
          <primitive object={nodes.entrance_fracturepart18_3001} />
          <primitive object={nodes.entrance_fracturepart18_2001} />
          <primitive object={nodes.entrance_fracturepart40_1001} />
          <primitive object={nodes.entrance_fracturepart46001} />
          <primitive object={nodes.entrance_fracturepart18_1001} />
          <primitive object={nodes.entrance_fracturepart40001} />
          <primitive object={nodes.entrance_fracturepart48_3001} />
          <primitive object={nodes.entrance_fracturepart17001} />
          <primitive object={nodes.entrance_fracturepart43_2001} />
          <primitive object={nodes.entrance_fracturepart48_2001} />
          <primitive object={nodes.entrance_fracturepart8001} />
          <primitive object={nodes.entrance_fracturepart9_2001} />
          <primitive object={nodes.entrance_fracturepart26_1001} />
          <primitive object={nodes.entrance_fracturepart39001} />
          <primitive object={nodes.entrance_fracturepart9_1001} />
          <primitive object={nodes.entrance_fracturepart26001} />
          <primitive object={nodes.entrance_fracturepart4_4001} />
          <primitive object={nodes.entrance_fracturepart9001} />
          <primitive object={nodes.entrance_fracturepart36001} />
          <primitive object={nodes.entrance_fracturepart50_2001} />
          <primitive object={nodes.entrance_fracturepart4_2001} />
          <primitive object={nodes.entrance_fracturepart10001} />
          <primitive object={nodes.entrance_fracturepart50_1001} />
          <primitive object={nodes.entrance_fracturepart15001} />
          <primitive object={nodes.entrance_fracturepart33_3001} />
          <primitive object={nodes.entrance_fracturepart50001} />
          <primitive object={nodes.entrance_fracturepart16001} />
          <primitive object={nodes.entrance_fracturepart28_1001} />
          <primitive object={nodes.entrance_fracturepart31001} />
          <primitive object={nodes.entrance_fracturepart33_2001} />
          <primitive object={nodes.entrance_fracturepart28001} />
          <primitive object={nodes.entrance_fracturepart49_1001} />
          <primitive object={nodes.entrance_fracturepart_1001} />
          <primitive object={nodes.entrance_fracturepart38001} />
          <primitive object={nodes.entrance_fracturepart41_1001} />
          <primitive object={nodes.entrance_fracturepart47001} />
          <primitive object={nodes.entrance_fracturepart001} />
          <primitive object={nodes.entrance_fracturepart35_2001} />
          <primitive object={nodes.entrance_fracturepart41001} />
          <primitive object={nodes.entrance_fracturepart42001} />
          <primitive object={nodes.entrance_fracturepart25001} />
          <primitive object={nodes.entrance_fracturepart34_1001} />
          <primitive object={nodes.entrance_fracturepart44001} />
          <primitive object={nodes.entrance_fracturepart20_2001} />
          <primitive object={nodes.entrance_fracturepart27001} />
          <primitive object={nodes.entrance_fracturepart34001} />
          <primitive object={nodes.entrance_fracturepart20_1001} />
          <primitive object={nodes.entrance_fracturepart45_1001} />
          <primitive object={nodes.entrance_fracturepart13_1001} />
          <primitive object={nodes.entrance_fracturepart24_1001} />
          <primitive object={nodes.entrance_fracturepart30_2001} />
          <primitive object={nodes.entrance_fracturepart45001} />
          <primitive object={nodes.entrance_fracturepart19_1001} />
          <primitive object={nodes.entrance_fracturepart30_1001} />
          <primitive object={nodes.entrance_fracturepart19001} />
          <primitive object={nodes.entrance_fracturepart22_1001} />
          <primitive object={nodes.entrance_fracturepart32001} />
          <primitive object={nodes.entrance_fracturepart3001} />
          <primitive object={nodes.entrance_fracturepart5_1001} />
          <primitive object={nodes.entrance_fracturepart6001} />
          <primitive object={nodes.entrance_fracturepart2_1001} />
          <primitive object={nodes.entrance_fracturepart5001} />
          <primitive object={nodes.entrance_fracturepart2001} />
          <primitive object={nodes.entrance_fracturepart35001} />
          <primitive object={nodes.entrance_fracturepart11001} />
          <primitive object={nodes.entrance_fracturepart14001} />
          <primitive object={nodes.entrance_fracturepart1001} />
          <primitive object={nodes.entrance_fracturepart23001} />
          <primitive object={nodes.entrance_fracturepart43_1001} />
          <primitive object={nodes.entrance_fracturepart4_1001} />
          <primitive object={nodes.entrance_fracturepart33_1001} />
          <primitive object={nodes.entrance_fracturepart7001} />
          <primitive object={nodes.entrance_fracturepart23_1001} />
          <primitive object={nodes.entrance_fracturepart45_2001} />
          <primitive object={nodes.entrance_fracturepart22001} />
          <primitive object={nodes.entrance_fracturepart12_1001} />
          <primitive object={nodes.Bone_glowend} />
          <primitive object={nodes.headstone_E_9002} />
          <primitive object={nodes.Polygon002} />
          <primitive object={nodes.Bone_extra_bg} />
          <primitive object={nodes.neutral_bone} />
        </group>
      </group>
    </group>
  );
});

useGLTF.preload("/models/zionCemetery-rigged.glb");
