import React from "react";
import { OrbitControls } from "@react-three/drei";
import { useControls } from "leva";
import { useThree } from "@react-three/fiber";

export interface OrbitControlsContainerProps {}

const OrbitControlsContainer: React.FC<OrbitControlsContainerProps> = () => {
  const { camera, controls } = useThree();

  const { zoom, autoRotate, pan, target, makeDefault } = useControls(
    "OrbitControls",
    {
      zoom: { value: true },
      makeDefault: { value: false },
      autoRotate: { value: false },
      pan: { value: true },
      target: { value: [0, 2.6, 0] },
    }
  );

  return (
    <>
      <OrbitControls
        makeDefault={makeDefault}
        enablePan={pan}
        autoRotate={autoRotate}
        enabled={zoom}
        zoomSpeed={1}
        onChange={() => {
          // @ts-ignore
          console.log(`target`, controls.target);
          console.log(`position`, camera.position);
        }}
        target={target}
        // enableDamping
      />
    </>
  );
};

export default OrbitControlsContainer;
