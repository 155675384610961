import React, { useEffect, useCallback } from 'react';

interface Props {
  toggleDevEnvironment: () => void;
}

const ShortcutLaunchGui: React.FC<Props> = ({ toggleDevEnvironment }) => {
  const handleKeyDown = useCallback(
    // @ts-ignore
    (event) => {
      if ((event.ctrlKey || event.metaKey) && event.key.toLowerCase() === 'd') {
        toggleDevEnvironment();
      }
    },
    [toggleDevEnvironment]
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  return null;
};

export default ShortcutLaunchGui;
