import React, { useCallback, useState, useRef, useEffect } from "react";
import leftArrow from "../../../assets/images/leftArrow.svg";
import rightArrow from "../../../assets/images/rightArrow.svg";
import navPointer from "../../../assets/images/navPointer.svg";
import state from "../../../state/state";
import { isMobile } from "react-device-detect";
import {
  SvgContainer,
  SvgSmall,
  SvgLarge,
  DiscoverSvg,
  Date,
  DateContainer,
} from "./styles/navigationStyles";
import { transitionDuration } from "./styles/navigationStyles";
import { AnimatePresence } from "framer-motion";

interface Props {
  Hotspot: number;
  Area: number;
}

const NavigationUI: React.FC<Props> = React.memo(({ Hotspot, Area }) => {
  const setHotspot = state((s) => s.setHotspot);
  const ActiveDate = state((s) => s.ActiveDate);
  const CenterImage = state((s) => s.CenterImage);
  const setCenterImage = state((s) => s.setCenterImage);
  const setModalIndex = state((s) => s.setModalIndex);
  const ModalIndex = state((s) => s.ModalIndex);
  const setActivePlane = state((s) => s.setActivePlane);
  const ActivePlane = state((s) => s.ActivePlane);
  const transitionAppState = state((s) => s.transitionAppState);

  const handleLeftClick = useCallback(() => {
    if (Area === 4) {
      if (CenterImage <= 0) {
        transitionAppState(1, 2);
        setModalIndex(null);
        return;
      }
      setCenterImage(CenterImage - 1);
      setActivePlane(null);
      setModalIndex(null);
    } else if (Area === 2) {
      if (Hotspot === 0) transitionAppState(1, 1);
      if (ModalIndex === 0) {
        setModalIndex(null);
        setHotspot(0);
      }
      if (ModalIndex === 1) setModalIndex(0);
      if (ModalIndex === 2) setModalIndex(1);
    } else {
      if (Hotspot === 0) setHotspot(1);
      else if (Hotspot === 1) transitionAppState(2, 0);
      else setHotspot(0);
    }
  }, [
    Area,
    CenterImage,
    setCenterImage,
    setActivePlane,
    setModalIndex,
    transitionAppState,
    Hotspot,
    ModalIndex,
    setHotspot,
  ]);

  const handleRightClick = useCallback(() => {
    if (Area === 4) {
      if (CenterImage > 7) return;
      setCenterImage(CenterImage + 1);
      setActivePlane(null);
      setModalIndex(null);
    } else if (Area === 2) {
      if (ModalIndex === null) {
        setModalIndex(0);
        setHotspot(1);
      }
      if (ModalIndex === 2) return;
      if (ModalIndex === 1) setModalIndex(2);
      if (Hotspot === 0) transitionAppState(1, 1);
    } else {
      if (Hotspot === 0) setHotspot(2);
      else if (Hotspot === 2) transitionAppState(4, 0);
      else setHotspot(0);
    }
  }, [
    Area,
    CenterImage,
    setCenterImage,
    setActivePlane,
    setModalIndex,
    ModalIndex,
    setHotspot,
    Hotspot,
    transitionAppState,
  ]);

  const handleCenterClick = useCallback(() => {
    setHotspot(0);
  }, [setHotspot]);

  const [isHovering, setIsHovering] = useState(false);
  const [angle, setAngle] = useState(0);
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsMounted(true);
    }, 1600);
  }, []);

  const handleMouseMove = useCallback(
    (event: any) => {
      const rect = event.currentTarget.getBoundingClientRect();
      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;
      let calcX = -(y - rect.height / 2) / 50;
      let calcY = (x - rect.width / 2) / 50;
      // Adjust the degree to be within -90 to 90 degrees
      let deg = 90 * calcY;
      deg = Math.max(-90, Math.min(90, deg));
      setAngle(deg);
    },
    [setAngle]
  );

  const handleMouseEnter = useCallback(() => {
    setIsHovering(true);
  }, [setIsHovering]);

  const handleMouseLeave = useCallback(() => {
    setIsHovering(false);
    setAngle(0);
  }, [setIsHovering, setAngle]);

  const svgLargeRef = useRef(null);

  const [transition, setTransition] = useState(false);

  // Set a ref to keep track of the current hotspot
  const hotspotRef = useRef(Hotspot);
  // On each render, update the ref with the current hotspot
  hotspotRef.current = Hotspot;

  useEffect(() => {
    // Only trigger the transition if the hotspot changed and it's not due to hover
    if (hotspotRef.current !== Hotspot && !isHovering) {
      setTransition(true);
      const timer = setTimeout(() => {
        setTransition(false);
      }, transitionDuration);

      // Clean up function to clear the timeout when the component unmounts or before next effect run
      return () => {
        clearTimeout(timer);
      };
    }
  }, [Hotspot, isHovering]);

  const handleSvgLargeClick = useCallback(() => {
    if (Area === 2) return;
    if (angle >= -30 && angle <= 30) {
      setHotspot(0);
    } else if (angle > 30 && angle <= 180) {
      setHotspot(2);
    } else if (angle < -30 && angle >= -180) {
      setHotspot(1);
    }
  }, [angle, setHotspot, Area]);

  console.log(Area, Hotspot, ModalIndex, CenterImage, ActivePlane);

  return (
    <SvgContainer
      layerabovemodal={(isMobile && Area === 2) || (isMobile && Area === 4)}
      revealed={isMounted}
      alignLeft={Area === 2 && Hotspot === 0}
    >
      {Area === 2 && Hotspot === 0 ? (
        <SvgSmall faded={true}>
          <div className="blank"></div>
        </SvgSmall>
      ) : (
        <SvgSmall faded={false}>
          <img src={leftArrow} alt="Left Arrow" onClick={handleLeftClick} />
        </SvgSmall>
      )}
      {(Area === 1 || (Area === 2 && Hotspot < 1)) && (
        <>
          {Area === 1 ? (
            <SvgLarge
              ref={svgLargeRef}
              rotation={Hotspot}
              angle={angle}
              hovering={isHovering}
              transition={transition}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              onMouseMove={handleMouseMove}
              onClick={handleSvgLargeClick}
            >
              <img
                src={navPointer}
                alt="Navigation Pointer"
                onClick={handleCenterClick}
              />
            </SvgLarge>
          ) : (
            <DiscoverSvg
              onClick={() => {
                setHotspot(1);
                setModalIndex(0);
              }}
            >
              <div>Discover</div>
            </DiscoverSvg>
          )}
        </>
      )}

      {Area === 4 && (
        <DateContainer>
          <Date>{ActiveDate || null}</Date>
        </DateContainer>
      )}
      {Area === 4 && CenterImage === 0 && ActivePlane !== 0 ? (
        <SvgSmall faded={true}>
          <div className="blank"></div>
        </SvgSmall>
      ) : (
        <SvgSmall
          faded={
            (Area === 4 && CenterImage === 8) ||
            (Area === 2 && ModalIndex === 2)
          }
        >
          <img src={rightArrow} alt="Right Arrow" onClick={handleRightClick} />
        </SvgSmall>
      )}
    </SvgContainer>
  );
});

export default NavigationUI;
