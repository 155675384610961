import styled, { keyframes } from "styled-components";

interface ModalProps {
  visible: boolean;
  children?: React.ReactNode;
  easingFunc?: string;
  triggerImage?: string | undefined;
}

export const ModalDiv = styled.div<ModalProps>`
  position: absolute;
  z-index: 1000000;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0.5);
  transition: all 1s cubic-bezier(0.445, 0.05, 0.55, 0.95);

  opacity: ${({ visible }) => (visible ? 1 : 0)};
`;

export const ContentWrapper = styled.div<ModalProps>`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  pointer-events: ${({ visible }) => (visible ? "auto" : "none")};
  justify-content: center;
  transform: translate(-50%, -50%);
  overflow: hidden;
  margin-left: 2.5rem;
  transition: all 1s cubic-bezier(0.445, 0.05, 0.55, 0.95);
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transform: ${({ visible }) => (visible ? "scale(1)" : "scale(0)")};

  @media (max-width: 768px) {
    margin-left: 1.25rem;
  }
`;

export const VideoContentWrapper = styled.div<ModalProps>`
  width: 100%;
  height: 100%;
  flex-direction: column;
  display: flex;
  align-items: center;
  pointer-events: ${({ visible }) => (visible ? "auto" : "none")};
  justify-content: center;
  transform: translate(-50%, -50%);
  overflow: hidden;
  margin-left: 0;
  transition: all 1s cubic-bezier(0.445, 0.05, 0.55, 0.95);
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transform: ${({ visible }) => (visible ? "scale(1)" : "scale(0)")};

  @media (max-width: 768px) {
    margin-left: 0;
  }
`;

export const Video = styled.video<ModalProps>`
  height: 48rem;
  pointer-events: ${({ visible }) => (visible ? "auto" : "none")};

  @media (max-width: 768px) {
    height: unset;
    width: 95%;
    margin-left: unset;
    /* margin-right: 5rem; */
  }
`;

export const CloseButton = styled.button`
  position: relative;
  left: -2.5rem;
  transform: translate(0, -50%);
  top: -21.5rem;
  min-width: 5rem;
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  background-color: black;
  z-index: 9999999999;
  border: 2px solid white;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 25;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.1);
  opacity: 0.8;
  pointer-events: auto;
  transition: all 0.3s ease-in;

  @media (hover: hover) {
    &:hover {
      scale: 1.1;
    }
  }

  @media (max-width: 768px) {
    top: -10rem;
    left: -3rem;
    min-width: 4rem;
    width: 4rem;
    height: 4rem;
  }
`;

export const VideoCloseButton = styled.button`
  position: relative;
  margin-top: 3rem;
  min-width: 5rem;
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  background-color: black;
  z-index: 9999999999;
  border: 2px solid white;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 25;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.1);
  opacity: 0.8;
  pointer-events: auto;
  transition: all 0.3s ease-in;

  @media (hover: hover) {
    &:hover {
      scale: 1.1;
    }
  }
  @media (max-width: 768px) {
    /* top: -10rem; */
    /* left: -3rem; */
    min-width: 4rem;
    width: 4rem;
    height: 4rem;
  }
`;

const fadeInOut = keyframes`
  0% { opacity: 0.5; }
  50% { opacity: 1; }
  100% { opacity: 0.5; }
`;

const lightBeam = keyframes`
  0% { background-position: -100% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: -100% 50%; }
`;

export const MuteBtn = styled.button<{ visible: boolean; hovered: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 2s ease-in-out, scale 0.5s ease-in-out;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  width: 15rem;
  height: 5rem;
  border: 0.75px solid white;
  top: calc(50% - 19rem);
  left: calc(50% - 35.5rem);
  background-color: rgba(255, 255, 255, 1);
  color: rgba(255, 255, 255, 1);
  position: absolute;
  pointer-events: auto;
  cursor: pointer;
  z-index: 30;
  transform: translate(-50%, -50%);
  font-size: 1.1rem;
  font-family: serif;
  text-align: left;
  letter-spacing: 0.2px;
  animation: ${fadeInOut} 5s infinite ease-in-out;
  background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 1),
      rgba(255, 255, 255, 0.9)
    )
    50% 100% no-repeat;
  background-size: 25% 100%; // Defines the size of the light beam, adjust as needed.
  /* background-size: ${({ hovered }) =>
    hovered ? "25% 100%" : "100% 100%"}; */
  scale: ${({ hovered }) => (hovered ? 1.05 : 1)};
  animation: ${lightBeam} 5s infinite;
  @media (max-width: 768px) {
    width: 13rem;
    height: 4.3rem;
    top: calc(50% - 8.5rem);
    left: calc(50% - 14.5rem);
    font-size: 1rem;
    scale: 0.9;
  }
  @media (max-width: 400px) {
    scale: 0.75;
  }
`;

export const MuteImg = styled.img`
  width: 2.5rem;
  height: 2.5rem;
  margin-right: 0.7rem;
`;
