const createParticlesAttributes = (
  particleCount,
  power,
  width,
  height,
  depth
) => {
  // * attributes
  const positions = new Float32Array(particleCount * 3);
  const velocities = new Float32Array(particleCount * 3);
  const randomness = new Float32Array(particleCount);
  const direction = new Float32Array(particleCount);

  for (let i = 0; i < particleCount; i++) {
    const i3 = i * 3;

    // * positions
    positions[i3] = Math.random() * (width + width) - width;
    positions[i3 + 1] = Math.random() * (height + (height + 2)) - (height + 2);
    positions[i3 + 2] = Math.random() * (depth + depth) - depth;

    // * velocities
    // velocities[i3] = (Math.random() * (2.8 - 2) + 2) * 0.03;
    velocities[i3] = 0;
    // velocities[i3 + 1] = (Math.random() * (1.2 - 0.82) + 0.82) * 0.6;
    velocities[i3 + 1] = 0.05;
    velocities[i3 + 2] = 0;

    // * randomness
    randomness[i] = 1 * Math.random();
    // randomness[i3] = 0;
    // * direction
    // direction[i] = Math.random() > 0.5 ? 1 : -1;
    // randomness[i3] = 0;
  }

  return {
    positions: positions,
    velocities: velocities,
    randomness: randomness,
    // direction: direction,
  };
};

export default createParticlesAttributes;
