export const fragmentShader = `
    
    uniform float uTime;
    varying float randomness;


    float inverseLerp(float v, float minValue, float maxValue) {
      return (v - minValue) / (maxValue - minValue);
    }

    float remap(float v, float inMin, float inMax, float outMin, float outMax) {
      float t = inverseLerp(v, inMin, inMax);
      return mix(outMin, outMax, t);
    }

    void main(){
        vec4 col = vec4(0.);
        //Light point pattern (diffuse point that fades faster)
        float strength = distance(gl_PointCoord, vec2(.5));
        strength = pow(1. - strength, 3. + remap(sin(uTime - randomness) +1.,  -1., 1., 0., 1.8 * (fract(randomness * uTime))));

        // Feathered
        // strength = smoothstep(.5, .9, strength);

          
        col = mix(col, vec4(0.933,0.769,0.388, 1.), strength);
        // col = mix(col, vec4(1.), strength);

        // col = clamp(col + ((1. + sin(smoothstep(uTime + randomness, 0. ,1.)))/2. * .1), 0.,.7);
        // col = sin(col) + .5;
        // col.w = max(col.w , 1.);

        // Final
        gl_FragColor = vec4(col);
    }


`;
