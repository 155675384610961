export const fragmentShader = `
    varying vec2 vuv; 
    uniform float uHovered; 

    // color
    vec4 white = vec4(1.);
    vec4 semiFadedWhite = vec4(vec3(1.), .9);
    vec4 fadedWhite = vec4(vec3(1.), .35);
    vec4 black = vec4(0.);

    float circleShape(vec2 position, float radius){
        return length(position) - radius;
    }
  

    float easeOutQuint(float t) {
      return 1.0 + (--t) * t * t * t * t;
  }

    
    void main(){
        vec4 col = vec4(0.);
        
        vec2 center = (vuv - .5);
        float w = fwidth(center.y);

        // outer Ring
        float ringOuter = circleShape(center,.49);
        float ringOuterInner = circleShape(center,.44);
        // inner Circle
        float innerCircleSize = (easeOutQuint(uHovered) * .18) + .21;
        float innerCircle = circleShape(center,innerCircleSize);
        // outer circle
        float outerCircle = circleShape(center, .37);


        // Glow
        float glow = 1. - smoothstep(0., .35, circleShape(center, .4));


        // Apply the glow
        // col = mix(vec4(0.,0.,0.,glow), vec4(1.0, 1.0, 1.0, 1. - glow), glow); // Let glow control the alpha channel of the glow color
        // glow = pow(glow, 2.);
        

        col = mix(white, col, smoothstep(-w, w, ringOuter));
        col *= smoothstep(-w, w, ringOuterInner);
        col = mix(white, col, smoothstep(-w, w, innerCircle));
        col = mix(fadedWhite, col, smoothstep(.025 - w, .025 + w, outerCircle));
        col = mix(semiFadedWhite, col, smoothstep(.025 - w, .025 + w, innerCircle));

        // Final
        gl_FragColor = vec4(col);
    }
`;
