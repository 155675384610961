import * as THREE from "three";
import React, { useEffect } from "react";
import { useGLTF } from "@react-three/drei";
import { GLTF } from "three-stdlib";
import { motion } from "framer-motion-3d";
import { useFrame } from "@react-three/fiber";

type GLTFResult = GLTF & {
  nodes: {
    Plane: THREE.Mesh;
    Plane_3: THREE.Mesh;
    Plane_2: THREE.Mesh;
    Plane_1: THREE.Mesh;
  };
  materials: {
    people: THREE.MeshBasicMaterial;
  };
};

interface PropTypes {
  Area: number;
}

export const LobbyPeopleModel: React.FC<PropTypes> = ({ Area }) => {
  const { nodes, materials } = useGLTF(
    "./models/lobbyPeople.glb"
  ) as GLTFResult;

  useEffect(() => {
    materials.people.opacity = 0;
  }, [materials.people]);

  useFrame((state, delta) => {
    if (Area > 0) {
      let opacity = materials.people.opacity + delta * 0.15;
      // opacity = easeInExpo(opacity);
      opacity = THREE.MathUtils.clamp(opacity, 0, 0.85);

      materials.people.opacity = opacity;
    }
  });

  return (
    <group dispose={null}>
      <group position={[29.5, 0, 0]}>
        <mesh
          renderOrder={-0.1}
          name="right_blue_stripe"
          geometry={nodes.Plane.geometry}
          material={materials.people}
          position={[-254.81, -15.25, -1697.38]}
          rotation={[-1.45, -0.49, 0.11]}
          scale={-1.5}
        />
        <mesh
          renderOrder={0.1}
          name="sun"
          geometry={nodes.Plane_3.geometry}
          material={materials.people}
          position={[115, 93.57, -1525.4]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={1.3}
        />
        <mesh
          renderOrder={-0.1}
          name="right_blue_stripe"
          geometry={nodes.Plane_2.geometry}
          material={materials.people}
          position={[199.25, -8.69, -1748.91]}
          rotation={[Math.PI / 2, -Math.PI / 6, 0]}
          // scale={0.9}
          scale={1.5}
        />
      </group>
      <mesh
        scale={1.35}
        renderOrder={0.2}
        name="people"
        geometry={nodes.Plane_1.geometry}
        material={materials.people}
        position={[-5.22, 63.23, -870.73]}
        rotation={[1.23, 0, 0]}
      />
    </group>
  );
};

useGLTF.preload("./models/lobbyPeople.glb");
