import * as React from "react";
import { MenuItem } from "./MenuItem";
import { StyledUl } from "./styles/menuStyles";

const variants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
};

interface NavigationProps {
  isOpen: boolean;
  links: any[];
}

const itemIds = [0, 1, 2, 3, 4, 5, 6];
const menuText = [
  "HOME",
  "STORY OF ZION",
  "TIMELINE",
  "MAP",
  "TAKE ACTION",
  "VIEW IN AR",
  "ABOUT",
];

export const Navigation: React.FC<NavigationProps> = ({ isOpen, links }) => {
  return (
    <StyledUl variants={variants}>
      {itemIds.map((i) => (
        <MenuItem
          i={i}
          key={i + "menu"}
          isOpen={isOpen}
          text={menuText[i]}
          clickHandler={links[i]}
        />
      ))}
    </StyledUl>
  );
};
