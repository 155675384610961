import React, { FC, useRef } from "react";
import {
  ModalWrapper,
  Cross,
  CloseButton,
  CloseButtonMobile,
  CloseButtonMobileWrapper,
} from "./styles/sideModalStyles";
import { isMobile } from "react-device-detect";
import { MouseTrackerState } from "../mouseTracker/data/mouseTracker.data";
import {
  deactivateMouseTracker,
  activateMouseTracker,
} from "../../util/changeMouseTracker";
import state from "../../state/state";

interface ModalProps {
  onClose: (arg0?: boolean) => void;
  data?: {
    onClose: () => void;
    content?: any;
  };
  toggleAnim: boolean;
  setToggleAnim: (arg0: boolean) => void;
  setModalIndex: (arg0: number | null) => void;
  Area: number;
}

const Modal: FC<ModalProps> = ({
  onClose,
  data,
  toggleAnim,
  setToggleAnim,
  setModalIndex,
  Area,
}) => {
  const beforeHoverMouseTrackerState = useRef<null | string>(null);
  const lowerSoundVolume = state((s) => s.lowerSoundVolume);

  const handleHover = (e: any) => {
    e.stopPropagation();
    if (MouseTrackerState.name !== "") {
      beforeHoverMouseTrackerState.current = MouseTrackerState.name;
      deactivateMouseTracker();
    }
  };

  const handleHoverExit = (e: any) => {
    e.stopPropagation();
    if (beforeHoverMouseTrackerState.current !== null) {
      activateMouseTracker(beforeHoverMouseTrackerState.current);
    }
    beforeHoverMouseTrackerState.current = null;
  };

  if (!data) return null;

  return (
    <>
      {isMobile ? (
        <>
          <ModalWrapper toggleAnim={toggleAnim} area={Area}>
            {data.content && data.content}
          </ModalWrapper>
          <CloseButtonMobileWrapper toggleAnim={toggleAnim}>
            <CloseButtonMobile
              toggleAnim={toggleAnim}
              onClick={() => {
                if (data.onClose) data.onClose();
                setToggleAnim(false);
                onClose(true);
              }}
            >
              <Cross />
            </CloseButtonMobile>
          </CloseButtonMobileWrapper>
        </>
      ) : (
        <>
          <ModalWrapper
            toggleAnim={toggleAnim}
            onPointerEnter={handleHover}
            onPointerLeave={handleHoverExit}
          >
            <CloseButton
              onClick={() => {
                if (data.onClose) data.onClose();
                lowerSoundVolume("high");
                beforeHoverMouseTrackerState.current = null;
                deactivateMouseTracker();
                setModalIndex(null);
                setToggleAnim(false);
                onClose(true);
              }}
            >
              <Cross />
            </CloseButton>
            {data.content && data.content}
          </ModalWrapper>
        </>
      )}
    </>
  );
};

export default Modal;
