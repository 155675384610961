import React, { useRef, useEffect } from "react";
import { useFrame, useThree } from "@react-three/fiber";
import * as THREE from "three";
import { lerp } from "../utils/easingFunctions";
import { useControls } from "leva";
// import { smootherstep } from "three/src/math/MathUtils";

interface Vector3Data {
  x: number;
  y: number;
  z: number;
  label?: string;
}

interface CameraData {
  label: string;
  position: Vector3Data[];
  target: Vector3Data[];
}

interface MoveCameraProps {
  cameraData: any;
  duration?: number;
  easing?: (min: number, max: number, value: number) => number;
  precision?: number;
  onStart?: () => void;
  onEnd?: () => void;
  onChange?: () => void;
}

const MoveCamera: React.FC<MoveCameraProps> = ({
  cameraData,
  duration = 10000,
  easing,
  precision = 0.01,
  onStart,
  onEnd,
  onChange,
}) => {
  const { camera, mouse } = useThree();
  const cameraDataRef = useRef(cameraData);
  const startTimeRef = useRef<number>(0);
  const target = useRef(new THREE.Vector3());
  const positionVector = useRef(new THREE.Vector3());

  const { sensitivity } = useControls("CameraParallax", {
    sensitivity: { value: 0.2, min: 0, max: 2, step: 0.0001 },
  });

  const { slowestCam } = useControls("Camera", {
    slowestCam: { value: false },
  });

  useEffect(() => {
    cameraDataRef.current = cameraData;
    startTimeRef.current = 0;
    if (onStart) onStart();
  }, [cameraData, onStart]);

  useFrame((state, delta) => {
    if (!cameraDataRef.current) return;

    const position = cameraDataRef.current.position;
    const targetData = cameraDataRef.current.target;
    if (!position || !targetData) return;

    let t = 0;

    startTimeRef.current += delta * 1000;
    // @ts-ignore
    t = easing((startTimeRef.current / duration) * 1.2);
    // if (slowestCam) t = easing(0, 1, startTimeRef.current / duration);
    // if (!slowestCam) t = smootherstep(startTimeRef.current / duration, 0, 1);

    // * add smoothing
    // if (slowestCam) t = smootherstep(t, 0, 1);

    // console.log(`t`, t);

    // * Camera Parallax effect
    positionVector.current.set(
      position.x - mouse.x * sensitivity,
      position.y - mouse.y * sensitivity,
      position.z
    );

    // soften
    camera.position.lerp(positionVector.current, t);

    target.current.set(
      lerp(target.current.x, targetData.x, t),
      lerp(target.current.y, targetData.y, t),
      lerp(target.current.z, targetData.z, t)
    );

    camera.lookAt(target.current);
    camera.updateProjectionMatrix();

    if (onChange) onChange();

    if (startTimeRef.current >= duration) {
      startTimeRef.current = 0;
      if (onEnd) onEnd();
    }
  });

  return null;
};

export default React.memo(MoveCamera);
