import React from "react";
import Carousel from "./Carousel";
import state from "../../../../../state/arState";

interface CarouselProps {
  Chapter: number;
  setChapter: (Chapter: number) => void;
  ScenePlaced: boolean;
}

const CarouselContainer: React.FC<CarouselProps> = ({
  Chapter,
  setChapter,
  ScenePlaced,
}) => {
  const setTextIndex = state((s) => s.setTextIndex);

  const handleSelectedIndexChanged = (index: number) => {
    setTextIndex(0);
    setChapter(index);
  };

  if (!ScenePlaced || Chapter === 4) return null;

  return (
    <>
      <Carousel
        selectedIndex={Chapter}
        onSelectedIndexChanged={handleSelectedIndexChanged}
      />
    </>
  );
};

export default CarouselContainer;
