export const fragmentShader = `
    
    
    void main(){

        //Light point pattern (diffuse point that fades faster)
        float strength = distance(gl_PointCoord, vec2(.5));
        strength = 1. - strength;

        // Feathered
        strength = smoothstep(.78,1.,strength);
       

        vec3 col = mix(vec3(.0), vec3(1.), strength);

        // Final
        gl_FragColor = vec4(col, strength);
    }


`;
