export const vertexShader = `

  varying vec2 vuv; 
  uniform float time;
   #define PI 3.14159265358979323846

   float easeOutElastic(float t) {
      float p = 0.3;
      float a = 1.0;
      float s = p/4.0;
      return (a*pow(2.0,-10.0*t) * sin((t-s)*(2.0*PI)/p) + 1.0);
  }

  
  float easeInOutExpo(float t) {
    if(t == 0.0) return 0.0;
    if(t == 1.0) return 1.0;
    if((t /= 0.5) < 1.0) return 0.5 * pow(2.0, 10.0 * (t - 1.0));
    return 0.5 * (-pow(2.0, -10.0 * --t) + 2.0);
}

float easeInOutQuint(float t) {
    return t < 0.5 ? 16.0 * t * t * t * t * t : 1.0 + 16.0 * (--t) * t * t * t * t;
}

  // mat3 rotateY(float radians){
  //     float s = sin(radians);
  //     float c = cos(radians);

  //     return mat3(
  //       c, 0., s,
  //       0., 1., 0.,
  //       -s, 0., c
  //     );
  //   }

  void main(){
    vec3 localSpacePosition = position;

    float delay = 0.;
    // localSpacePosition = rotateY(clamp(easeOutElastic(time) * 6.28319,.0, 6.28319)) * localSpacePosition;
    localSpacePosition *= easeInOutQuint(clamp(time - delay, 0., 1.));

    
    // easeOutElastic(clamp(rotateY(time) - delay, 0., 1.)) *  localSpacePosition;

    vec4 modelPosition = modelMatrix * vec4(localSpacePosition, 1.0);

    
    vec4 viewPosition = viewMatrix * modelPosition;
    vec4 projectedPosition = projectionMatrix * viewPosition;
    gl_Position = projectedPosition;
    // gl_Position = projectionMatrix * modelViewMatrix * vec4(localSpacePosition, 1.0);

    vuv = uv;
  }
`;
