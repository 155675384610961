import styled, { keyframes, css } from "styled-components";

type ScrollTextProps = {
  visible: boolean;
};

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

export const ScrollText = styled.h1<ScrollTextProps>`
  font-family: "ApothecarySerifSpaced", serif;
  font-size: 3rem;
  position: absolute;
  bottom: 2.5rem;
  left: 50%;
  transform: translateX(-50%);
  pointer-events: none;
  text-align: center;
  z-index: 10;
  width: 100%;
  color: white;
  margin: 0;
  opacity: 0;

  animation: ${(props) =>
    props.visible
      ? css`
          ${fadeIn} 2s cubic-bezier(0.445, 0.05, 0.55, 1) 3s forwards
        `
      : css`
          ${fadeOut} 2s cubic-bezier(0.445, 0.05, 0.55, 1) forwards
        `};

  @media (max-width: 768px) {
    bottom: 19rem;
    font-size: 2.4rem;
    letter-spacing: -2.5px;
    pointer-events: auto;
  }
  @media (max-width: 380px) {
    bottom: 14rem;
    font-size: 1.9rem;
    letter-spacing: -2.5px;
    pointer-events: auto;
  }
`;

export const SvgContainer = styled.div<ScrollTextProps>`
  position: absolute;
  bottom: 5.5rem;
  left: 50%;
  transform: translateX(-50%);
  pointer-events: none;
  text-align: center;
  z-index: 10;

  @media (max-width: 768px) {
    bottom: 13rem;
    pointer-events: ${(props) => (props.visible ? "auto" : "none")};
    width: 100%;
    height: 100%;
  }
`;

export const Img = styled.img`
  pointer-events: none;
`;
