import React from "react";
import { useControls } from "leva";
import { Plane } from "@react-three/drei";
import {
  deactivateMouseTracker,
  activateMouseTracker,
} from "../../util/changeMouseTracker";
import state from "../../state/state";

const RoomPlanes = () => {
  const transitionAppState = state((s) => s.transitionAppState);
  const setAnimatedTextHovered = state((s) => s.setAnimatedTextHovered);
  const { rotation, opacity, x, y, z } = useControls("roomPlanes", {
    rotation: {
      value: Math.PI / 2,
      min: -Math.PI * 2,
      max: Math.PI * 2,
      step: 0.001,
    },
    opacity: {
      value: 0,
      min: 0,
      max: 1,
      step: 1,
    },
    x: {
      value: -7.3,
      min: -20,
      max: 40,
      step: 0.001,
    },
    y: {
      value: 3.51,
      min: 0,
      max: 20,
      step: 0.001,
    },
    z: {
      value: -2.4,
      min: -20,
      max: 20,
      step: 0.001,
    },
  });
  const handleClick = (e: any, area: number, hotspot: number) => {
    e.stopPropagation();
    deactivateMouseTracker();
    transitionAppState(area, hotspot);
    // setAnimatedTextHovered(false);
  };
  const handleHover = (e: any) => {
    e.stopPropagation();
    setAnimatedTextHovered(true);
    activateMouseTracker("ENTER");
  };
  const handleHoverExit = (e: any) => {
    e.stopPropagation();
    setAnimatedTextHovered(false);
    deactivateMouseTracker();
  };

  return (
    <group renderOrder={4}>
      <mesh
        rotation={[0, 0, 0.0]}
        onClick={(e) => handleClick(e, 1, 0)}
        position={[0, 4, 14.9]}
        onPointerEnter={handleHover}
        onPointerLeave={handleHoverExit}
      >
        <Plane args={[3.5, 8, 2, 2]}>
          <meshBasicMaterial color={"#ff0000"} opacity={0} transparent />
        </Plane>
      </mesh>
      <mesh
        rotation={[0, rotation, 0.0]}
        onClick={(e) => handleClick(e, 2, 0)}
        position={[x, y, z - 0.1]}
        onPointerEnter={handleHover}
        onPointerLeave={handleHoverExit}
      >
        <Plane args={[4, 7, 2, 2]}>
          <meshBasicMaterial color={"#ff0000"} opacity={opacity} transparent />
        </Plane>
      </mesh>
      <mesh
        rotation={[0, -rotation, 0.0]}
        onClick={(e) => handleClick(e, 4, 0)}
        position={[-x, y, z]}
        onPointerEnter={handleHover}
        onPointerLeave={handleHoverExit}
      >
        <Plane args={[4, 7, 2, 2]}>
          <meshBasicMaterial color={"#ff0000"} opacity={opacity} transparent />
        </Plane>
      </mesh>
    </group>
  );
};

export default RoomPlanes;
