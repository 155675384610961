import styled from "styled-components";

export const CloseButton = styled.div<{ backgroundImageUrl: string }>`
  margin: 0 0 4px 0;
  width: 30px;
  height: 30px;
  font-weight: bold;
  cursor: pointer;
  background-image: url(${(props) => props.backgroundImageUrl});
  background-size: cover;
  background-position: center;
`;

export const Heading = styled.h3`
  font-size: 26px;
  letter-spacing: 0;
  font-weight: bold;
  margin: 16px 0 8px 0;
  font-family: ApothecarySerifSpaced;
  text-align: center;
  line-height: 75%;
`;

export const Subheading = styled.h4`
  font-size: 13px;
  font-weight: normal;
  margin: 8px 0 0px 0;
  font-family: Iowan Old Style;
  text-align: center;
`;

interface ModalWrapperProps {
  isOpen: boolean;
}

export const ModalWrapper = styled.div<ModalWrapperProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: opacity 2.8s ease-in-out;
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
`;

export const ModalOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
`;

interface ModalContentProps {
  isOpen: boolean;
}

export const ModalContent = styled.div<ModalContentProps>`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 95%;
  background-image: url("/images/storyPage/TornPaper.png");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 15% 25px 25px 25px;
  box-sizing: border-box;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  z-index: 9999999;
`;
